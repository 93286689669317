import styled from 'styled-components';
// App Bar Component
export const AppBar = styled.div`
  width: 100%;
  padding: 15px 30px;
  background: #1f2a3b;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  
  position: fixed; /* Keep it fixed on top */
  top: 0; /* Always at the top */
  left: 0; /* Align to the left */
  z-index: 1000; /* Ensure it's above other elements */
`;
// Title for the App Bar
export const AppBarTitle = styled.h1`
  font-size: 22px;
  font-weight: bold;
  color: white;
`;

// Text Section Below the App Bar (Description of the Profession ID Card)
export const DescriptionContainer = styled.div`
  padding: 20px;
  
  text-align: center;
`;

export const DescriptionText = styled.p`
  color: #b0b0b0;
  font-size: 12px;
  font-style: italic;
  color: #555;
  margin: 0;
  line-height: 1.5;
  word-wrap: break-word;
  padding: 10px 0;
`;
export const CardContainer = styled.div`
  perspective: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: #f0f0f0;
  margin-bottom: 80px;
  position: relative;
`;

export const IdCard = styled.div`
  width: 380px;
  height: 250px;
  background: linear-gradient(135deg, #1f2a3b, #2a3a52);
  border-radius: 20px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  transition: transform 0.6s ease, box-shadow 0.3s ease;
  transform-style: preserve-3d;
  position: relative;
  font-family: 'Arial', sans-serif;

  &:hover {
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.3);
  }

  &.flipped {
    transform: rotateY(180deg);
  }
`;

export const IdCardSide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #2b3c49;
  border-radius: 15px;
`;

export const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const ProfilePicWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const VerificationBadge = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => (props.verified ? '#4caf50' : 'red')}; /* Green for verified, red for unverified */
  font-size: 16px;
  font-weight: bold;

  span {
    margin-left: 8px;
  }
`;

export const InfoField = styled.div`
  display: flex;
  justify-content: space-between;

  label {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }

  span {
    color: #b0b0b0;
    font-size: 15px;
  }
`;

export const Barcode = styled.div`
  margin-top: 20px;
  color: #b0b0b0;
  font-size: 14px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between the buttons */
  width: 100%;
`;

export const EditIcon = styled.div`
  cursor: pointer;
  color: #4caf50;
  font-size: 22px;
  transition: color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    color: #45a049;
  }
`;

export const FlipButton = styled.button`
  margin-top: 20px;
  background: linear-gradient(135deg, #1f2a3b, #2a3a52);

  color: white;
  font-size: 16px;
  padding: 12px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #45a049;
  }
`;
export const TextContainer = styled.div`
  height: ${(props) => (props.height ? props.height : '100px')};  /* Default height of 100px */
  overflow-y: auto;  /* Enable vertical scrolling if the content overflows */
  margin-left: 15px;  /* Space between QR code and text */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically align the text */
  text-align: left; /* Align the text to the left */
`;


export const TextContent = styled.p`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  color: #b0b0b0;
  font-size: 12px;
 
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  
  span {
    font-style: italic;
    color: #b0b0b0;
  }
`;


export const SuccessBanner = styled.div`
  background-color: green;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1000;
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Modal = styled.div`
  background-color: white;
  padding: 20px;
  width: 300px;
  border-radius: 8px;
  margin: auto;
  top: 30%;
  position: relative;
  z-index: 1000;
  text-align: center;
`;

export const ModalContent = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: space-around;
  
  button {
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }

  button:hover {
    background-color: #45a049;
  }
`;
export const ErrorModal = styled(Modal)`
  border: 2px solid red; /* Highlighting error */
`;

export const ErrorModalContent = styled(ModalContent)`
  color: red;
  font-weight: bold;
`;

import React, { useContext, useState, useEffect } from 'react';
import { FiSearch, FiEdit2, FiTrash2, FiClock, FiToggleLeft, FiToggleRight, FiCalendar } from "react-icons/fi";
import { format } from "date-fns";
import styled from 'styled-components';
import {ButtonContainer, FullWidthContainer, ChannelManagementButton, Container, Heading, Button, SearchInput, ChannelCard, ChannelTitle, ChannelDescription, StatusBadge, } from './StyledComponents';
import InitialSetup from './InitialSetup';
import { CoinContext } from '../../contextdtatashare/CoinContext';
import AsbekAppwriteService from '../../api/AsbekAppwriteService';
import ChannelManagement from './ChannelManagement';
import ExplanationPage from './ExplanationPage';

const appwriteService = new AsbekAppwriteService();

const TelegramManager = () => {
  const tg = window.Telegram.WebApp;
  tg.ready();

  // Initialize Telegram data
  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;

  // Replace with actual userId if dynamically assigned
  const userId = String(usertg?.id).trim();

  const handleGetStarted = () => {
    if (!userId || userId === "undefined") {
      // Redirect to Telegram bot link if userId is undefined
      window.location.href = "https://t.me/asbeksbot/start";
    } else {
      // Set view to "setup"
      setView("setup");
    }
  };
  const {
    loadingChannels,
      setPhoneNumber,
      setEmail,
      setUsersCountry,
      setFullName,
      setProfession,
      setProfilePic,
      email,
      thePhoneNumber,
      theUsername,
      fullName,
      profession,
      UsersCountry,
      verified,
      profilePic,
      quotesfiles,
      setWeeklyClickCount,
      theRank,
      setTheRank,
      friendsCount,
      taskEarn,
      appConfig,
      withdrawDate,
      allowWithdraw,
      withdrawRequestType,
      setWithdrawalRequests,
      setWithdrawRequestType,
      withdrawalRequests,
      withdrawLimit,
      theCoinValue,
      theasbeksPrice,
      decrementCoinValue,
      channels,
      setChannels,posts, setPosts,telegramChatId, setTelegramChatId
    } = useContext(CoinContext);
  const [view, setView] = useState("dashboard");
  
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [newChannel, setNewChannel] = useState({ link: "", description: "", schedule: "daily" });

  const filteredChannels = channels.filter(channel =>
    (channel.name ?? "Unnamed Channel").toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  const Dashboard = () => (
    <Container>
      <FullWidthContainer>
      <ButtonContainer>
        <Button onClick={() => setView("setup")}>Add New Channel</Button>
        <Button onClick={() => setView("explanation")}>Learn More</Button>
      </ButtonContainer>
      <Heading>Your Registered Channels</Heading>
    </FullWidthContainer>

      <div>
        <SearchInput
          type="text"
          placeholder="Search channels..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div>
      {loadingChannels ? (
        
  <div><center> Loading your channels...</center></div>
) : channels?.length === 0 ? (
  <div>
    No channels found. Create one now and let Asbeks AI manage your content seamlessly, even if you're away for months. 
    <span 
  onClick={() => setView("setup")}
  style={{ color: "#007BFF", cursor: "pointer", textDecoration: "underline" }}
>
  Create Your Channel Now
</span>
  </div>
) : (
  <div>
    {filteredChannels.map((channel) => (
      <ChannelCard key={channel.$id}>
        <div>
          <ChannelTitle>{channel.name || "Unnamed Channel"}</ChannelTitle>
         {/* <ChannelDescription>{channel.description || "No description available"}</ChannelDescription> */}
        </div>
        <StatusBadge active={channel.isActive}>
          {channel.isActive ? "Active" : "Inactive"}
        </StatusBadge>
        <ChannelManagementButton
          onClick={() => {
            setSelectedChannel(channel);
            setView("manage");
          }}
        >
          Manage Channel
        </ChannelManagementButton>
      </ChannelCard>
    ))}
  </div>
)}

      </div>
    </Container>
  );

  return view === "dashboard" ? (
    <Dashboard />
  ) : view === "setup" ? (
    <InitialSetup />
  ) : view === "explanation" ? (
    <ExplanationPage onGetStarted={handleGetStarted} />
  ) : (
    <ChannelManagement selectedChannel={selectedChannel} />
  );
};

export default TelegramManager;

import React, { useState } from "react";
import styled from "styled-components";

// Placeholder Images
const demoImage1 = "https://via.placeholder.com/600x400";
const demoImage2 = "https://via.placeholder.com/620x420";
const demoImage3 = "https://via.placeholder.com/640x440";
const demoImage4 = "https://via.placeholder.com/660x460";

// Styled Components
const GalleryContainer = styled.div`
  margin: 0 auto;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Default to 2 columns */
  gap: 10px;
  width: 100%;
  max-width: 1200px;

  @media (min-width: 800px) {
    /* On large screens, show all images in a single row */
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 600px) {
    /* On large screens, show all images in a single row */
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ImageCard = styled.div`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
  }
`;

const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.editMode ? 1 : 0)};
  transition: opacity 0.2s ease;

  label {
    cursor: pointer;
    margin: 0 5px;
    background-color: hsla(0, 0.00%, 100.00%, 0.80);
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  button {
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    border: none;
    margin: 0 5px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
`;

const UploadInput = styled.input`
  display: none;
`;

// Component
const Gallery = ({ editMode }) => {
  const [images, setImages] = useState([
    demoImage1,
    demoImage2,
    demoImage3,
    demoImage4,
  ]);

  const handleImageUpload = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const newImages = [...images];
      newImages[index] = URL.createObjectURL(file);
      setImages(newImages);
    }
  };

  const handleDeleteImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
  };

  return (
    <GalleryContainer>
      {images.map((image, index) => (
        <ImageCard key={index}>
          <img src={image} alt={`Gallery ${index + 1}`} />
          {editMode && (
            <HoverOverlay editMode={editMode}>
              <label>
                Edit
                <UploadInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(index, e)}
                />
              </label>
              <button onClick={() => handleDeleteImage(index)}>Delete</button>
            </HoverOverlay>
          )}
        </ImageCard>
      ))}
      {editMode && (
        <ImageCard>
          <label>
            <UploadInput
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  setImages([...images, URL.createObjectURL(file)]);
                }
              }}
            />
            <HoverOverlay editMode={editMode}>
              <label>Add New</label>
            </HoverOverlay>
          </label>
        </ImageCard>
      )}
    </GalleryContainer>
  );
};

export default Gallery;

import React, { useContext, useState, useEffect } from 'react';
import Barcode from 'react-barcode'; // Import the Barcode component
import QRCode from 'react-qr-code';
import {
  CardContainer,
  IdCard,
  IdCardSide,
  ProfileSection,
  ProfilePicWrapper,
  ProfileDetails,
  VerificationBadge,
  InfoField,
  EditIcon,
  FlipButton,
  TextContent,
  TextContainer,
  SuccessBanner, // Assuming a styled component for the success banner
  Modal, // Modal for confirmation
  ModalContent, // Modal content
  ModalActions, // Modal actions
  ModalBackground, // Background for modal
  AppBar,ErrorModal, ErrorModalContent,
  AppBarTitle, DescriptionContainer, DescriptionText, ButtonContainer
} from './idcardstyle';
import { CoinContext } from '../contextdtatashare/CoinContext';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import { ShareID } from './invite';
const appwriteService = new AsbekAppwriteService();

const InfoFieldComponent = ({ label, value, isEditing, name, onChange, isDisabled }) => (
    <InfoField>
      <label>{label}</label>
      {isEditing && !isDisabled ? (
        <input
          type="text"
          name={name}
          defaultValue={value}
          onBlur={(e) => onChange(e)} // Trigger update on losing focus
        />
      ) : (
        <span>{value}</span>
      )}
    </InfoField>
  );
  

const IDCard = () => {
  const flipCard = () => {
    setIsFlipped((prev) => !prev);
  };
  const [showError, setShowError] = useState(false);
  const handleError = () => setShowError(true);
  const closeErrorModal = () => setShowError(false);
  const [message, setMessage] = useState("");
  // Extracting user data from CoinContext
  const {
    setPhoneNumber,
    setEmail,
    setUsersCountry,
    setFullName,
    setProfession,
    setProfilePic,
    email,
    thePhoneNumber,
    theUsername,
    fullName,
    profession,
    UsersCountry,
    verified,
    profilePic,
    quotesfiles,
    setWeeklyClickCount,
    theRank,
    setTheRank,
    friendsCount,
    taskEarn,
    appConfig,
    withdrawDate,
    allowWithdraw,
    withdrawRequestType,
    setWithdrawalRequests,
    setWithdrawRequestType,
    withdrawalRequests,
    withdrawLimit,
    theCoinValue,
    theasbeksPrice,
    decrementCoinValue,
  } = useContext(CoinContext);

  const [isEditing, setIsEditing] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [profileImage, setProfileImage] = useState(profilePic || 'https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);

  const [showModal, setShowModal] = useState(false); // Modal state for confirmation
  const [IDDetails, setIDDetails] = useState({
    fullName: '',
    profession: '',
    country: '',
    phoneNumber: '',
    email: '',
    username: '',
  });
const tg = window.Telegram.WebApp;
  tg.ready();

  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;

 

  const userId = String(usertg?.id).trim();
  
  useEffect(() => {
    setIDDetails({
      fullName: fullName,
      email: email,
      phoneNumber: thePhoneNumber,
      username: theUsername,
      profession: profession,
      country: UsersCountry,
      profilePic: profilePic,
    });
  }, [fullName, email, thePhoneNumber, theUsername, profession, UsersCountry, profilePic]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIDDetails(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      await appwriteService.updateDocument(userId, IDDetails);
      setShowSuccessBanner(true);
  
      setTimeout(() => {
        setShowSuccessBanner(false);
      }, 3000);
    } catch (error) {
      console.error('Error saving account details:', error);
    }
  };
  

  const handleImageChange = async (e) => {
    if(theCoinValue < 500){
        setMessage('insufficient Balance you need to have atleast 500 Asbeks ')
        handleError()
        return
    }
    const file = e.target.files[0];
    if (file) {
      try {
        // Upload the profile picture and get the URL
        const fileUrl = await appwriteService.uploadProfilePic(file, userId);
        decrementCoinValue(500)
        // Update the profile image state
        setProfileImage(fileUrl);

        // Optionally, you can update the user's profilePic in the context if you are using it elsewhere
        setProfilePic(fileUrl);

      } catch (error) {
        console.error("Error uploading profile picture:", error);
      }
    }
};

  const toggleEdit = () => {
    if (isEditing) {
      // If editing is being disabled, show the confirmation modal
      setShowModal(true);
    } else {
      // Otherwise, just toggle the editing state
      setIsEditing((prev) => !prev);
    }
  };

  const confirmSaveChanges = () => {
    // Save the changes
    handleSubmit();
  
    // Close the modal
    setShowModal(false);
    setShowSuccessBanner(true);
  
    // Hide success banner after 2 seconds
    setTimeout(() => setShowSuccessBanner(false), 2000);
  
    // Disable editing state
    setIsEditing(false);
  };
  

  const cancelSaveChanges = () => {
    // If the user cancels, just close the modal without saving
    setShowModal(false);
    setIsEditing(false);  // Ensure the editing state is reset
  };
  const title="My Profession Id CArd";
  //   const userId = String(user.id).trim();
  const telegramLink = userId ? `https://t.me/asbeksbot/start/?startapp=myid${userId}` : '';
  return (
    <CardContainer>
        <AppBar>
        <AppBarTitle>Profession ID Card</AppBarTitle>
      </AppBar>
      <DescriptionContainer>
      <DescriptionText>
  The Profession ID Card is a verified representation of your professional credentials, designed to showcase your skills and expertise with credibility. Once verified, you can confidently share it with employers, clients, or collaborators, ensuring trust and authenticity in your professional interactions.
  
  To start the verification process for your ID, please <a href="https://t.me/abdiisabaadm" target="_blank" rel="noopener noreferrer">Contact Us</a>. We're here to help you verify your credentials and enhance your professional presence.
</DescriptionText>

      </DescriptionContainer>
    
      {showSuccessBanner && (
        <SuccessBanner>Changes saved successfully!</SuccessBanner>
      )}
{showError && (
        <>
          <ModalBackground onClick={closeErrorModal} />
          <ErrorModal>
            <ErrorModalContent>{message}</ErrorModalContent>
            <ModalActions>
              <button onClick={closeErrorModal}>Close</button>
            </ModalActions>
          </ErrorModal>
        </>
      )}
      {/* Modal for Confirmation */}
      {showModal && (
        <ModalBackground>
          <Modal>
            <ModalContent>
              <h3>Are you sure you want to save these changes?</h3>
              <ModalActions>
                <button onClick={confirmSaveChanges}>Yes</button>
                <button onClick={cancelSaveChanges}>No</button>
              </ModalActions>
            </ModalContent>
          </Modal>
        </ModalBackground>
      )}

      <IdCard className={isFlipped ? 'flipped' : ''}>
        {/* Front Side */}
        <IdCardSide>
          <ProfileSection>
            <ProfilePicWrapper style={{ position: 'relative' }}>
              <img
                src={profileImage}
                alt="Profile"
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
              {isEditing && (
                <label
                  htmlFor="file-upload"
                  style={{
                    position: 'absolute',
                    top: '10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    fontSize: '24px',
                    cursor: 'pointer',
                    zIndex: 1,
                  }}
                >
                  📷
                </label>
              )}
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
            </ProfilePicWrapper>
            <VerificationBadge verified={verified}>
              {verified ? '✔ Verified' : '❌ Unverified'}
            </VerificationBadge>
          </ProfileSection>
          <ProfileDetails>
          <InfoFieldComponent
              label="Full Name:"
              value={IDDetails.fullName || 'John Doe'}
              isEditing={isEditing}
              name="fullName"
              onChange={handleInputChange}
              isDisabled={verified} // Prevent editing if verified
            />
<InfoFieldComponent
  label="Profession:"
  value={IDDetails.profession || 'Your Profession'}
  isEditing={isEditing}
  name="profession"
  onChange={handleInputChange}
  isDisabled={verified}
/>
<InfoFieldComponent
  label="Country:"
  value={IDDetails.country || 'Your Country'}
  isEditing={isEditing}
  name="country"
  onChange={handleInputChange}
  isDisabled={verified}
/>
          </ProfileDetails>
        </IdCardSide>

        {/* Back Side */}
        <IdCardSide style={{ transform: 'rotateY(180deg)', padding: '20px' }}>
          <ProfileDetails style={{ wordWrap: 'break-word' }}>
         
    <InfoFieldComponent
      label="Contact:"
      value={IDDetails.phoneNumber || '(+123) 456-7890'}
      isEditing={isEditing}
      name="phoneNumber"
      onChange={handleInputChange}
    />
    <InfoFieldComponent
      label="Email:"
      value={IDDetails.email || 'john.doe@example.com'}
      isEditing={isEditing}
      name="email"
      onChange={handleInputChange}
    />
    <InfoFieldComponent
      label="Telegram Username:"
      value={IDDetails.username || '@yourusername'}
      isEditing={isEditing}
      name="username"
      onChange={handleInputChange}
    />
            
          </ProfileDetails>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '10px', width: '100%' }}>
  <div style={{ width: '100px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <QRCode value={telegramLink} size={100} />
  </div>
  <TextContainer height="120px">
    <TextContent bold>
      This ID is issued by Asbeks.
    </TextContent>
    <TextContent>
      The owner of this ID is{' '}
      <span style={{ color: verified ? 'green' : 'red', fontWeight: 'bold' }}>
        {verified ? 'verified' : 'not yet verified'}
      </span>{' '}
      as a{' '}
      <span style={{ color: 'dodgerblue', fontWeight: 'bold' }}>
        {profession || 'Your Profession'}
      </span>{' '}
      and is{' '}
      <span style={{ color: verified ? 'green' : 'red', fontWeight: 'bold' }}>
        {verified ? 'trusted' : 'not trusted at this time'}
      </span>.
    </TextContent>
  </TextContainer>
</div>

        </IdCardSide>
      </IdCard>
      <FlipButton onClick={flipCard}>
          {isFlipped ? 'Flip to Front' : 'Flip to Back'}
        </FlipButton>
      {/* Edit Icon and Flip Button */}
      <div style={{ marginTop: '20px' }}>
        <EditIcon onClick={toggleEdit}>{isEditing ? '✔ save' : '✎ edit'}</EditIcon>
        
        <div style={{ marginTop: '20px' }}>
        <ShareID inviteLink={telegramLink} title={title}/>
        </div>
      </div>
    </CardContainer>
  );
};

export default IDCard;

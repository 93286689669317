import styled from 'styled-components';

export const FormContainer = styled.div`
  max-width: 600px;
  margin: 20px auto;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to bottom, #f8f9fa, #ffffff);
  font-family: 'Arial', sans-serif;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-weight: 600;
  margin-bottom: 8px;
  color: #343a40;
`;

export const TextArea = styled.textarea`
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  resize: vertical;
  min-height: 100px;
  transition: border-color 0.3s, box-shadow 0.3s;
  font-family: 'Arial', sans-serif;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
    outline: none;
  }
`;

export const SubmitButton = styled.button`
  padding: 14px 20px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.02);
  }

  &:active {
    background-color: #004085;
  }
`;

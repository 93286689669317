import React, { useState } from 'react';
import styled from 'styled-components';
import QuoteGenerator from './quoteUI';
import TelegramManager from './Telegram Channel/TGManager';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1f1f1f;
  min-height: 100vh;
  padding: 20px;
  color: #fff;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

const Card = styled.div`
  background: linear-gradient(135deg, #6c5ce7, #0984e3);
  border-radius: 15px;
  width: 300px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-10px);
  }
`;

const CardTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

const CardDescription = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #0984e3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #6c5ce7;
  }
`;
const AIPage = () => {
  const [view, setView] = useState("dashboard");

  const goToQuotePage = () => {
    setView("quote");
  };

  const goToTelegramAutomationPage = () => {
    setView("telegramAutomation");
  };

  const goBack = () => {
    setView("dashboard");
  };
  return view === "dashboard" ? (
    <PageContainer>
    
      <Title>AI-Powered Tools</Title>
      <CardsContainer>
        <Card onClick={goToQuotePage}>
          <CardTitle>Quote Generator AI</CardTitle>
          <CardDescription>
            Create amazing quotes with images, perfect for your posts and social media.
          </CardDescription>
          <Button>Go to Quote Page</Button>
        </Card>
        <Card onClick={goToTelegramAutomationPage}>
          <CardTitle>Telegram Channel Automation AI</CardTitle>
          <CardDescription>
            Automate your posts tailored to your audience, 24/7, even when you’re away for months.
          </CardDescription>
          <Button>Go to Telegram Automation</Button>
        </Card>
      </CardsContainer>
    
    </PageContainer>
  ) : view === "quote" ? (
    <div>
          
          <span onClick={goBack}
          style={{zIndex: 9999, color: "#007BFF", cursor: "pointer", textDecoration: "underline" }}

          >Back to AI Page</span>
  
    <QuoteGenerator />
    </div>
  ) : view === "telegramAutomation" ? (
    <div>
          <span onClick={goBack}
          style={{ zIndex: 9999, color: "#007BFF", cursor: "pointer", textDecoration: "underline" }}

          >Back to AI Page</span>
          <TelegramManager />
        </div>
  ) : null;
  
 
};

export default AIPage;

import React, { useState } from 'react';
import { Mistral } from '@mistralai/mistralai';
import styled from 'styled-components';
import html2canvas from 'html2canvas';

const QuoteGenerator = () => {
  const [quote, setQuote] = useState('');
  const [loading, setLoading] = useState(false);
  const [previousQuotes, setPreviousQuotes] = useState([]);
  const [selectedType, setSelectedType] = useState('motivational'); // Track selected type

  const apiKey = 'vt2Kk2MCvBjQiNwXTsNqncgzsOK08q06'; // Your Mistral API key
  const client = new Mistral({ apiKey });

  // Dynamically generate the prompt based on the selected quote type
  const generatePrompt = () => {
    switch (selectedType) {
      case 'motivational':
        return 'Create 1 motivational quote for success.';
      case 'inspirational':
        return 'Create 1 inspirational quote for perseverance.';
      case 'funny':
        return 'Create 1 funny quote for laughter.';
      case 'philosophical':
        return 'Create 1 philosophical quote about life and existence.';
      case 'leadership':
        return 'Create 1 leadership quote on guiding others to success.';
      case 'love':
        return 'Create 1 romantic quote about love and relationships.';
      case 'wisdom':
        return 'Create 1 quote about wisdom and learning.';
      case 'happiness':
        return 'Create 1 quote about finding happiness and joy.';
      case 'friendship':
        return 'Create 1 quote about friendship and relationships.';
      default:
        return 'Create 1 motivational quote for success.';
    }
  };

  const generateQuote = async () => {
    setLoading(true);
    try {
      const randomPrompt = generatePrompt(); // Use the dynamic prompt

      // Fetch a new quote from the AI
      const response = await client.chat.complete({
        model: 'mistral-large-latest',
        messages: [{ role: 'system', content: randomPrompt }],
        temperature: 0.8, // Adjust for randomness
      });

      const newQuote = response.choices[0].message.content.trim();

      // Avoid duplicate quotes
      if (!previousQuotes.includes(newQuote)) {
        setQuote(newQuote);
        setPreviousQuotes([...previousQuotes, newQuote]);
      } else {
        setQuote('Generated a duplicate quote, try again.');
      }
    } catch (error) {
      console.error('Error generating quote:', error);
      setQuote('An error occurred while generating the quote. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to download the card as an image
  const downloadCard = () => {
    const element = document.getElementById('quote-card');
    html2canvas(element).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'quote-card.png';
      link.click();
    });
  };

  return (
    <Container>
      <Title>AI Quote Generator</Title>
      <QuoteCard id="quote-card">
        <QuoteText>{quote || 'Your generated quote will appear here!'}</QuoteText>
        <QuoteFooter>Generated with 💻 &amp; AI</QuoteFooter>
      </QuoteCard>

      <RadioGroup>
        {['motivational', 'inspirational', 'funny', 'philosophical', 'leadership', 'love', 'wisdom', 'happiness', 'friendship'].map(type => (
          <RadioLabel key={type}>
            <input
              type="radio"
              value={type}
              checked={selectedType === type}
              onChange={() => setSelectedType(type)}
            />
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </RadioLabel>
        ))}
      </RadioGroup>

      <GenerateButton onClick={generateQuote} disabled={loading}>
        {loading ? 'Generating...' : 'Generate Quote'}
      </GenerateButton>

      <DownloadButton onClick={downloadCard}>
        Download as Image
      </DownloadButton>
    </Container>
  );
};

export default QuoteGenerator;

// Styled Components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background: linear-gradient(135deg, #f06, #4a90e2);
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 26px;
  color: #fff;
  margin-bottom: 20px;
  font-weight: bold;
`;

const QuoteCard = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  min-height: 150px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  text-align: center;
`;

const QuoteText = styled.p`
  font-size: 22px;
  font-style: italic;
  color: #333;
  line-height: 1.5;
  margin-bottom: 10px;
`;

const QuoteFooter = styled.div`
  font-size: 14px;
  color: #aaa;
  margin-top: 10px;
`;

const GenerateButton = styled.button`
  padding: 12px 25px;
  border-radius: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  max-width: 200px;
  margin-top: 20px;
  transition: all 0.3s ease;

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #0056b3;
  }
`;

const DownloadButton = styled.button`
  padding: 12px 25px;
  border-radius: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  max-width: 200px;
  margin-top: 20px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
`;

const RadioLabel = styled.label`
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;

  input {
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.8;
  }
`;


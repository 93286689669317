import styled from 'styled-components';
export const FullWidthContainer = styled.div`
  width: 100%;
  padding: 1rem 2rem; /* Add padding for vertical and horizontal breathing space */
  background-color: #f9f9f9; /* Optional background color */
  box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; /* Add spacing between buttons and heading */
`;

export const Button = styled.button`
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const Heading = styled.h1`
  font-size: 1.8rem;
  color: #333;
  text-align: center; /* Center the heading */
  margin: 0;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

export const SearchInput = styled.input`
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid #d1d5db;
  font-size: 1rem;
`;

export const ChannelCard = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
`;

export const ChannelTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937;
`;

export const ChannelDescription = styled.p`
  color: #4b5563;
  margin-top: 0.5rem;
`;

export const StatusBadge = styled.span`
  background-color: ${(props) => (props.active ? '#d1fae5' : '#fef2f2')};
  color: ${(props) => (props.active ? '#16a34a' : '#b91c1c')};
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 600;
`;
export const ChannelManagementButton = styled.button`
  background-color: #3b82f6;
  color: white;
  width: 100%;
  padding: 1rem;
  border-radius: 0.375rem;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #2563eb;
  }
`;
import styled from 'styled-components';

// Create a styled component for the container
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left (start) */
  justify-content: flex-start; /* Align items to the top */
  height: 100vh; /* Take full viewport height */
  width: 100%; /* Take full width */
  background: linear-gradient(135deg, #6e7fdf, #b5d8d8);
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 20px; /* Optional padding for some space */
  
  /* Allow scrolling when content overflows */
  overflow-y: auto; /* Make the container scrollable if the content exceeds the height */
  
  /* Ensure that the content inside is able to stretch */
  flex-grow: 1;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;


export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures space between logo and action button */
  padding: 20px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  
  @media (max-width: 768px) {
    flex-direction: row; /* Keeps the layout in a row */
    align-items: center; /* Keeps the items vertically aligned */
  }
`;

export const Logo = styled.img`
  width: 40px;
  height: auto;
  margin-right: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 35px;
  }
`;

export const Name = styled.h1`
  font-size: 1.8rem;
  color: #fff;
  margin: 0;
  font-weight: 600;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.3rem;
  color: #fff;
  font-weight: bold;
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    transform: scale(1.05);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.4);
    transform: scale(0.98);
  }

  @media (max-width: 768px) {
    font-size: 0.5rem;
    padding: 8px 11px;
  }
`;

export const Switch = styled.input`
  margin-left: 10px;
  width: 40px;
  height: 20px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ddd;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

  &:checked {
    background-color: #4caf50;
  }

  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 17px;
    height: 17px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s ease;
  }

  &:checked:before {
    left: 28px;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 22px;
  }
`;

export const OverlayButton = styled.button`
  position: fixed;
  bottom: ${(props) => (props.bottom ? "80px" : "200px")};
  right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 15px;
  font-size: 1.5rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  z-index: 10;

  &:hover {
    background-color: rgba(0, 0, 0, 1);
  }
`;
import styled from 'styled-components';

// Modern vibrant gradient colors with animation
const vibrantGradients = [
    'linear-gradient(135deg, #ff8a00, #e52e71)', // Bold Orange to Deep Pink (Energetic & Bold)
    'linear-gradient(135deg, #f7b7a3, #f9d7b3)', // Soft Peach to Pale Yellow (Subtle & Elegant)
    'linear-gradient(135deg, #4facfe, #00f2fe)', // Sky Blue to Turquoise (Fresh & Cool)
    'linear-gradient(135deg, #ff9a8b, #ff6a00)', // Warm Peach to Orange (Elegant & Lively)
    'linear-gradient(135deg, #2c3e50, #16a085)', // Dark Teal to Emerald Green (Sophisticated & Serene)
    'linear-gradient(135deg, #a6c0fe, #f68084)', // Light Blue to Soft Coral (Feminine & Soft)
    'linear-gradient(135deg, #673ab7, #512da8)', // Deep Purple to Violet (Luxury & Mystique)
    'linear-gradient(135deg, #00d2ff, #28df99)', // Cyan to Mint Green (Futuristic & Refreshing)
    'linear-gradient(135deg, #9b4dca, #4f29f1)', // Purple to Electric Blue (Bold & Luxurious)
    'linear-gradient(135deg, #f3a683, #f7b731)', // Soft Orange to Golden Yellow (Warm & Inviting)
    'linear-gradient(135deg, #3e5151, #decba4)', // Teal to Light Beige (Elegant & Classy)
    'linear-gradient(135deg, #9d50bb, #6e48aa)', // Purple to Dark Violet (Mystic & Bold)
    'linear-gradient(135deg, #ff0844, #ffb199)', // Red to Peach (Modern & Playful)
    'linear-gradient(135deg, #dce35b, #45b649)', // Lime Green to Fresh Grass Green (Vibrant & Natural)
    'linear-gradient(135deg, #ff6a00, #ee0979)', // Vivid Orange to Neon Pink (Energetic & Stylish)
    'linear-gradient(135deg, #ffffff, #e6e6e6)', // Pure White to Light Gray (Minimal & Sleek)
    'linear-gradient(135deg, #f953c6, #b91d73)', // Soft Pink to Deep Purple (Chic & Modern)
    'linear-gradient(135deg, #f3ec78, #af4261)', // Light Yellow to Deep Red (Vibrant & Passionate)
    'linear-gradient(135deg, #ffafbd, #ffc3a0)', // Soft Pink to Peach (Feminine & Sweet)
    'linear-gradient(135deg, #ff9a8b, #d8d0ff)', // Light Pink to Lavender (Charming & Elegant)
    'linear-gradient(135deg, #38ef7d, #11998e)', // Lime Green to Teal (Fresh & Energizing)
    'linear-gradient(135deg, #f1c40f, #e67e22)', // Golden Yellow to Orange (Warm & Inviting)
    'linear-gradient(135deg, #9c27b0, #ff80ab)', // Deep Purple to Soft Pink (Regal & Feminine)
    'linear-gradient(135deg, #b92b27, #f2a900)', // Red to Yellow (Bold & Warm)
    'linear-gradient(135deg, #cc2b5e, #753a88)', // Rich Red to Purple (Bold & Elegant)
    'linear-gradient(135deg, #0f2027, #203a43, #2c5364)', // Dark Teal to Deep Blue (Sophisticated & Mysterious)
    'linear-gradient(135deg, #c8c8c8, #ffffff)', // Light Gray to White (Minimal & Professional)
    'linear-gradient(135deg, #ffb300, #ff9e00)', // Gold to Amber (Elegant & Luxe)
    'linear-gradient(135deg, #000000, #434343)', // Black to Dark Gray (Sleek & Edgy)
    'linear-gradient(135deg, #be2edd, #8e44ad)', // Bold Purple to Deep Violet (Elegant & Mystical)
    'linear-gradient(135deg, #f8cdda, #fbc2eb)', // Soft Pink to Lavender (Sweet & Soft)
    'linear-gradient(135deg, #74ebd5, #9face6)', // Aqua to Soft Blue (Fresh & Calming)
    'linear-gradient(135deg, #e9d362, #333333)', // Yellow to Dark Charcoal (Bold & Modern)
    'linear-gradient(135deg, #fd746c, #ff9068)', // Sunset Red to Peach (Warm & Inviting)
    'linear-gradient(135deg, #20b2aa, #000080)', // Light Sea Green to Navy (Sophisticated & Timeless)
    'linear-gradient(135deg, #02aab0, #00cdac)', // Turquoise to Mint Green (Futuristic & Fresh)
    'linear-gradient(135deg, #ed6e6e, #ff9a9e)', // Coral to Pale Pink (Fresh & Soft)
    'linear-gradient(135deg, #1d976c, #93f9b9)', // Emerald Green to Mint Green (Calming & Refreshing)
    'linear-gradient(135deg, #2a2a72, #009ffd)', // Dark Blue to Bright Blue (Bold & Modern)
    'linear-gradient(135deg, #6a11cb, #2575fc)', // Purple to Electric Blue (Futuristic & High-Tech)
    'linear-gradient(135deg, #fa9f6d, #f2eb61)', // Warm Orange to Yellow (Inviting & Cozy)
    'linear-gradient(135deg, #93a5cf, #e4efe9)', // Soft Blue to Light Green (Calm & Soothing)
  ];
  
  
  


  
export const Wrapper = styled.div`
  background: linear-gradient(to bottom, #f3f4f6, #e2e8f0);
  padding: 20px;
  font-family: 'Inter', sans-serif;
  height: 100%;
  overflow-y: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
`;

export const Header = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 40px;
  background: linear-gradient(135deg, #0d2c54, #0e88c3); /* Deep Blue to Light Blue Gradient */
  border-radius: 25px;
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2); /* Softer, more refined shadow */
  color: white;
  font-family: 'Poppins', sans-serif; /* Clean, modern font */
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3); /* Hover glow effect */
  }

  /* Mobile responsive */
  @media (max-width: 768px) {
    padding: 20px 30px;
  }

  @media (max-width: 480px) {
    padding: 15px 20px;
  }
`;

export const Balance = styled.div`
  background: linear-gradient(135deg, #4caf50, #2196f3);
  padding: 20px 30px;
  border-radius: 10px;
  text-align: center;
  color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    z-index: 1;
    box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.05);
  }

  /* Ensure the button is above the pseudo-element */
  button {
    position: relative;
    z-index: 2;
  }
`;



export const BalanceText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.8;
  margin-bottom: 8px;
`;

export const BalanceAmount = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 2px;
  margin-top: 10px;
  color: white;
  font-family: 'Lora', serif; /* Elegant serif font for VIP look */
`;

export const Filters = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  /* Mobile responsive */
  @media (max-width: 768px) {
    gap: 15px;
  }
`;

export const FilterButton = styled.button`
  background: ${({ active }) => (active ? '#f39c12' : '#34495e')}; /* Active state is gold, inactive is dark gray */
  color: ${({ active }) => (active ? 'white' : '#f39c12')};
  border: 2px solid #f39c12;
  padding: 12px 30px;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: capitalize;

  /* Button hover effect */
  &:hover {
    background: #f39c12;
    color: white;
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Light shadow effect */
  }

  /* Mobile responsive */
  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 10px 25px;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 8px 20px;
  }
`;





export const TaskCard = styled.div`
  background: ${({ status }) =>
    status === 'completed'
      ? '#4ade80' // Completed status with green
      : vibrantGradients[Math.floor(Math.random() * vibrantGradients.length)]}; // Modern gradient
  color: white;
  padding: 20px;
  border-radius: 18px; // A more subtle rounded corner for a sleek card look
  margin-bottom: 20px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1); // Softer, more subtle shadow for a high-end look
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease; // Smooth transition for background and shadow
  backdrop-filter: blur(8px); // More pronounced blur effect for a card-like finish

  &:hover {
    transform: translateY(-8px); // Subtle lift effect for interactivity
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2); // Deeper shadow on hover
  }
`;

export const TaskDetails = styled.div`
  margin-bottom: 15px;
  padding: 0 10px; // Added padding for spacing
`;

export const TaskTitle = styled.div`
  font-size: 1.4rem; // Slightly larger title for better visibility
  font-weight: 700; // Heavier font for emphasis
  margin-bottom: 10px; // Added margin for better spacing
  text-transform: capitalize; // Ensures a clean title appearance
  letter-spacing: 1px; // Adds subtle spacing for a more premium feel
`;

export const TaskDescription = styled.div`
  font-size: 1rem;
  margin: 5px 0;
  opacity: 0.9; // Slightly more opacity for clarity
  line-height: 1.6; // Increased line height for readability
  font-weight: 400; // Regular weight for description text
`;

export const TaskEarn = styled.div`
  font-size: 1rem;
  font-weight: 500;
  margin-top: 10px;
  font-style: italic; // Italicized to differentiate earnings from other text
  opacity: 0.9; // Slight opacity to keep the focus on the title and description
  letter-spacing: 0.5px; // Slight letter spacing for a more elegant look
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px; // Added margin for spacing between actions
`;

export const ActionButton = styled.button`
  background: ${({ disabled }) => (disabled ? '#e2e8f0' : '#22c55e')};
  color: ${({ disabled }) => (disabled ? '#6b7280' : 'white')};
  border: none;
  padding: 12px 22px;
  border-radius: 12px; // Rounded edges for the button to match the card's style
  font-size: 1rem;
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s ease, transform 0.2s ease; // Transition for smooth hover effects
  
  &:hover {
    background: ${({ disabled }) => (disabled ? '#e2e8f0' : '#16a34a')};
    transform: scale(1.05); // Subtle scaling effect for interactivity
  }
`;

export const FAB = styled.div`
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, #0ea5e9, #0284c7);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: all 0.3s ease, background-color 0.3s ease;
  z-index: 1000;

  &:hover {
    background: linear-gradient(135deg, #0284c7, #2563eb);
    transform: scale(1.15);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
`;

export const FABIcon = styled.div`
  font-size: 2rem;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;

  ${FAB}:hover & {
    transform: rotate(15deg);
  }
`;

export const FABTooltip = styled.span`
  position: absolute;
  bottom: 90px;
  right: 5px;
  background: rgba(37, 99, 235, 0.9);
  color: white;
  padding: 10px 14px;
  border-radius: 12px;
  font-size: 0.85rem;
  white-space: nowrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  ${FAB}:hover & {
    opacity: 1;
    transform: translateY(0);
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: rgba(37, 99, 235, 0.9) transparent transparent transparent;
  }
`;
export const FABText = styled.div`
  color: white;
  font-size: 2rem;
  font-weight: bold;
  transition: opacity 0.3s ease;
  opacity: 0;
`;
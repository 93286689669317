import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

const GameContainer = styled.div`
  position: relative;
  height: 100vh;
  background-color: black;
  overflow: hidden;
`;

const StarStyled = styled.div`
  color: gold;
  font-size: 24px;
  position: absolute;
`;

const Plate = styled.div`
  position: absolute;
  bottom: 10%;
  left: ${(props) => props.left}%;
  width: 100px;
  height: 20px;
  background: linear-gradient(to top, #ffcc00, #ff9900);
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-out;
`;

const ScoreDisplay = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  font-size: 24px;
`;

const SplashAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
`;

const Splash = styled.div`
  position: absolute;
  color: gold;
  font-size: 24px;
  animation: ${SplashAnimation} 0.5s ease-out forwards;
`;

const Star = ({ style }) => <StarStyled style={style}>★</StarStyled>;

const Game = () => {
  const [stars, setStars] = useState([]);
  const [score, setScore] = useState(0);
  const [playerPosition, setPlayerPosition] = useState(50);
  const [splashes, setSplashes] = useState([]);
  const [isGameOver, setIsGameOver] = useState(false);

  // Create a new star every second
  const createStar = useCallback(() => {
    const newStar = {
      id: Date.now(),
      left: Math.random() * 90, // Random horizontal position (percentage)
      top: 0, // Start at the top
      speed: Math.random() * 3 + 2, // Falling speed
    };
    setStars((prev) => [...prev, newStar]);
  }, []);

  useEffect(() => {
    const starInterval = setInterval(createStar, 1000);
    return () => clearInterval(starInterval);
  }, [createStar]);

  // Update star positions every frame using requestAnimationFrame
  const updateStars = useCallback(() => {
    setStars((prev) =>
      prev
        .map((star) => ({ ...star, top: star.top + star.speed }))
        .filter((star) => {
          if (star.top > 90) {
            setScore((prev) => prev - 1);
            if (score <= 0) {
              setIsGameOver(true);
            }
            return false;
          }
          return true;
        })
    );
  }, [score]);

  useEffect(() => {
    const gameLoop = () => {
      if (!isGameOver) {
        updateStars();
        requestAnimationFrame(gameLoop);
      }
    };
    gameLoop();
  }, [updateStars, isGameOver]);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowLeft') {
      setPlayerPosition((prev) => Math.max(prev - 5, 0)); // Move left
    } else if (e.key === 'ArrowRight') {
      setPlayerPosition((prev) => Math.min(prev + 5, 90)); // Move right
    }
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const screenWidth = window.innerWidth;
    const newPlayerPosition = (touch.clientX / screenWidth) * 100;
    setPlayerPosition(Math.min(Math.max(newPlayerPosition, 0), 90)); // Keep within bounds
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Improved collision detection logic
  const checkCollision = useCallback(() => {
    setStars((prev) =>
      prev.filter((star) => {
        const plateLeft = playerPosition * (window.innerWidth / 100); // Plate's left edge
        const plateRight = plateLeft + 100; // Plate's right edge
        const plateTop = window.innerHeight * 0.8; // Plate's top edge
        const plateBottom = plateTop + 20; // Plate's bottom edge

        // Star's exact boundaries
        const starLeft = star.left * (window.innerWidth / 100); // Star's left edge
        const starRight = starLeft + 24; // Star's right edge (24px size)
        const starTop = star.top * (window.innerHeight / 100); // Star's top edge
        const starBottom = starTop + 24; // Star's bottom edge

        // Check if the star intersects with the plate's boundaries
        const isXOverlap = starRight >= plateLeft && starLeft <= plateRight;
        const isYOverlap = starBottom >= plateTop && starTop <= plateBottom;

        // If both X and Y overlap, the star is caught
        if (isXOverlap && isYOverlap) {
          setScore((prev) => prev + 1);

          // Add splash effect
          setSplashes((splashes) => [
            ...splashes,
            { id: Date.now(), left: star.left, top: star.top },
          ]);

          return false; // Remove the star after catching
        }

        return true;
      })
    );
  }, [playerPosition]);

  useEffect(() => {
    const collisionInterval = setInterval(checkCollision, 50);
    return () => clearInterval(collisionInterval);
  }, [checkCollision]);

  // Clean up splashes after animation ends
  useEffect(() => {
    const splashCleanup = setInterval(() => {
      setSplashes((prev) => prev.filter((splash) => Date.now() - splash.id < 500));
    }, 100);
    return () => clearInterval(splashCleanup);
  }, []);

  return (
    <GameContainer onTouchMove={handleTouchMove}>
      {stars.map((star) => (
        <Star key={star.id} style={{ top: `${star.top}%`, left: `${star.left}%` }} />
      ))}

      {splashes.map((splash) => (
        <Splash key={splash.id} style={{ top: `${splash.top}%`, left: `${splash.left}%` }}>
          ★
        </Splash>
      ))}

      <Plate left={playerPosition} />

      <ScoreDisplay>Score: {score}</ScoreDisplay>
      {isGameOver && <div style={{ color: 'white', fontSize: '30px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Game Over</div>}
    </GameContainer>
  );
};

export default Game;

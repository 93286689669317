import React, { useContext, useState, useEffect } from 'react';
import { FiSearch, FiEdit2, FiTrash2, FiClock, FiToggleLeft, FiToggleRight, FiCalendar } from "react-icons/fi";
import { format } from "date-fns";
import styled from 'styled-components';
import TelegramManager from './TGManager';
import { CoinContext } from '../../contextdtatashare/CoinContext';
import AsbekAppwriteService from '../../api/AsbekAppwriteService';
import axios from 'axios';
import Step4 from './Step4';

import {
    SetupContainer,
    BackButton,
    Title,
    StepContainer,
    ActionButton,
    InputField,
    StatusMessage,
    RetryButton,
    SuccessMessage
} from './initialStepStyle';

const appwriteService = new AsbekAppwriteService();

const InitialSetup = () => {
    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [isCompleted, setIsCompleted] = useState(false);
    const [adminCheckStatus, setAdminCheckStatus] = useState(null);
    const [step, setStep] = useState(1);
    const {
          setPhoneNumber,
          setEmail,
          setUsersCountry,
          setFullName,
          setProfession,
          setProfilePic,
          email,
          thePhoneNumber,
          theUsername,
          fullName,
          profession,
          UsersCountry,
          verified,
          profilePic,
          quotesfiles,
          setWeeklyClickCount,
          theRank,
          setTheRank,
          friendsCount,
          taskEarn,
          appConfig,
          withdrawDate,
          allowWithdraw,
          withdrawRequestType,
          setWithdrawalRequests,
          setWithdrawRequestType,
          withdrawalRequests,
          withdrawLimit,
          theCoinValue,
          theasbeksPrice,
          decrementCoinValue,
          channels,
          setChannels,posts, setPosts,telegramChatId, setTelegramChatId
        } = useContext(CoinContext);
      const [view, setView] = useState("");
      
      const [selectedChannel, setSelectedChannel] = useState(null);
      const [searchQuery, setSearchQuery] = useState("");

    
    const [newChannel, setNewChannel] = useState({
      owner: '',
      goal: '',
      mainTopic: '',
      targetAudience: '',
      preferredContentFormat: '',
      channelHandle: '',
      telegramChatId: '',
      name: '',
      description: '',
      link: '',
    });
    const handleChannelLinkSubmit = async () => {
        setLoading(true);
        setStatusMessage('Checking Telegram link...');
        setStep(2);
        try{
        // Check if @asbeksbot is an admin in the provided channel link
        const isTelegramLink = newChannel.link.startsWith('https://t.me/') || newChannel.link.startsWith('http://t.me/') || newChannel.link.startsWith('https://telegram.me/') || newChannel.link.startsWith('http://telegram.me/');
      if (!isTelegramLink) {
        setStatusMessage('Invalid Telegram link. Please provide a valid link.');
                setLoading(false);
                return;
      }
      setStatusMessage('Checking if it’s your channel...');
      const username = newChannel.link.startsWith('https://t.me/')
                ? newChannel.link.replace('https://t.me/', '').split(/[/\?]/)[0] // Split on either '/' or '?'
                : newChannel.link.replace('http://t.me/', '').split(/[/\?]/)[0];

        const isBot = username.endsWith('bot');
        if(isBot){
            setStatusMessage('The provided link points to a bot. Please provide a link to a channel.');
                setLoading(false);
                return;
        }
        setStatusMessage('Connecting to the channel...');
        const responseOfbot = await axios.get(`https://67100c95ea75afe346f4.appwrite.global/getChatId?username=${username}`);
        
        console.log('username', username);

        if (responseOfbot) {
            console.log('Channel Info:', responseOfbot);
            console.log('Channel ID:', responseOfbot.data.channelId);
            setNewChannel((prev) => ({
                ...prev,
                channelHandle: `@${username}`,
                name: String(responseOfbot.data.channelName), // Converts channelName to a string
                telegramChatId: String(responseOfbot.data.channelId), // Converts channelId to a string
                owner: String('445929610'), // Ensures owner is treated as a string
            }));
            
            
        } else {
            console.log('Error:', );
        }
        setStatusMessage('Checking if @asbeksbot is an admin...');
        const response = await fetch(`https://66f46cdd4587b0b3ee6b.appwrite.global/check_membership/@${username}/445929610`);
        console.log('response ID:', response);

        const data = await response.json();
        if (data.error) {
            setStatusMessage(data.error);
            setLoading(false);
            return;
        }
        // if (data.error == 'Failed to retrieve bot status in the channel/group.'){
        //     console.log('bot is not even member in the channel');
        //     setStatusMessage('Failed to retrieve bot status in the channel.');
        //     setLoading(false);
        //     return;
        // }else if (data.error == 'The bot is not an admin in this channel or group.'){
        //     setStatusMessage('The bot is not an admin in the channel.');
        //     setLoading(false);
        //     return;
        //     console.log('bot is not admin');
        //   }
          if (['administrator', 'creator'].includes(data.status)) {
            
            setStatusMessage('Success! @asbeksbot is an admin.');
            setStep(4);
            setIsCompleted(true);
              
          } else {
            setStatusMessage('Oops! You are not owner or Admin in this Channel/group');
                setStep(3);
            setStep(3); // Show feedback if @asbeksbot is not an admin
          }

        }catch (error) {
            console.error('Failed to :', error.message);
            alert(error.message)
          } finally {
            setLoading(false);
        }
      };
    
      const checkIfAsbeksBotIsAdmin = async (link) => {
        // Implement logic to check if @asbeksbot is an admin in the channel.
        // For now, we'll return true as a placeholder
        return true; 
      };
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setNewChannel((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleAddChannel = async (e) => {
      e.preventDefault();
    
      // Validate and prepare channel data
      const channelData = {
        owner: newChannel.owner || '',
        goal: newChannel.goal || 'To educate and engage the audience with high-quality, attention-grabbing facts.',
        mainTopic: newChannel.mainTopic || 'Sharing intriguing, unique facts across various topics.',
        targetAudience: newChannel.targetAudience || 'Knowledge-seeking individuals who enjoy professionally presented facts.',
        preferredContentFormat: newChannel.preferredContentFormat || 'Text-based facts with a professional tone and call-to-action.',
        channelHandle: newChannel.channelHandle || '',
        telegramChatId: newChannel.telegramChatId || '',
        name: newChannel.name || '',
        description: newChannel.description || 'Enter brief description of your channel',
        link: newChannel.link || '',
        schedule: newChannel.schedule || 'daily',
        isActive: true, // Assuming the channel is active by default
      };
    
      try {
        // Save the channel data to Appwrite
        await appwriteService.createChannel(channelData);
    
        // After successful creation, update the state with the new channel
        setChannels((prevChannels) => [...prevChannels, channelData]);
    
        // Reset the form state after successful submission
        setNewChannel({
          owner: '',
          goal: '',
          mainTopic: '',
          targetAudience: '',
          preferredContentFormat: '',
          channelHandle: '',
          telegramChatId: '',
          name: '',
          description: '',
          link: '',
          schedule: 'daily'
        });
    
        // Optionally, switch to dashboard or give feedback
        setStatusMessage("Channel successfully added!");
        setView("dashboard");
        
      } catch (error) {
        console.error("Error adding channel: ", error);
        // Handle errors (e.g., show error message to user)
        setStatusMessage("Error occurred while adding channel. Please try again.");
      }
    };
  if (view === "dashboard"){
     return <TelegramManager />
}
    return (
      <SetupContainer>
        <BackButton onClick={() => setView("dashboard")}>← Back to Dashboard</BackButton>
        <Title>Add New Telegram Channel</Title>

        {step === 1 && (
                <StepContainer>
                    <p>1. Make @asbeksbot an admin of your channel.</p>
                    <ActionButton onClick={() => setStep(2)}>I have made @asbeksbot admin</ActionButton>
                </StepContainer>
            )}

{step === 2 && (
                <StepContainer>
                    <p>2. Please enter your channel link starting with https:</p>
                    <InputField
                        type="text"
                        name="link"
                        value={newChannel.link}
                        onChange={handleInputChange}
                        placeholder="https://t.me/yourchannel"
                        required
                    />
                    <ActionButton onClick={handleChannelLinkSubmit} disabled={loading}>
                        {loading ? 'Checking...' : 'Continue'}
                    </ActionButton>

                    <StatusMessage success={statusMessage.includes('Success')}>{statusMessage}</StatusMessage>
                </StepContainer>
            )}

            {step === 3 && (
                <StepContainer>
                    <StatusMessage>{statusMessage}</StatusMessage>
                    <RetryButton onClick={() => setStep(2)}>Try Again</RetryButton>
                </StepContainer>
            )}

{step === 4 && (
    <Step4
        step={step}
        newChannel={newChannel}
        handleInputChange={handleInputChange}
        handleAddChannel={handleAddChannel}
        statusMessage={statusMessage}
    />
)}


        
      </SetupContainer>
    );
  };

  export default InitialSetup;
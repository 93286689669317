import styled, { keyframes } from 'styled-components';

export const Card = styled.div`
  background: linear-gradient(145deg, #ffffff 0%, #a5d6f3 30%, #81c784 60%, #7986cb 100%); /* Gradient with blues, teal, and purple */
  border: 1px solid #cfd8dc; /* Light gray-blue border to complement the gradient */
  border-radius: 16px;
  padding: 24px;
  max-width: 400px;
  margin: 30px auto;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Soft shadow for a modern lift */
  font-family: "Roboto", sans-serif;
  
  
  @media (max-width: 600px) {
    padding: 16px;
  }
`;



export const Title = styled.h2`
  font-size: 22px;
  color: #34495e;
  margin-bottom: 20px;
  font-weight: bold;
`;

export const Balance = styled.p`
  font-size: 14px; /* Smaller font size */
  color: white;
  font-weight: 600;
  margin: 0;
  padding: 8px 12px; /* Padding for a more compact look */
  background: linear-gradient(135deg, #6a11cb, #2575fc); /* Cool diagonal gradient */
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-align: center; /* Center the text */
  display: inline-block; /* Allow it to fit its content */
`;


export const Input = styled.input`
  width: 100%;
  padding: 14px;
  margin-bottom: 20px;
  border: 1px solid #b0c4de;
  border-radius: 10px;
  font-size: 16px;
  background: #f9fbfd;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
  padding-right: 60px; /* Space for the button inside */
  
  &:focus {
    border-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
    outline: none;
  }
`;

export const Button = styled.button`
  background: ${(props) => (props.disabled ? "#dcdcdc" : "linear-gradient(90deg, #3498db, #2980b9)")};
  color: #fff;
  border: none;
  border-radius: 12px;
  padding: 14px;
  font-size: 16px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: 100%;
  transition: background 0.3s, transform 0.2s;

  &:hover {
    background: ${(props) => (props.disabled ? "#dcdcdc" : "linear-gradient(90deg, #2980b9, #1f618d)")};
    transform: ${(props) => (props.disabled ? "none" : "scale(1.02)")};
  }
`;

export const Message = styled.p`
  color: ${(props) => (props.success ? "#27ae60" : "#d32f2f")};
  font-size: 14px;
  margin-bottom: 10px;
  background-color: ${(props) => (props.success ? "#eafaf1" : "#fdecea")};
  padding: 12px;
  border-left: 4px solid ${(props) => (props.success ? "#27ae60" : "#d32f2f")};
  border-top: 1px solid ${(props) => (props.success ? "#d4edda" : "#f5c6cb")};
  border-right: 1px solid ${(props) => (props.success ? "#d4edda" : "#f5c6cb")};
  border-bottom: 1px solid ${(props) => (props.success ? "#d4edda" : "#f5c6cb")};
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin-top: 20px;
`;


export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 20px;
  text-align: left;
`;

export const ListItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;
  padding: 8px 12px;
  border-radius: 8px;
  background: ${(props) => (props.pending ? "#fff4e6" : "#eafaf1")};
  border: ${(props) => (props.pending ? "1px solid #f0a500" : "1px solid #27ae60")};
`;

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

export const TableHeader = styled.th`
  padding: 10px;
  font-weight: bold;
  background-color: #f4f4f4;
`;

export const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #fafafa;
  color: #333;

  @media (max-width: 400px) {
    padding: 8px;
  }
`;

export const Skeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  height: 17px;
  width: 100px;
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;

export const DropdownIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 16px;
  cursor: pointer;
  color: ${(props) => (props.open ? "#007AFF" : "#555")};
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s ease, color 0.3s ease;

  &:hover {
    color: #007AFF;
  }
`;


export const CollapsibleContainer = styled.div`
  max-height: ${(props) => (props.open ? "500px" : "0")};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
`;

export const FilterContainer = styled.div`
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; // Full-screen height to center content vertically
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

export const FilterButton = styled.button`
  padding: 6px 12px; // Smaller padding for compact buttons
  font-size: 12px; // Small font size for filter-style buttons
  border-radius: 12px; // Rounded corners for a clean look
  border: 1px solid black; // Border hidden by default, as it's replaced with gradient
  background-image: linear-gradient(135deg, #ff00b3, #ff7300, #00c6ff, #36d7b7); // Vibrant multi-color gradient
  color: white; // White text color
  cursor: pointer; // Pointer cursor on hover
  transition: all 0.3s ease; // Smooth transition for hover effect
  text-transform: capitalize; // Capitalize button text for consistency
  text-shadow: 5px 5px 8px rgba(0, 0, 0, 0.9); // Add a soft shadow for better visibility

  // Responsive size adjustments
  @media (max-width: 600px) {
    padding: 4px 8px; // Smaller padding on small screens
    font-size: 10px; // Smaller font size on small screens
  }

  // Hover effect: Slightly darker version of the gradient
  &:hover {
    background-image: linear-gradient(135deg, #e60099, #e06f00, #00b3e6, #2eb8a3); // Darker gradient on hover
    color: white; // White text on hover
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); // Darker shadow on hover
  }

  // Active (selected) state: More intense gradient change
  &:active {
    background-image: linear-gradient(135deg, #d5006f, #e85d00, #0091ea, #28b6a1); // Stronger gradient for selection
    color: white; // White text when clicked
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7); // Stronger shadow when active
  }

  // Disabled state: Light gray background and text
  &:disabled {
    background-color: #f0f0f0; // Light gray background
    color: #a0a0a0; // Light gray text color
    border: 1px solid #d0d0d0; // Light gray border
    cursor: not-allowed; // Cursor to indicate it's disabled
    text-shadow: none; // No shadow for disabled state
  }
`;

export const badgeStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px', // Changed from left to right for the top-right position
  backgroundColor: '#FF5733', // Modern orange-red
  color: '#ffffff',
  fontSize: '12px',
  fontWeight: 'bold',
  padding: '4px 10px',
  borderRadius: '15px', // Rounded for a pill shape
  textTransform: 'uppercase',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Light shadow for depth
  letterSpacing: '0.5px',
};

export const Badge = styled.div`
  position: flex;
  top: 10px;
  right: 80px; /* Positioned at the top-right corner */
  background-color: #FF5733; /* Modern orange-red */
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 10px;
  border-radius: 15px; /* Rounded for a pill shape */
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Light shadow for depth */
  letter-spacing: 0.5px;
`;
import React from 'react';
import {
  FormContainer,
  Form,
  FormGroup,
  Label,
  Input,
  TextArea,
  SubmitButton,
} from './Step4Style';

const Step4 = ({ step, newChannel, handleInputChange,handleAddChannel, statusMessage, }) => {
  return (
    step === 4 && (
      <FormContainer>
        <Form onSubmit={handleAddChannel}>
          <h2>Customize Your Asbeks AI</h2>
          <p>
            Please provide the details below to register your channel. These details will be used by Asbeks AI to generate posts tailored to your channel's needs. Fields with default values can be customized to suit your preferences. You can edit these details later if necessary, so feel free to provide as much detail as possible to ensure high-quality content generation.
            </p>


          {[
            { label: 'Channel Link', name: 'link', placeholder: "https://t.me/yourchannel" , readOnly: true },
            { label: 'Owner', name: 'owner', placeholder: "Owner's Name" , readOnly: true },
            { label: 'Name', name: 'name', placeholder: 'Channel Name' },
            { label: 'Channel Handle', name: 'channelHandle', placeholder: 'Channel Handle', readOnly: true },
            { label: 'Telegram Chat ID', name: 'telegramChatId', placeholder: 'Telegram Chat ID' , readOnly: true},
            { 
              label: 'Goal', 
              name: 'goal', 
              placeholder: 'Channel Goal', 
              defaultValue: 'To educate and engage the audience with high-quality, attention-grabbing facts.' 
            },
            { 
              label: 'Main Topic', 
              name: 'mainTopic', 
              placeholder: 'Main Topic', 
              defaultValue: 'Sharing intriguing, unique facts across various topics.' 
            },
            { 
              label: 'Target Audience', 
              name: 'targetAudience', 
              placeholder: 'Target Audience', 
              defaultValue: 'Knowledge-seeking individuals who enjoy professionally presented facts.' 
            },
            { 
              label: 'Preferred Content Format', 
              name: 'preferredContentFormat', 
              placeholder: 'Preferred Content Format', 
              defaultValue: 'Text-based facts with a professional tone and call-to-action.' 
            },
            { 
                label: 'Description', 
                name: 'description', 
                placeholder: 'Channel Description', 
                defaultValue: 'Enter brief description of your channel' 
              },
          ].map((field, index) => (
            <FormGroup key={index}>
  <Label>
    {field.label}
    {field.readOnly && (
      <span
        style={{
          marginLeft: '8px',
          padding: '2px 6px',
          backgroundColor: '#e0e0e0',
          color: '#555',
          borderRadius: '12px',
          fontSize: '12px',
          fontWeight: 'bold',
        }}
      >
        Uneditable
      </span>
    )}
  </Label>
  <TextArea
    name={field.name}
    value={newChannel[field.name] || field.defaultValue || ''}
    onChange={handleInputChange}
    placeholder={field.placeholder}
    required
    readOnly={field.readOnly || false}
    style={field.readOnly ? { backgroundColor: '#f9f9f9', cursor: 'not-allowed' } : {}}
  />
            </FormGroup>

          ))}


          <SubmitButton type="submit">Register Channel</SubmitButton>
        </Form>
        {statusMessage && (
          <div
            style={{
            marginTop: '16px',
              marginBottom: '16px',
              padding: '10px 15px',
              backgroundColor: '#f0f8ff',
              color: '#007bff',
              borderRadius: '8px',
              border: '1px solid #007bff',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            {statusMessage}
          </div>
        )}
      </FormContainer>
    )
  );
};

export default Step4;

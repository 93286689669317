import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AsbekAppwriteService from '../api/AsbekAppwriteService';

const appwriteService = new AsbekAppwriteService();

// Styled Components
const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #45a049;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ImageList = styled.div`
  display: ${props => (props.isVisible ? 'grid' : 'none')};
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 15px;
  max-height: 400px;
  overflow-y: auto;
`;

const ImageCard = styled.div`
  position: relative;
  width: 100%;
  max-width: 250px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
`;

const ButtonGroup = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  gap: 5px;
  z-index: 10;
`;

const DownloadButton = styled(Button)`
  background-color: #FF5722;
  padding: 8px 14px;
  font-size: 14px;
  box-shadow: none;

  &:hover {
    background-color: #e64a19;
  }
`;

const ShareButton = styled(Button)`
  background-color: #009688;
  padding: 8px 14px;
  font-size: 14px;
  box-shadow: none;

  &:hover {
    background-color: #00796b;
  }
`;

const ReloadButton = styled(Button)`
  background-color: #FF9800;
  padding: 8px 14px;
  font-size: 14px;
  box-shadow: none;
  margin-top: 10px;

  &:hover {
    background-color: #f57c00;
  }
`;

const Banner = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  /* Center it */
  background-color: rgba(0, 0, 0, 0.7);  /* Semi-transparent background */
  color: white;
  padding: 20px 30px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  z-index: 9999;  /* Ensure it's above all other elements */
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  word-wrap: break-word;
  text-align: center;
`;

const UsersImages = ({ userId }) => {
  const [fileUrls, setFileUrls] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [bannerMessage, setBannerMessage] = useState('');

  const fetchFiles = async () => {
    try {
      const files = await appwriteService.fetchUserFiles(userId);
      setFileUrls(files);
    } catch (error) {
      console.error('Error fetching user files:', error);
      setBannerMessage('Failed to fetch images.');
      setBannerVisible(true);
      setTimeout(() => {
        setBannerVisible(false);
      }, 3000);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [userId]);

  const toggleDropdown = () => {
    setIsDropdownVisible(prevState => !prevState);
  };

  const downloadFile = (url) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = url.split('/').pop(); // Extract filename from URL
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl); // Free up memory

        // Show success banner
        setBannerMessage('File downloaded successfully!');
        setBannerVisible(true);

        // Hide banner after 3 seconds
        setTimeout(() => {
          setBannerVisible(false);
        }, 3000);
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
        setBannerMessage('Failed to download file.');
        setBannerVisible(true);

        setTimeout(() => {
          setBannerVisible(false);
        }, 3000);
      });
  };

  const shareStory = (url) => {
    try {
      const mediaUrl = url;

      // Ensure Telegram WebApp is ready
      const tg = window.Telegram?.WebApp;

      // Check if the method is available
      if (tg && typeof tg.shareToStory === 'function') {
        // Share the story with parameters
        tg.shareToStory(mediaUrl, {
          is_private: false, // Set to true if you want the story to be private
          caption: 'Asbeks', // Add a caption to the story
          background_color: '#FFFFFF' // Optional background color
        });

        // Show success banner
        setBannerMessage('Story shared successfully!');
        setBannerVisible(true);

        // Hide banner after 3 seconds
        setTimeout(() => {
          setBannerVisible(false);
        }, 3000);
      } else {
        console.error('shareToStory is not available in this WebApp version.');
      }
    } catch (error) {
      console.error('Error sharing story:', error);
      setBannerMessage('Failed to share story.');
      setBannerVisible(true);

      setTimeout(() => {
        setBannerVisible(false);
      }, 3000);
    }
  };

  return (
    <div>
      <Button onClick={toggleDropdown}>
        {isDropdownVisible ? 'Hide My Quotes' : 'Show My Quotes'}
      </Button>

      <ReloadButton onClick={fetchFiles}>Reload My Quotes</ReloadButton>

      <ImageList isVisible={isDropdownVisible}>
        {fileUrls.length > 0 ? (
          fileUrls.map((url, index) => (
            <ImageCard key={index}>
              <Image src={url} alt={`User file ${index + 1}`} />
              <ButtonGroup>
                <DownloadButton onClick={() => downloadFile(url)}>
                  Download
                </DownloadButton>
                <ShareButton onClick={() => shareStory(url)}>
                  Share as Story
                </ShareButton>
              </ButtonGroup>
            </ImageCard>
          ))
        ) : (
          <p>No images available.</p>
        )}
      </ImageList>

      {bannerVisible && <Banner>{bannerMessage}</Banner>}
    </div>
  );
};

export default UsersImages;

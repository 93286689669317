import React, { useEffect, useRef, useState } from "react";
import {VideoWrapper, CommunityHighlight, Wrapper,CallToAction,Feature,FeatureDescription,FeatureTitle,Footer,Header,Heading,Parallax,Section,SectionTitle,SocialLinks,SubHeading,Video } from "./style";
import { FaTelegramPlane } from "react-icons/fa";
import ReactPlayer from "react-player";
import AsbeksPrice from "../asbek/AsbeksPrice";
import { Helmet } from "react-helmet";
import ExplanationPage from "../AI/Telegram Channel/ExplanationPage";
const ExplosiveWebsite = () => {
  const [view, setView] = useState("dashboard");
  const [activeFeatureIndex, setActiveFeatureIndex] = useState(null); // State to track the active feature index
  const featureRefs = useRef([]); // Array of refs for the features
  const [viewExplanationPage, setViewExplanationPage] = useState(false); // Track ExplanationPage visibility
  const handleLearnMore = () => {
    setView("explanation"); // Open ExplanationPage
  };
  const tg = window.Telegram.WebApp;
  tg.ready();

  // Initialize Telegram data
  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;

  // Replace with actual userId if dynamically assigned
  const userId = String(usertg?.id).trim();

  const handleGetStarted = () => {
    if (!userId || userId === "undefined") {
      // Redirect to Telegram bot link if userId is undefined
      window.location.href = "https://t.me/asbeksbot/start";
    } else {
      // Set view to "setup"
      setView("explanation");
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const index = featureRefs.current.indexOf(entry.target);
        if (entry.isIntersecting) {
          setActiveFeatureIndex(index); // Set the index of the feature that is in view
        }
      },
      { threshold: 1 } // Trigger when 50% of the feature card is in the viewport
    );

    featureRefs.current.forEach((feature) => {
      observer.observe(feature); // Start observing each feature
    });

    return () => {
      featureRefs.current.forEach((feature) => {
        observer.unobserve(feature); // Cleanup observer on unmount
      });
    };
  }, []);
  if(view === 'explanation'){
    return <ExplanationPage onGetStarted={handleGetStarted} />
  }
  return (
    <Wrapper>
      <Helmet>
  <title>Asbeks - Grow , Earn Rewards, Boost Your Telegram and other social media Audiences, Complete Tasks, create your own Tasks and Grow with the TON Blockchain</title>
    
  <meta
    name="description"
    content="Asbeks empowers you to grow your Telegram audience effortlessly. Complete tasks, watch ads, engage with real users, and earn TON cryptocurrency rewards. Join a dynamic community of over 400k users!"
  />
  <meta
          name="description"
          content="Leverage AI to automate your Telegram channel. Enhance efficiency, boost engagement, and save time with intelligent solutions."
        />
        <meta
          name="keywords"
          content="AI Channel Automation, Telegram Automation, AI Bot, Efficiency, Engagement, Task Automation"
        />
  <meta 
    name="keywords" 
    content="Asbeks, earn money online, grow Telegram audience, task management, TON blockchain, cryptocurrency rewards, task creators, Telegram engagement, ad watching, decentralized platform, increase followers, TikTok, YouTube, blockchain rewards, Telegram groups, rewards system, community building, real users, secure transactions, task rewards, platform for creators, Telegram rewards, engagement platform" 
  />
  <meta
    property="og:title"
    content="Asbeks - Boost Your Telegram Reach, Engage, and Earn TON"
  />
  <meta
    property="og:description"
    content="Asbeks helps you grow your Telegram following with real interactions. Watch ads, complete tasks, and earn TON cryptocurrency rewards while joining a thriving community of 400k+ users."
  />
  <meta
    property="og:image"
    content="https://github.com/asbeks/asbeks.github.io/blob/main/photo_2024-12-14_23-36-28.jpg?raw=true"
  />
  <meta property="og:url" content="https://www.asbeks.com" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="Asbeks - Boost Your Telegram Reach, Engage, and Earn TON"
  />
  <meta
    name="twitter:description"
    content="Grow your Telegram reach, complete tasks, and earn TON cryptocurrency with Asbeks. Watch ads and engage with real users in our growing community of over 400k members."
  />
  <meta
    name="twitter:image"
    content="https://github.com/asbeks/asbeks.github.io/blob/main/photo_2024-12-14_23-36-28.jpg?raw=true"
  />
  <meta name="twitter:creator" content="@AsbeksOfficial" />

  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "Asbeks",
      "url": "https://www.asbeks.com",
      "potentialAction": [
        {
          "@type": "SearchAction",
          "target": "https://www.asbeks.com/search?q={search_term}",
          "query-input": "required name=search_term"
        }
      ],
      "description": "Asbeks empowers you to grow your Telegram audience effortlessly. Complete tasks, watch ads, engage with real users, and earn TON cryptocurrency rewards.",
      "image": "https://github.com/asbeks/asbeks.github.io/blob/main/photo_2024-12-14_23-36-28.jpg?raw=true"
    })}
  </script>
</Helmet>
      {/* Header Section */}
      <Header>
        <Heading>Welcome to Asbeks</Heading>
        <SubHeading>
          The Ultimate Platform to Boost Your Telegram Audience & Earn Amazing Rewards.
        </SubHeading>
        <CommunityHighlight>
    Join our 400k+ strong community!
  </CommunityHighlight>
  
        <CallToAction href="https://t.me/asbeksbot/start/?startapp=6307965462">Start Now</CallToAction>
      </Header>

      {/* Parallax Section */}
      <Parallax>
        <div>
      <p>Maximize Your Earnings and Grow Your Audience with Asbeks – Watch Ads, Earn TON, and Lead the Clicks</p>
      <SocialLinks>
  <a href="https://t.me/abdiisabaadm" target="_blank" rel="noopener noreferrer">
    <FaTelegramPlane /> Need help?
  </a>
</SocialLinks>
     </div>
      </Parallax>
      
      <Section>
  <SectionTitle>Channel Automation with Asbeks AI</SectionTitle>
  <p>Is your channel going quiet because you’re not posting? Are you struggling to keep your audience engaged while managing a busy schedule or being away?</p>
  <p>
    Automate your Telegram channel management effortlessly. Save time, grow your audience with real users, 
    and boost engagement like never before. Let Asbeks handle the hard work for you.
  </p>
  <CallToAction as="button" onClick={handleLearnMore}>
              Learn More
            </CallToAction>
</Section>


      {/* Features Section */}
      <Section>
        <SectionTitle>What Asbeks Offers</SectionTitle>
        {["Simple and Engaging", "Get Telegram Followers", "Grow Your Social Media", "Earn Rewards", "Task Verification", "Built on TON Blockchain", "Telegram Mini App", "Wallet Integration", "Send Asbeks to Friends"].map((title, index) => (
          <Feature
            key={index}
            ref={(el) => (featureRefs.current[index] = el)}
            className={activeFeatureIndex === index ? "in-view glow" : ""} // Only apply the glow effect to the active feature
          >
            <FeatureTitle>{title}</FeatureTitle>
            <FeatureDescription>
              {/* Feature description based on index */}
              {index === 0 && "Asbeks effortlessly keeps your audience engaged, offering valuable rewards for their time and participation."}
              {index === 1 && "Connect with real users who follow your channel or group. Watch your audience grow effortlessly."}
              {index === 2 && "Increase followers and engagement on platforms like TikTok, YouTube, and more, with real, active users."}
              {index === 3 && "Complete tasks, watch ads, earn money and withdraw them instantly on your wallet."}
              {index === 4 && "Our system verifies that each task, like joining groups or joining channels, is genuinely completed."}
              {index === 5 && "Asbeks is built on the TON blockchain, ensuring secure, decentralized transactions for users."}
              {index === 6 && "Experience Asbeks seamlessly within Telegram and enjoy a full range of features without leaving your chat."}
              {index === 7 && "Easily connect your wallet to buy and withdraw Asbeks, all within a secure and trusted environment."}
              {index === 8 && "Effortlessly send your Asbeks to friends with just a few taps."}
            </FeatureDescription>
          </Feature>
        ))}
        
      </Section>
      <Feature>
        <FeatureTitle>Weekly Rewards</FeatureTitle>
        <FeatureDescription>
          Top users with the most interactions get rewarded weekly. The more you engage, the more you earn!
        </FeatureDescription>
      </Feature>
      <Feature>
        <FeatureTitle>Become a Task Creator</FeatureTitle>
        <FeatureDescription>
          Create exciting tasks on Asbeks and watch your audience grow! With amazing engagement and the potential to attract new followers, it's your chance to build a thriving community while rewarding participants.
        </FeatureDescription>
      </Feature>

      {/* Video Tutorial Section */}
      <Section>
      <div>
      <AsbeksPrice></AsbeksPrice>
      </div>
    <SectionTitle>How Asbeks Works</SectionTitle>
    <VideoWrapper>
      <ReactPlayer
        className="react-player"
        url="https://youtu.be/m4gXLCBDPxg?si=ziFVee5APCpEiopG"
        controls
        width="80%"
        height="400px"
      />
    </VideoWrapper>
  </Section>

      {/* Call-to-Action Section */}
      <Section>
        <SectionTitle>Ready to Level Up Your Telegram?</SectionTitle>
        <SubHeading>Join the thousands of users already growing with Asbeks.</SubHeading>
        <CallToAction href="https://t.me/asbeksbot/start/?startapp=6307965462">Join Now</CallToAction>
      </Section>

      {/* Footer Section */}
      <Footer>
      <SocialLinks>
  <a href="https://t.me/abdiisabaadm" target="_blank" rel="noopener noreferrer">
    <FaTelegramPlane /> Contact Us
  </a>
</SocialLinks>
        <p>&copy; 2024 Asbeks. All Rights Reserved.</p>


      </Footer>
    </Wrapper>
  );
};

export default ExplosiveWebsite;

import React, { useContext } from 'react';
import { CoinContext } from "../../contextdtatashare/CoinContext";
import { FaUserFriends, FaTasks, FaCoins } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import RewardWithdrawal from './reward';
const formatValue = (value) => {
    if (value >= 1_000_000_000) {
      return `${(value / 1_000_000_000).toFixed(1)}B`;
    }
    if (value >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    }
    if (value >= 1_000) {
      return `${(value / 1_000).toFixed(1)}k`;
    }
    return value.toString();
  };
// Keyframe animation for "Oh wow" effect
const fadeIn = keyframes`
  0% { opacity: 0; transform: scale(0.9); }
  100% { opacity: 1; transform: scale(1); }
`;

const CriteriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  max-width: 400px;
  margin: 20px auto;
  padding: 15px;
  background: linear-gradient(145deg, #1e3c72, #2a5298); /* Cool gradient */
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const Title = styled.h2`
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 600;
`;

const CriteriaCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
  padding: 10px;
  background: #1f2a44; /* Darker background for cards */
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }
`;

const CriteriaText = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #e0e0e0;
`;

const Icon = styled.div`
  margin-right: 12px;
  font-size: 20px;
  color: #7efff5; /* Cool turquoise */
`;

const StatusIcon = styled.span`
  font-size: 22px;
  color: ${(props) => (props.success ? '#4CAF50' : '#FF6347')};
`;

const RewardMessage = styled.div`
  margin-top: 15px;
  padding: 12px;
  background: #273246;
  border-radius: 10px;
  font-size: 16px;
  color: #e0e0e0;
  text-align: center;
  font-weight: 500;
`;

const WithdrawCriteria = () => {
  const {appConfig, withdrawLimit, taskEarn, friendsCount, theCoinValue } = useContext(CoinContext);

  // Determine status for each condition
  const hasFriends = parseFloat(friendsCount) >= parseFloat(appConfig.friends);
  const hasTaskEarned = parseFloat(taskEarn) >= parseFloat(appConfig.taskEarning);
  const hasCoins = parseFloat(theCoinValue) >= parseFloat(withdrawLimit);
  
  return (
    <CriteriaContainer>
      <Title>💸 Withdraw Criteria 💸</Title>

      {/* User Friends */}
      <CriteriaCard>
        <CriteriaText>
          <Icon><FaUserFriends /></Icon>  <strong> {appConfig.friends}  Friends</strong>
        </CriteriaText>
        <StatusIcon success={hasFriends}>{hasFriends ? '✓' : '✗'}</StatusIcon>
      </CriteriaCard>

      {/* Task Earnings */}
      <CriteriaCard>
        <CriteriaText>
          <Icon><FaTasks /></Icon>    <strong>{formatValue(appConfig.taskEarning)} Task Earnings</strong>
        </CriteriaText>
        <StatusIcon success={hasTaskEarned}>{hasTaskEarned ? '✓' : '✗'}</StatusIcon>
      </CriteriaCard>

      {/* Asbeks Coins */}
      <CriteriaCard>
        <CriteriaText>
          <Icon><FaCoins /></Icon>   <string>{formatValue(withdrawLimit)} Asbeks</string> 
        </CriteriaText>
        <StatusIcon success={hasCoins}>{hasCoins ? '✓' : '✗'}</StatusIcon>
      </CriteriaCard>

      {/* Reward Preview */}
      <RewardMessage>
        {hasFriends && hasTaskEarned && hasCoins ? (
          <span>🎉 You’ve unlocked the withdrawal! 🎉</span>
        ) : (
          <span>Keep going! You’re almost there!</span>
        )}
      </RewardMessage>
      <RewardWithdrawal></RewardWithdrawal>
    </CriteriaContainer>
  );
};

export default WithdrawCriteria;

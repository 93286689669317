import React, { useState } from "react";
import styled from "styled-components";
import EligibilityCheck from "./EligibilityCheck";
// Styled-components for the UI
const CardWrapper = styled.div`
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
  text-align: center;
  font-family: Arial, sans-serif;
`;

const Title = styled.h3`
  font-size: 22px;
  color: #333;
  margin-bottom: 20px;
`;

const Info = styled.p`
  font-size: 16px;
  color: #555;
  margin: 10px 0;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #218838;
  }
`;

const ResultBox = styled.div`
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 10px;
  text-align: center;
`;

const Badge = styled.div`
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Reward = styled.p`
  font-size: 20px;
  color: #007bff;
  font-weight: bold;
  margin-top: 10px;
`;

const InputWrapper = styled.div`
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  font-size: 14px;
  color: #555;
  display: block;
  margin-bottom: 5px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const InfoSection = styled.div`
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
`;

const InfoTitle = styled.h4`
  font-size: 18px;
  color: #333;
  font-weight: 600;
  margin-bottom: 15px;
`;

const InfoText = styled.p`
  font-size: 14px;
  color: #555;
  line-height: 1.6;
`;

const InfoButton = styled(Button)`
  background-color: #007bff;
  margin-top: 10px;
`;

const RewardWithdrawal = () => {
  const [asbeks, setAsbeks] = useState(10000000); // Example user has 10 million asbeks
  const [rank, setRank] = useState(1); // Example user has Rank 1
  const [reward, setReward] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  const handleAsbeksChange = (e) => {
    setAsbeks(parseInt(e.target.value));
  };

  const handleRankChange = (e) => {
    setRank(parseInt(e.target.value));
  };

  const rewardThresholds = {
    diamond: 10000000,
    platinum: 5000000,
    gold: 1000000,
    silver: 500000,
  };

  const rankWithdrawalLimits = {
    diamond: [
      { rank: 1, maxWithdrawal: 10 },
      { rank: 5, maxWithdrawal: 8 },
      { rank: 10, maxWithdrawal: 7 },
      { rank: 20, maxWithdrawal: 6 },
      { rank: 30, maxWithdrawal: 5 },
      { rank: 40, maxWithdrawal: 4 },
      { rank: 50, maxWithdrawal: 3 },
    ],
    platinum: [
      { rank: 1, maxWithdrawal: 6 },
      { rank: 5, maxWithdrawal: 5 },
      { rank: 10, maxWithdrawal: 4 },
      { rank: 20, maxWithdrawal: 3 },
      { rank: 30, maxWithdrawal: 2.5 },
      { rank: 40, maxWithdrawal: 2 },
      { rank: 50, maxWithdrawal: 1 },
    ],
    gold: [
      { rank: 1, maxWithdrawal: 5 },
      { rank: 5, maxWithdrawal: 4 },
      { rank: 10, maxWithdrawal: 3 },
      { rank: 20, maxWithdrawal: 2.5 },
      { rank: 30, maxWithdrawal: 2 },
      { rank: 40, maxWithdrawal: 1.5 },
      { rank: 50, maxWithdrawal: 1 },
    ],
    silver: [
      { rank: 1, maxWithdrawal: 0 },
      { rank: 50, maxWithdrawal: 0 },
    ],
    bronze: [
      { rank: 1, maxWithdrawal: 0 },
      { rank: 50, maxWithdrawal: 0 },
    ],
  };

  // Helper function to get max withdrawal based on rank
  const getMaxWithdrawal = (rank, tier) => {
    const tierLimits = rankWithdrawalLimits[tier] || [];
    for (let limit of tierLimits) {
      if (rank <= limit.rank) {
        return limit.maxWithdrawal;
      }
    }
    return 0;
  };
  
  const calculateReward = () => {
    let maxWithdrawal = 0;
  
    if (asbeks >= rewardThresholds.diamond) {
      maxWithdrawal = getMaxWithdrawal(rank, "diamond");
    } else if (asbeks >= rewardThresholds.platinum) {
      maxWithdrawal = getMaxWithdrawal(rank, "platinum");
    } else if (asbeks >= rewardThresholds.gold) {
      maxWithdrawal = getMaxWithdrawal(rank, "gold");
    }
  
    setReward(maxWithdrawal);
  };
  

  return (
    <div>
      {/* <Title>Reward Withdrawal</Title> */}
      
      {/* Input Section */}
      {/* <InputWrapper>
        <InputLabel>Enter Asbeks</InputLabel>
        <InputField
          type="number"
          value={asbeks}
          onChange={handleAsbeksChange}
        />
        <InputLabel>Enter Rank</InputLabel>
        <InputField
          type="number"
          value={rank}
          onChange={handleRankChange}
        />
      </InputWrapper> */}

      {/* Check Button
      <Button onClick={calculateReward}>Check Maximum Withdrawal</Button> */}

      {/* Result Section */}
      {/* {reward !== null && (
        <ResultBox>
          <Badge>Eligible for Rank {rank}</Badge>
          <Reward>Maximum Withdrawal: ${reward}</Reward>
        </ResultBox>
      )} */}

      {/* Info Button to Toggle Info Section */}
      <InfoButton onClick={() => setShowInfo(!showInfo)}>
        {showInfo ? "Hide Criteria" : "Show More Criterias"}
      </InfoButton>

      {/* Info Section */}
      {showInfo && (
        <InfoSection>
  <InfoTitle>Weekly Withdrawal Criteria:</InfoTitle>

  {Object.entries(rewardThresholds).map(([status, threshold]) => {
    const withdrawalLimits = rankWithdrawalLimits[status];

    return (
      <InfoText key={status}>
        <strong>{status.charAt(0).toUpperCase() + status.slice(1)}</strong> Status ({threshold.toLocaleString()} asbeks or more):
        If you have <strong>{threshold.toLocaleString()}</strong> or more asbeks, your withdrawal amount depends on your rank.
        The maximum withdrawal can go up to <strong>${withdrawalLimits[0].maxWithdrawal}</strong> per week for the top-ranked user.
        As your rank decreases, the withdrawal amount decreases accordingly:
        <ul>
          {withdrawalLimits.map((limit, index) => {
            const startRank = index === 0 ? 1 : withdrawalLimits[index - 1].rank + 1;
            const endRank = limit.rank;
            return (
              <li key={limit.rank}>
                {startRank === endRank
                  ? `Rank ${startRank}`
                  : `Ranks ${startRank} - ${endRank}`}: <strong>${limit.maxWithdrawal}</strong>
              </li>
            );
          })}
        </ul>
      </InfoText>
    );
  })}

  {/* <InfoText>
    <strong>Silver Status (500,000 to 1 million asbeks):</strong> For users with between 500,000 and 1 million asbeks, the maximum withdrawal is <strong>$0.50</strong> per week, regardless of rank.
  </InfoText> */}
  <InfoText>
    <strong>Bronze Status (1 to 500,000 asbeks):</strong> If you have fewer than 500,000 asbeks, you are not eligible for withdrawals.
  </InfoText>
  <InfoText>
    <strong>Beginner Status (No asbeks held):</strong> Users who have no asbeks are also not eligible for withdrawals.
  </InfoText>
  <InfoText>
    This ensures fair and balanced weekly withdrawals based on both your asbek holdings and your rank.
  </InfoText>
  <InfoText>
    <strong>About the Rank System:</strong> Your rank is determined by your activity and performance in the app. Users with higher asbek holdings and more engagement typically rank higher. Your rank determines the amount you can withdraw each week within your status group.
  </InfoText>
  <InfoText>
    <strong>Note:</strong> The higher your asbek holdings and rank, the more you can withdraw each week. The system is designed to reward active users who contribute to the app’s growth and engagement.
  </InfoText>
</InfoSection>




      
      )}
    </div>
  );
};

export default RewardWithdrawal;

import React, { useState } from 'react';
import { FaFacebook, FaTelegramPlane, FaTiktok, FaYoutube, FaTwitter } from 'react-icons/fa';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  padding: 16px;
`;

const IconLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #333;
  font-size: 1.6rem;
  transition: transform 0.2s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    background-color: #4e73df; /* Hover color */
    color: #fff;
    transform: scale(1.2); /* Slight zoom effect */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Subtle shadow */
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(78, 115, 223, 0.3); /* Focus effect */
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  background-color: #f8f8f8;
  color: #333;
  border: 1px solid ${({ isValid }) => (isValid ? '#ddd' : 'red')};
  font-size: 14px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #4e73df;
  }
`;

const SocialMediaLinks = ({ editMode }) => {
  const [links, setLinks] = useState({
    facebook: 'https://facebook.com/fakeprofile',
    telegram: 'https://t.me/fakechannel',
    tiktok: 'https://www.tiktok.com/@fakeprofile',
    youtube: 'https://youtube.com/fakechannel',
    twitter: 'https://twitter.com/fakeprofile',
  });
  const [validUrls, setValidUrls] = useState({
    facebook: true,
    telegram: true,
    tiktok: true,
    youtube: true,
    twitter: true,
  });

  const handleLinkChange = (e) => {
    const { name, value } = e.target;

    // Simple URL validation (could be improved)
    const isValid = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
    setLinks((prevLinks) => ({ ...prevLinks, [name]: value }));
    setValidUrls((prevValidUrls) => ({ ...prevValidUrls, [name]: isValid }));
  };

  return (
    <IconWrapper>
      {editMode ? (
        Object.keys(links).map((platform) => (
          <InputWrapper key={platform}>
            <Input
              type="text"
              name={platform}
              value={links[platform]}
              onChange={handleLinkChange}
              placeholder={`Enter ${platform.charAt(0).toUpperCase() + platform.slice(1)} URL`}
              isValid={validUrls[platform]}
            />
          </InputWrapper>
        ))
      ) : (
        <>
          {links.facebook && (
            <IconLink href={links.facebook} target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </IconLink>
          )}
          {links.telegram && (
            <IconLink href={links.telegram} target="_blank" rel="noopener noreferrer">
              <FaTelegramPlane />
            </IconLink>
          )}
          {links.tiktok && (
            <IconLink href={links.tiktok} target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </IconLink>
          )}
          {links.youtube && (
            <IconLink href={links.youtube} target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </IconLink>
          )}
          {links.twitter && (
            <IconLink href={links.twitter} target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </IconLink>
          )}
        </>
      )}
    </IconWrapper>
  );
};

export default SocialMediaLinks;

import styled, { keyframes } from "styled-components";


// Keyframes for Animations
export const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
`;

export const float = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

export const glow = keyframes`
  0% { box-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700; }
  50% { box-shadow: 0 0 20px #ff0000, 0 0 30px #ff0000, 0 0 40px #ff0000; }
  100% { box-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700; }
`;
export const glowcard = keyframes`
  0% { 
    box-shadow: 0 0 4px #ffd700, 0 0 8px #ffd700, 0 0 12px #ffd700, 0 0 4px #39b54a;
  }
  50% { 
    box-shadow: 0 0 6px #ffd700, 0 0 12px #ffd700, 0 0 18px #ffd700, 0 0 4px #39b54a;
  }
  100% { 
    box-shadow: 0 0 4px #ffd700, 0 0 8px #ffd700, 0 0 12px #ffd700, 0 0 4px #39b54a;
  }
`;

export const glowEffect = styled.div`
  animation: ${glowcard} 6s ease-in-out infinite;
`;


export const Wrapper = styled.div`
  z-index: 2000;
  font-family: "Arial, sans-serif";
  background: linear-gradient(135deg, #1a1a2e, #16213e);
  color: #fff;
  overflow-x: hidden;
  text-align: center;
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
`;

export const Header = styled.header`
  padding: 3rem;
  background: linear-gradient(135deg, #0f3460, #1a1a2e);
  position: relative;
  animation: ${fadeIn} 1s ease-in-out;

  @media (max-width: 768px) {
    padding: 2rem;
  }
    @media (max-width: 400px) {
    padding: 1rem;
  }
`;

export const Heading = styled.h1`
  font-size: 4rem;
  margin: 1rem 0;
  color: #ffd700;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: ${glow} 2s ease-in-out infinite;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const SubHeading = styled.p`
  font-size: 1.5rem;
  color: #ddd;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const CallToAction = styled.a`
  margin-top: 2rem;
  display: inline-block;
  background: #ffd700;
  color: #000;
  padding: 1rem 3rem;
  border-radius: 50px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease;
  box-shadow: 0 0 15px #ffd700;
  &:hover {
    background: #000;
    color: #ffd700;
    transform: scale(1.1);
    box-shadow: 0 0 20px #ff0000, 0 0 30px #ff0000;
  }

  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const Section = styled.section`
  padding: 6rem 2rem;
  background: rgba(0, 0, 0, 0.8);
  animation: ${fadeIn} 1.5s ease-in-out;

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 3rem;
  color: #ffd700;
  margin-bottom: 2rem;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Feature = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 3rem;
  border-radius: 20px;
  margin: 1rem 0;
  display: inline-block;
  width: 80%;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px #ffd700;
  }
&.in-view {
    animation: ${glowcard} 1.5s infinite;
  }
  @media (max-width: 768px) {
    width: 80%;
    padding: 2rem;
  }
`;

export const FeatureTitle = styled.h3`
  font-size: 2rem;
  color: #00d4ff;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const FeatureDescription = styled.p`
  font-size: 1.2rem;
  color: #e0e0e0;
  margin: 1rem 0;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const Video = styled.iframe`
  width: 80%;
  height: 400px;
  border: none;
  margin: 2rem auto;
  display: block;
  border-radius: 20px;

  @media (max-width: 768px) {
    width: 100%;
    height: 250px;
  }
`;

export const Footer = styled.footer`
z-index: 2000;
  padding: 3rem;
  background: #0f3460;
  color: #ddd;
  text-align: center;

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

  export const SocialLinks = styled.div`
    
    a {
      color: #ffd700;
      margin: 0 1rem;
      font-size: 2rem;
      text-decoration: none;
      transition: all 0.3s;
      &:hover {
        color: #00d4ff;
      }

      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
  `;

export const Parallax = styled.div`
  background: url("https://www.example.com/background.jpg") no-repeat center center fixed;
  background-size: cover;
  height: 50vh;
  animation: ${float} 4s ease-in-out infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 3rem;
  padding: 4rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    padding: 2rem;
  }

  @media (max-width: 400px) {
    font-size: 1.5rem;
    padding: 1.5rem;
  }
`;

export const CommunityHighlight = styled.div`
  margin-right: 2rem;
  font-size: 2.5rem;
  color: #ffd700;
  background: linear-gradient(135deg, #ff0000, #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
  animation: ${fadeIn} 1.5s ease-in-out;
  font-weight: bold;
  letter-spacing: 1px;

  padding: 1rem 2rem;
  border-radius: 10px;
  display: inline-block;
  border: 2px solid #ffd700;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.6);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 25px rgba(255, 215, 0, 0.8);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.8rem 1rem;
  }
    @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.8rem 1rem;
  }
`;

export const VideoWrapper = styled.div`
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .react-player {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
// initialStepStyle.js
import styled from 'styled-components';

export const SetupContainer = styled.div`
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const BackButton = styled.button`
    background-color: #ff4c4c;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e03b3b;
    }
`;

export const Title = styled.h2`
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
`;

export const StepContainer = styled.div`
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
`;

export const ActionButton = styled.button`
    background-color: #4caf50;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #45a049;
    }

    &:disabled {
        background-color: #e0e0e0;
        cursor: not-allowed;
    }
`;

export const InputField = styled.input`
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    margin-bottom: 10px;
    transition: border-color 0.3s ease;

    &:focus {
        border-color: #4caf50;
    }
`;

export const StatusMessage = styled.div`
    font-size: 16px;
    margin-top: 20px;
    color: ${props => props.success ? '#4caf50' : '#f44336'};
    font-weight: bold;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
`;

export const RetryButton = styled(ActionButton)`
    background-color: #ff9800;
    
    &:hover {
        background-color: #f57c00;
    }
`;

export const SuccessMessage = styled.div`
    font-size: 20px;
    color: #4caf50;
    font-weight: bold;
    animation: fadeInUp 1s ease-in-out;
    text-align: center;
`;


import React, { useContext,useEffect,useState } from 'react';
import {
  FormContainer,
  Form,
  FormGroup,
  Label,
  Input,
  TextArea,
  SubmitButton,
} from './Step4Style';
import { CoinContext } from '../../contextdtatashare/CoinContext';
import axios from 'axios';

const Setting = ({ selectedChannel, settings, setSettings,handleSave, statusMessage, }) => {
  const {
        setPhoneNumber,
        setEmail,
        setUsersCountry,
        setFullName,
        setProfession,
        setProfilePic,
        email,
        thePhoneNumber,
        theUsername,
        fullName,
        profession,
        UsersCountry,
        verified,
        profilePic,
        quotesfiles,
        setWeeklyClickCount,
        theRank,
        setTheRank,
        friendsCount,
        taskEarn,
        appConfig,
        withdrawDate,
        allowWithdraw,
        withdrawRequestType,
        setWithdrawalRequests,
        setWithdrawRequestType,
        withdrawalRequests,
        withdrawLimit,
        theCoinValue,
        theasbeksPrice,
        decrementCoinValue,
        channels,
        setChannels,posts, setPosts,telegramChatId, setTelegramChatId
      } = useContext(CoinContext);
    useEffect(() => {
        console.log(`isActive state updated to ${settings.isActive}`);
        handleSave();
      }, [settings.isActive]); // Dependency on settings.isActive
      const [loading, setLoading] = useState(false);
      const [sent, setSent] = useState(false);
      const insufficientBalance = !theCoinValue || theCoinValue <= 0;
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSettings((prevSettings) => ({
          ...prevSettings,
          [name]: value,
        }));
      };
      const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        handleSave(); // Call the save handler
      };
      const handleButtonClick = () => {
        setSettings((prevSettings) => ({
          ...prevSettings,
          isActive: !prevSettings.isActive,
        }));
         // Call the save handler after state update
      };
      const handleSendPost = async () => {
        setLoading(true);  // Set loading to true when the request starts
        setSent(false);    // Reset sent status
      
        try {
          const response = await axios.post(`https://67100c95ea75afe346f4.appwrite.global/sendToMyChannel?documentid=${selectedChannel.$id}`);
          console.log('Post sent successfully:', response.data);
          setSent(true);    // Set sent to true when the post is successfully sent
        } catch (error) {
          console.error('Error sending post:', error);
        } finally {
          setLoading(false);  // Set loading to false once the request finishes (either success or failure)
        }
      };
      
      
  return (
    
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <h2>Customize Your Asbeks AI </h2>
          {insufficientBalance && (
  <div
    style={{
      marginTop: '10px',
      textAlign: 'center',
      color: 'red',
      fontWeight: 'bold',
    }}
  >
    Please recharge your Asbeks. Insufficient balance to post.
  </div>
)}
          <FormGroup>
          <button
  type="button"
  onClick={handleSendPost}
  style={{
    padding: '10px 20px',
    backgroundColor: insufficientBalance ? '#ccc' : loading ? '#ccc' : '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: insufficientBalance || loading ? 'not-allowed' : 'pointer',
    fontSize: '16px',
  }}
  disabled={insufficientBalance || loading}  // Disable if balance is insufficient or loading
>
  {insufficientBalance ? 'Insufficient Balance' : loading ? 'Sending...' : sent ? 'Sent' : 'Send Post Now'}
</button>

</FormGroup>
{sent && (
    <div
      style={{
        marginTop: '10px',
        textAlign: 'center',
        color: 'green',
        fontWeight: 'bold',
      }}
    >
      Post Sent Successfully!
    </div>
  )}


          <div style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', padding: '20px', border: '1px solid #d1d1d1', fontFamily: 'Arial, sans-serif', color: '#333', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
  <h3 style={{ color: '#007bff', fontSize: '18px', marginBottom: '10px' }}>Important Setup Instructions:</h3>
  <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
  <li style={{ marginBottom: '8px', fontSize: '14px', lineHeight: '1.5' }}>
      <strong>*. schedule:</strong> Asbeks will post 1 post per day automatically. You can use the button above to post manually
    </li>
    <li style={{ marginBottom: '8px', fontSize: '14px', lineHeight: '1.5' }}>
      <strong>1. Minimum Balance:</strong> Ensure you have at least 2 Ton worth Asbeks in your account for the AI to post to your channel.
    </li>
    <li style={{ marginBottom: '8px', fontSize: '14px', lineHeight: '1.5' }}>
      <strong>2. Daily Posting:</strong> Asbeks AI will post once daily to your channel, using Your Asbeks per post.
    </li>
    <li style={{ marginBottom: '8px', fontSize: '14px', lineHeight: '1.5' }}>
      <strong>3. Correct Setup:</strong> Make sure all details are set correctly, as the AI will use them to generate tailored posts for your channel.
    </li>
    <li style={{ marginBottom: '8px', fontSize: '14px', lineHeight: '1.5' }}>
      <strong>4. Edit Anytime:</strong> You can edit these details later if needed to ensure high-quality content generation.
    </li>
    <li style={{ marginBottom: '8px', fontSize: '14px', lineHeight: '1.5', color: '#e74c3c' }}>
      <strong>5. Admin Removal:</strong> If you remove AsbeksBot as admin from your channel, the AI will stop running.
    </li>
  </ul>
</div>



            {/* Activate Feature */}
        <FormGroup>
          <Label>Activate Feature</Label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button
              type="button"
              onClick={handleButtonClick} // Button click handler
              style={{
                padding: '10px 20px',
                backgroundColor: settings.isActive ? '#ff4d4d' : '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
              }}
            >
              {settings.isActive ? 'Stop Running' : 'Start Running'}
            </button>
          </div>
        </FormGroup>



          {[
            { label: 'Channel Link', name: 'link', placeholder: "https://t.me/yourchannel" , readOnly: true },
            { label: 'Owner', name: 'owner', placeholder: "Owner's Name" , readOnly: true },
            { label: 'Name', name: 'name', placeholder: 'Channel Name' },
            { label: 'Channel Handle', name: 'channelHandle', placeholder: 'Channel Handle', readOnly: true },
            { label: 'Telegram Chat ID', name: 'telegramChatId', placeholder: 'Telegram Chat ID' , readOnly: true},
            { 
              label: 'Goal', 
              name: 'goal', 
              placeholder: 'Channel Goal', 
              defaultValue: 'To educate and engage the audience with high-quality, attention-grabbing facts.' 
            },
            { 
              label: 'Main Topic', 
              name: 'mainTopic', 
              placeholder: 'Main Topic', 
              defaultValue: 'Sharing intriguing, unique facts across various topics.' 
            },
            { 
              label: 'Target Audience', 
              name: 'targetAudience', 
              placeholder: 'Target Audience', 
              defaultValue: 'Knowledge-seeking individuals who enjoy professionally presented facts.' 
            },
            { 
              label: 'Preferred Content Format', 
              name: 'preferredContentFormat', 
              placeholder: 'Preferred Content Format', 
              defaultValue: 'Text-based facts with a professional tone and call-to-action.' 
            },
            { 
                label: 'Description', 
                name: 'description', 
                placeholder: 'Channel Description', 
                defaultValue: 'Enter brief description of your channel' 
              },
          ].map((field, index) => (
            <FormGroup key={index}>
  <Label>
    {field.label}
    {field.readOnly && (
      <span
        style={{
          marginLeft: '8px',
          padding: '2px 6px',
          backgroundColor: '#e0e0e0',
          color: '#555',
          borderRadius: '12px',
          fontSize: '12px',
          fontWeight: 'bold',
        }}
      >
        Uneditable
      </span>
    )}
  </Label>
  <TextArea
    name={field.name}
    value={settings[field.name] || field.defaultValue || ''}
    onChange={handleInputChange}
    placeholder={field.placeholder}
    required
    readOnly={field.readOnly || false}
    style={field.readOnly ? { backgroundColor: '#f9f9f9', cursor: 'not-allowed' } : {}}
  />
            </FormGroup>

          ))}


          <SubmitButton type="submit">Update Channel</SubmitButton>
        </Form>
        {statusMessage && (
          <div
            style={{
            marginTop: '16px',
              marginBottom: '16px',
              padding: '10px 15px',
              backgroundColor: '#f0f8ff',
              color: '#007bff',
              borderRadius: '8px',
              border: '1px solid #007bff',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            {statusMessage}
          </div>
        )}
      </FormContainer>
    
  );
};

export default Setting;

import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  background: linear-gradient(145deg, #ffffff, #f3f4f8);
  border-radius: 20px;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 60px rgba(0, 0, 0, 0.15);
  }
`;

const Title = styled.h2`
  font-size: 2.2rem;
  font-weight: 800;
  color: #2d3748;
  text-align: center;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: 'Poppins', sans-serif;
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const DescriptionText = styled.p`
  font-size: 1.3rem;
  color: #4a5568;
  text-align: center;
  line-height: 1.7;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const Input = styled.textarea`
  width: 100%;
  padding: 18px;
  border-radius: 15px;
  background-color: #f8f9fc;
  color: #333;
  border: 2px solid ${({ isValid }) => (isValid ? '#ccd6f6' : '#ff6f61')};
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  height: 130px;
  resize: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    outline: none;
    border-color: #6c63ff;
    box-shadow: 0 0 15px rgba(108, 99, 255, 0.2);
  }

  &::placeholder {
    color: #b1b8c4;
    font-style: italic;
  }
`;

const SelfDescription = ({ editMode }) => {
  const [bio, setBio] = useState('I am a passionate software engineer, striving to build meaningful and impactful solutions.');
  const [title, setTitle] = useState('About Me');
  const [validDescription, setValidDescription] = useState(true);

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    const isValid = value.trim().length > 0;
    setBio(value);
    setValidDescription(isValid);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  return (
    <Wrapper>
      <Title>{editMode ? 
        <Input 
          value={title} 
          onChange={handleTitleChange} 
          placeholder="Enter title..." 
        /> 
        : title}
      </Title>
      {editMode ? (
        <InputWrapper>
          <Input
            value={bio}
            onChange={handleDescriptionChange}
            placeholder="Write something amazing about yourself..."
            isValid={validDescription}
          />
        </InputWrapper>
      ) : (
        <DescriptionWrapper>
          <DescriptionText>{bio}</DescriptionText>
        </DescriptionWrapper>
      )}
    </Wrapper>
  );
};

export default SelfDescription;

import React, { useContext, useState ,useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Card,
  Title,
  Balance,
  Input,
  Button,
  Message,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  Skeleton,
  TableWrapper,
  CollapsibleContainer,
  DropdownIcon, FilterButton, FilterButtonContainer,FilterContainer
} from "./WithdrawCardStyle";

import { CoinContext } from "../../contextdtatashare/CoinContext";
import AsbekAppwriteService from '../../api/AsbekAppwriteService';
import { TonConnectUIProvider, TonConnectButton, useTonWallet, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { ErrorMessage } from '../TonToAsbeksSwap.styles';
import WithdrawCriteria from './criteria';
import RewardWithdrawal from './reward';
import { Divider } from '@material-ui/core';
import { FaCrown, FaMedal, FaStar, FaShieldAlt, FaGem } from "react-icons/fa";

const formatValue = (value) => {
  if (value >= 1_000_000_000) {
    return `${(value / 1_000_000_000).toFixed(1)}B`;
  }
  if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(1)}M`;
  }
  if (value >= 1_000) {
    return `${(value / 1_000).toFixed(1)}k`;
  }
  return value.toString();
};
const appwriteService = new AsbekAppwriteService(); 

const WithdrawCard = () => {
  
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress(true);
  const tonToAsbeksRate = 10000; // Example: 1 TON = 10,000 Asbeks (Optional if you have fixed rate)
  const [tonConnectUI] = useTonConnectUI();
  const {setWeeklyClickCount,theRank, setTheRank,friendsCount, taskEarn, appConfig, withdrawDate, allowWithdraw, withdrawRequestType, setWithdrawalRequests, setWithdrawRequestType, withdrawalRequests, withdrawLimit, theCoinValue, theasbeksPrice, decrementCoinValue } = useContext(CoinContext);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [currentRequestId, setCurrentRequestId] = useState(null);
  const [currentRequestUserId, setCurrentRequestUserId] = useState(null);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [error, setError] = useState('')
  const [dollarEquivalent, setDollarEquivalent] = useState(0);
  const hasFriends = parseFloat(friendsCount) >= parseFloat(appConfig.friends);
  const hasTaskEarned = parseFloat(taskEarn) >= parseFloat(appConfig.taskEarning);
  const hasCoins = parseFloat(theCoinValue) >= parseFloat(withdrawLimit);
  const [reward, setReward] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusIcon, setStatusIcon] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const tg = window.Telegram.WebApp;
  
  tg.ready();

  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;

  const userId = String(usertg?.id).trim();

  const rewardThresholds = {
    diamond: 10000000,
    platinum: 5000000,
    gold: 1000000,
    silver: 500000,
  };

  const rankWithdrawalLimits = {
    diamond: [
      { rank: 1, maxWithdrawal: 10 },
      { rank: 5, maxWithdrawal: 8 },
      { rank: 10, maxWithdrawal: 7 },
      { rank: 20, maxWithdrawal: 6 },
      { rank: 30, maxWithdrawal: 5 },
      { rank: 40, maxWithdrawal: 4 },
      { rank: 50, maxWithdrawal: 3 },
    ],
    platinum: [
      { rank: 1, maxWithdrawal: 6 },
      { rank: 5, maxWithdrawal: 5 },
      { rank: 10, maxWithdrawal: 4 },
      { rank: 20, maxWithdrawal: 3 },
      { rank: 30, maxWithdrawal: 2.5 },
      { rank: 40, maxWithdrawal: 2 },
      { rank: 50, maxWithdrawal: 1 },
    ],
    gold: [
      { rank: 1, maxWithdrawal: 5 },
      { rank: 5, maxWithdrawal: 4 },
      { rank: 10, maxWithdrawal: 3 },
      { rank: 20, maxWithdrawal: 2.5 },
      { rank: 30, maxWithdrawal: 2 },
      { rank: 40, maxWithdrawal: 1.5 },
      { rank: 50, maxWithdrawal: 1 },
    ],
    silver: [
      { rank: 1, maxWithdrawal: 0 },
      { rank: 50, maxWithdrawal: 0 },
    ],
    bronze: [
      { rank: 1, maxWithdrawal: 0 },
      { rank: 50, maxWithdrawal: 0 },
    ],
  };

  // Helper function to get max withdrawal based on rank
const getMaxWithdrawal = (rank, tier) => {
  const tierLimits = rankWithdrawalLimits[tier] || [];
  for (let limit of tierLimits) {
    if (rank <= limit.rank) {
      return limit.maxWithdrawal;
    }
  }
  return 0;
};

  // const calculateReward = () => {
  //   let maxWithdrawal = 0;
  //   let status = "";
  //   let statusIcon = "";
  //   // For 10 million and above asbeks
  //   if (theCoinValue >= 10000000) {
  //     status = "Diamond";
  //     statusIcon = "fas fa-gem";
  //     if (theRank === 1) maxWithdrawal = 10;
  //     else if (theRank >= 2 && theRank <= 5) maxWithdrawal = 8;
  //     else if (theRank >= 6 && theRank <= 10) maxWithdrawal = 7;
  //     else if (theRank >= 11 && theRank <= 20) maxWithdrawal = 6;
  //     else if (theRank >= 21 && theRank <= 30) maxWithdrawal = 5;
  //     else if (theRank >= 31 && theRank <= 40) maxWithdrawal = 4;
  //     else if (theRank >= 41 && theRank <= 50) maxWithdrawal = 3;
  //   }
  //   // For 5 million to 10 million asbeks
  //   else if (theCoinValue >= 5000000 && theCoinValue < 10000000) {
  //     status = "Platinum";
  //     statusIcon = "fas fa-crown";
  //     if (theRank === 1) maxWithdrawal = 6;
  //     else if (theRank >= 2 && theRank <= 5) maxWithdrawal = 5;
  //     else if (theRank >= 6 && theRank <= 10) maxWithdrawal = 4;
  //     else if (theRank >= 11 && theRank <= 20) maxWithdrawal = 3;
  //     else if (theRank >= 21 && theRank <= 30) maxWithdrawal = 2.5;
  //     else if (theRank >= 31 && theRank <= 40) maxWithdrawal = 2;
  //     else if (theRank >= 41 && theRank <= 50) maxWithdrawal = 1;
  //   }
  //   // For 1 million to 5 million asbeks
  //   else if (theCoinValue >= 1000000 && theCoinValue < 5000000) {
  //     status = "Gold";
  //     statusIcon = "fas fa-star";
  //     if (theRank === 1) maxWithdrawal = 5;
  //     else if (theRank >= 2 && theRank <= 5) maxWithdrawal = 4;
  //     else if (theRank >= 6 && theRank <= 10) maxWithdrawal = 3;
  //     else if (theRank >= 11 && theRank <= 20) maxWithdrawal = 2.5;
  //     else if (theRank >= 21 && theRank <= 30) maxWithdrawal = 2;
  //     else if (theRank >= 31 && theRank <= 40) maxWithdrawal = 1.5;
  //     else if (theRank >= 41 && theRank <= 50) maxWithdrawal = 1;
  //   } else if (theCoinValue >= 500000 && theCoinValue < 1000000) {
  //     status = "Silver"; // 500,000 to 1 million
  //     statusIcon = "fas fa-shield-alt";
  //     maxWithdrawal = 0; // Example withdrawal (can be adjusted as needed)
  // } else if (theCoinValue > 0) {
  //     status = "Bronze"; // 1 to 500,000
  //     statusIcon = "fas fa-medal";
  //     maxWithdrawal = 0; // Example withdrawal (can be adjusted as needed)
  // } else {
  //     status = "Beginner"; // No coins held
  //     statusIcon = "fa-certificate";
  //     maxWithdrawal = 0; // No withdrawal allowed
  // }

  // // Set both reward and status
  // setStatusIcon(statusIcon);
  // setReward(maxWithdrawal);
  // setStatus(status);

  //   setReward(maxWithdrawal);
  // };

  const calculateReward = () => {
    let status = "Beginner";
    let statusIcon = "fa-certificate";
    let maxWithdrawal = 0;
    
    // Check if theRank is null or greater than 50
    if (theRank === null || theRank > 50 || theRank == 0) {
      setMessage("The system is designed to reward active users. Improve your rank to top 50");
      setIsSuccess(false);  // Indicating failure
      setLoading(false);  // End the loading state

        // Set status based on coin value since rank is invalid
        if (theCoinValue >= rewardThresholds.diamond) {
          status = "Diamond";
          statusIcon = "fas fa-gem";
      } else if (theCoinValue >= rewardThresholds.platinum) {
          status = "Platinum";
          statusIcon = "fas fa-crown";
      } else if (theCoinValue >= rewardThresholds.gold) {
          status = "Gold";
          statusIcon = "fas fa-star";
      } else if (theCoinValue >= rewardThresholds.silver) {
          status = "Silver";
          statusIcon = "fas fa-shield-alt";
      } else if (theCoinValue > 0) {
          status = "Bronze";
          statusIcon = "fas fa-medal";
      }

      // Set the status and reward when rank is invalid but coin value is valid
      setStatusIcon(statusIcon);
      setReward(maxWithdrawal);
      setStatus(status);

      return;  // Exit the function early to avoid further processing
  }
    console.log('theRank', theRank)
    if (theCoinValue >= rewardThresholds.diamond) {
      status = "Diamond";
      statusIcon = "fas fa-gem";
      maxWithdrawal = getMaxWithdrawal(theRank, "diamond");
    } else if (theCoinValue >= rewardThresholds.platinum) {
      status = "Platinum";
      statusIcon = "fas fa-crown";
      maxWithdrawal = getMaxWithdrawal(theRank, "platinum");
    } else if (theCoinValue >= rewardThresholds.gold) {
      status = "Gold";
      statusIcon = "fas fa-star";
      maxWithdrawal = getMaxWithdrawal(theRank, "gold");
    } else if (theCoinValue >= rewardThresholds.silver) {
      status = "Silver";
      statusIcon = "fas fa-shield-alt";
      maxWithdrawal = getMaxWithdrawal(theRank, "silver");
    } else if (theCoinValue > 0) {
      status = "Bronze";
      statusIcon = "fas fa-medal";
      maxWithdrawal = getMaxWithdrawal(theRank, "bronze");
    }
  
    // Set both reward and status
    setStatusIcon(statusIcon);
    setReward(maxWithdrawal);
    setStatus(status);
  };

  useEffect(() => {
    calculateReward();
  }, [theRank, theCoinValue]);
  const toggleTable = () => {
    setIsTableOpen((prev) => !prev);
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    setAmount(inputValue);

    // Update dollar equivalent dynamically
    if (inputValue && theasbeksPrice) {
      setDollarEquivalent((inputValue * theasbeksPrice).toFixed(5));
    } else {
      setDollarEquivalent(0);
    }
  };
  const handleWithdrawClick = async () => {
    setLoading(true);
    try {
      if (isNaN(amount) || amount === 0 || amount === null) {
        setMessage("Please enter a valid withdrawal amount!");
        setIsSuccess(false);
        setLoading(false);
        return;
      }
      if (theCoinValue === null) {
        setMessage("Available balance is loading. Please try again later.");
        setIsSuccess(false);
        setLoading(false);
        return;
      }
      //if (dollarEquivalent < withdrawLimit) {}
      if (parseFloat(amount) > parseFloat(theCoinValue)) {
       // setMessage(`Amount exceeds your available balance! you can request to withdraw ${theCoinValue} Asbeks`);
        setMessage(
          <>
            Maximum withdrawal amount is ${(reward / theasbeksPrice).toFixed(2)} Asbeks 
            (equivalent to $${reward.toFixed(2)})! 
            <button 
              style={{
                marginLeft: '10px',
                padding: '5px 10px',
                fontSize: '12px',
                borderRadius: '5px',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => {
                setMaxAmount();
                setMessage('');
              }}
              
            >
              Set Max Amount
            </button>
          </>
        );
        setIsSuccess(false);
        setLoading(false);
        return;
      }
      if (userId == 'undefined') {
        setMessage("we can't recognize you!");
        setIsSuccess(false);
        setLoading(false);
        return;
      }
      if (!theRank || parseFloat(theRank) > 50 || theRank == 0) {
        setMessage("The system is designed to reward active users. Improve your rank to top 50");
        setIsSuccess(false);
        setLoading(false);
        return;
      }
      if (amount > reward/theasbeksPrice) {
        setMessage(
          <>
            Maximum withdrawal amount is ${(reward / theasbeksPrice).toFixed(2)} Asbeks 
            (equivalent to $${reward.toFixed(2)})! 
            <button 
              style={{
                marginLeft: '10px',
                padding: '5px 10px',
                fontSize: '12px',
                borderRadius: '5px',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => {
                setMaxAmount();
                setMessage('');
              }}
              
            >
              Set Max Amount
            </button>
          </>
        );
                setIsSuccess(false);
      } else {
        await appwriteService.createWithdrawalRequest(
          {
            userId: userId,
            asbeks:  parseFloat(amount),
            dollar: parseFloat(dollarEquivalent),
            ton: 0,
            wallet: String(userFriendlyAddress)
          }
        )
        setMessage("Withdrawal requested successfully! Expect to receive your payout within 48 hours");
        setIsSuccess(true);
        decrementCoinValue(parseFloat(amount))
        // setTheRank(0)
        // setWeeklyClickCount(0)
        
      }
      setLoading(false);
      setAmount("");
    } catch (error) {
      console.log('jkhgfgh', error)
      const errorMessage = error?.message || "Something went wrong. Please try again!";
      setMessage(errorMessage);
      setIsSuccess(false);
      setLoading(false);
      setAmount("");
    }
      
    
  };

  // const isValidAmount = amount >= withdrawLimit;

  const openModal = (action, requestId, requestUserId) => {
    setCurrentAction(action);
    setCurrentRequestId(requestId);
    setCurrentRequestUserId(requestUserId);
    setModalOpen(true);
  };

  const confirmAction = () => {
    if(userId == '445929610'){
      if (currentAction === "Approve") {
        //console.log(`Approved request with ID: ${currentRequestId}`);
        updateRequestStatus(currentRequestId, "approved");
        const apiUrl = `https://67100c95ea75afe346f4.appwrite.global/sendWithdrawalMessage?userId=${currentRequestUserId}`;
        axios.get(apiUrl)
        .then(response => {
            console.log(`Message sent successfully for userId: ${userId}`, response.data);
            setSuccessMessage(true);
            setTimeout(() => {
              setSuccessMessage(false);
          }, 3000);
        })
        .catch(error => {
            console.error(`Failed to send message for userId: ${userId}`, error);
        });
      } else if (currentAction === "Decline") {
        //console.log(`Declined request with ID: ${currentRequestId}`);
        updateRequestStatus(currentRequestId, "declined");
      }
      setModalOpen(false);
    }
    
  };

  const updateRequestStatus = async (id, newStatus) => {
    // Example: Update request status locally or via API
    await appwriteService.updateWithdrawalRequestStatus(
      {
        requestId: id,
        status: newStatus,
        userIdCheck: userId
      }
    )
    
    // Update state if necessary
  };
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false; // Set to false after the first render
      return; // Skip the effect logic on the first render
    }

    setWithdrawalRequests([]); // Run this only when withdrawRequestType changes after the initial render
  }, [withdrawRequestType]);


  const handleStatusChange = (newStatus) => {
    toggleTable()
    //setWithdrawalRequests([]);
    //console.log('Status changed:', newStatus);
    setWithdrawRequestType(newStatus); // Update the status based on the button clicked
  };
  const setMaxAmount = () => {
    
    if (reward && theasbeksPrice) {
      setAmount(reward / theasbeksPrice);
      setDollarEquivalent(reward);
    } 
  };
  return (  
    <TonConnectUIProvider>
      
      
    <Card>
      <Title>Withdraw Funds</Title>
      {!wallet && (
          <ErrorMessage>
            <span>No wallet connected. Please connect your wallet to continue.</span>
            
          </ErrorMessage>
        )}
        
        
      {theCoinValue !== null ? (
        <Balance>{formatValue(theCoinValue)} Asbeks</Balance>
      ) : (
        <Skeleton />
      )}

      <center>
        {theasbeksPrice !== null ? (
          <p style={{ color: "gray", fontSize: "0.9em" }}>
            
            ≈ ${parseFloat(formatValue(theCoinValue * theasbeksPrice)).toFixed(5)}
          </p>
        ) : (
          <Skeleton />
        )}
        <p style={{ color: "gray", fontSize: "0.9em" }}>
        {withdrawDate}
        </p>
      </center>
        
      {/* <p style={{ color: "#616161", fontSize: "0.9em", fontFamily: "Roboto", fontWeight: "300" }}>
    Minimum withdrawal: ${parseFloat(formatValue(withdrawLimit * theasbeksPrice)).toFixed(1)}
  </p> */}

  {reward !== null && (
        <div style={{ color: "#616161", fontSize: "0.9em", fontFamily: "Roboto", fontWeight: "300" }}>
          <div className="status-card">
                <i className={`fa ${statusIcon} status-icon`} style={{ fontSize: '50px', color: '#FFD700' }}></i>
                <h2 className="status-name">{status}</h2>
                <p className="status-reward">Max Weekly Withdrawal: ${reward} </p>
                <p className="status-reward">Your Rank: {theRank}</p>
            </div>
          {theasbeksPrice !== null ? (
          <p style={{ color: "gray", fontSize: "0.9em" }}>            
            ≈ {parseFloat(reward / theasbeksPrice).toFixed(2)} Asbeks
          </p>
        ) : (
          <Skeleton />
        )}
        </div>
      )}
            
      
      {message && <Message success={isSuccess}>{message}</Message>}
      <div style={{ position: "relative", width: "100%" }}>
      <Input
        type="number"
        value={amount}
        onChange={handleAmountChange}
        placeholder="Enter Asbeks"
      />
      <button
        onClick={setMaxAmount}
        style={{
          position: "absolute",
          right: "14px",
          top: "50%",
          transform: "translateY(-50%)",
          background: "none",
          border: "none",
          color: "blue",
          cursor: "pointer",
          fontSize: "12px",
          fontWeight: "bold",
          padding: "0",
        }}
      >
        Max
      </button>
    </div>
      { dollarEquivalent !== 0 && (
        <p style={{ color: "#616161", fontSize: "0.9em" }}>
          ≈ ${dollarEquivalent || 0} (USD)
        </p>
      )}
      
      <Button onClick={handleWithdrawClick} disabled={ !allowWithdraw || !theRank ||theRank == 0|| !hasFriends || !hasTaskEarned || !hasCoins || !wallet|| loading  || theCoinValue === null || !withdrawLimit }>
        {loading ? "Processing..." : "Request Withdraw"}
      </Button>

      <center>
        <Title>Your Requests</Title>
      </center>
      <DropdownIcon open={isTableOpen} onClick={toggleTable}>
          ▼
        </DropdownIcon>
       
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
      
            <FilterButton onClick={() => handleStatusChange('pending')}>Pending</FilterButton>
            <FilterButton onClick={() => handleStatusChange('declined')}>Declined</FilterButton>
            <FilterButton onClick={() => handleStatusChange('approved')}>Approved</FilterButton>
       
          </div>
      
        <CollapsibleContainer open={isTableOpen}>
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>ID</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Amount (Asbeks)</TableHeader>
              <TableHeader>Amount (USD)</TableHeader>
              <TableHeader>Created At</TableHeader>
              <TableHeader>Wallet</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {withdrawalRequests.map((request) => (
              <TableRow key={request.$id}>
                <TableCell>
                  {request.$id ? (
                    <span
                      onClick={() => navigator.clipboard.writeText(request.$id)}
                      style={{ cursor: "pointer", color: "grey" }}
                      title="Click to copy full wallet address"
                    >
                      {`${request.$id.slice(0, 3)}...${request.$id.slice(-3)}`}
                    </span>
                  ) : (
                    <span style={{ color: "gray" }}>N/A</span>
                  )}
                </TableCell>

                <TableCell>
                  {request.status === "pending" && userId == '445929610' ? (
                    <>
                    <span 
                    
                    style={{
                      color: request.status === 'approved' ? 'green' :
                            request.status === 'pending' ? 'gray' : 
                            request.status === 'declined' ? 'red' : 'black'
                    }}
                  >
                    {request.status}
                      </span>

                      <button
                        onClick={() => openModal("Approve", request.$id, request.userId)}
                        style={{
                          background: "transparent",
                          color: "green",
                          border: "none",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                      >
                        Approve
                      </button>
                      <button
                        onClick={() => openModal("Decline", request.$id)}
                        style={{
                          background: "transparent",
                          color: "red",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        Decline
                      </button>
                    </>
                  ) : (
                    <span 
                    style={{
                      color: request.status === 'approved' ? 'green' :
                            request.status === 'pending' ? 'gray' : 
                            request.status === 'declined' ? 'red' : 'black'
                    }}
              >
                {request.status}
              </span>

                  )}
                </TableCell>
                <TableCell>{request.asbeks}</TableCell>
                <TableCell>${request.dollar}</TableCell>
                <TableCell>{new Date(request.$createdAt).toLocaleString()}</TableCell>
                <TableCell>
                  {request.wallet ? (
                    <span
                      onClick={() => navigator.clipboard.writeText(request.wallet)}
                      style={{ cursor: "pointer", color: "grey" }}
                      title="Click to copy full wallet address"
                    >
                      {`${request.wallet.slice(0, 3)}...${request.wallet.slice(-3)}`}
                    </span>
                  ) : (
                    <span style={{ color: "gray" }}>N/A</span>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
      </CollapsibleContainer>
      {/* Modal for Confirmation */}
      {successMessage && (
                <div style={{
                    background: "linear-gradient(135deg, #ff7e5f, #feb47b)",  // Gradient with solid colors
                    padding: "20px",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                    borderRadius: "10px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
                    color: "#fff",  // White text for contrast
                    fontSize: "16px", // Set the font size
                    fontWeight: "bold" // Bold text for emphasis
                }}>
                    ✅ Withdrawal Successfully Approved!
                </div>
            )}
      {modalOpen && (
        <div style={{
          background: "linear-gradient(135deg, #ff7e5f, #feb47b)",  // Gradient with solid colors
          padding: "20px",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1000,
          borderRadius: "10px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)"
        }}
          >
          <p>Are you sure you want to {currentAction.toLowerCase()} this request?</p>
          <button onClick={confirmAction} style={{ marginRight: "10px" }}>
            Yes
          </button>
          <button onClick={() => setModalOpen(false)}>No</button>
        </div>
      )}
    </Card>
    <WithdrawCriteria></WithdrawCriteria>
    </TonConnectUIProvider>
 

);
  

};

export default WithdrawCard;

import React, { useState } from 'react';
import styled from 'styled-components';

// Styled Components
const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: ${(props) => `url(${props.backgroundImage || "https://via.placeholder.com/1200x800"})`};
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  transition: background 0.3s ease-in-out;

  /* Gradient Overlay */
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3); /* Dark overlay */
    z-index: 1;
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
  
  &:hover {
    transform: scale(1.02); /* Slight scale effect on hover */
  }

  @media (max-width: 768px) {
    width: 95%;
    padding: 30px;
    text-align: left; /* Better alignment on mobile */
  }
`;

const Title = styled.h1`
  font-size: 4rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 20px;
  letter-spacing: 1.5px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);

  @media (max-width: 768px) {
    font-size: 3rem;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.6rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 30px;
  letter-spacing: 1px;

  @media (max-width: 768px) {
    font-size: 1.4rem;
    text-align: center; /* Center align for smaller devices */
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const Button = styled.button`
  background-color: #ff5733;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
  
  &:hover {
    background-color: #e94e21;
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 12px 24px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 10px 20px;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const OverlayButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #ff5733;
  color: white;
  padding: 15px;
  font-size: 1.8rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #e94e21;
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    top: 15px;
    right: 15px;
    font-size: 1.6rem;
  }

  @media (max-width: 480px) {
    top: 10px;
    right: 10px;
    font-size: 1.4rem;
  }
`;

const Hero = ({ editMode }) => {
  const [title, setTitle] = useState("Discover Your Potential");
  const [description, setDescription] = useState("Join the future. Explore the infinite possibilities.");
  const [backgroundImage, setBackgroundImage] = useState("https://via.placeholder.com/1200x800");
  const [isEditing, setIsEditing] = useState(false);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleEdit = () => setIsEditing(!isEditing);

  return (
    <HeroContainer backgroundImage={backgroundImage}>
      <HeroContent>
        {/* Editable Title */}
        {editMode ? (
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={() => setIsEditing(false)}
            autoFocus
            style={{
              width: '100%',
              padding: '15px',
              fontSize: '2.5rem',
              fontFamily: 'Poppins, sans-serif',
              borderRadius: '10px',
              border: 'none',
              boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
              outline: 'none',
            }}
          />
        ) : (
          <Title onClick={toggleEdit}>{title}</Title>
        )}

        {/* Editable Description */}
        {editMode ? (
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            onBlur={() => setIsEditing(false)}
            rows="4"
            style={{
              width: '100%',
              padding: '12px',
              fontSize: '1.4rem',
              fontFamily: 'Roboto, sans-serif',
              borderRadius: '10px',
              border: 'none',
              boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
              resize: 'none',
              marginTop: '10px',
              outline: 'none',
            }}
          />
        ) : (
          <Description>{description}</Description>
        )}

        {/* Call to Action Button */}
        {!editMode && <Button>Get Started</Button>}
      </HeroContent>

      {/* Overlay Buttons for Edit Mode */}
      {editMode && (
        <>
          <OverlayButton onClick={toggleEdit}>✏️</OverlayButton>
          <OverlayButton top onClick={() => document.getElementById("fileInput").click()}>
            📷
            <FileInput
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </OverlayButton>
        </>
      )}
    </HeroContainer>
  );
};

export default Hero;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { CoinContext } from "../contextdtatashare/CoinContext"; // Adjust the path as necessary
import { TonConnectUIProvider, TonConnectButton, useTonWallet, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import Confetti from 'react-confetti';
import { 
  Card, Title, CurrencySelector, CurrencyImage, Arrow, 
  InputContainer, InputField, OutputContainer, AsbeksOutput, 
  ErrorMessage, Button ,CloseButton,SuccessBanner,SuccessMessage
} from './TonToAsbeksSwap.styles'; // Import the styled components
import AsbekAppwriteService from "../api/AsbekAppwriteService";
import { Star, StarsWrapper } from "../asbek/top_style";
const appwriteService = new AsbekAppwriteService();
const TonToAsbeksSwap = () => {
  const [theasbeksPrice, setAsbeksPrice] = useState(null);
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress(true);
  const tonToAsbeksRate = 10000; // Example: 1 TON = 10,000 Asbeks (Optional if you have fixed rate)
  const [tonConnectUI] = useTonConnectUI();
  const {appConfig, incrementCoinValue } = useContext(CoinContext); // Accessing the Asbeks price from context
  const [tonAmount, setTonAmount] = useState(""); // Initialize as an empty string to handle dynamic inputs
  const [asbeksAmount, setAsbeksAmount] = useState(0);
  const [usdValue, setUsdValue] = useState(0); // For USD value
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [confettiVisible, setConfettiVisible] = useState(false);
  const [starsVisible, setStarsVisible] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
 
  // Confetti and Stars Logic
  const showEffects = () => {
    setConfettiVisible(true);
    setStarsVisible(true);
    setShowSuccess(true);
    // Hide effects after 5 seconds
    setTimeout(() => {
      setConfettiVisible(false);
      setStarsVisible(false);
      setShowSuccess(false);
    }, 9000);
  };
  const tg = window.Telegram.WebApp;
  tg.ready();

  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;


  const userId = String(usertg?.id).trim();

  // Function to fetch the Asbeks price if it's not available in context
  const fetchAsbeksPrice = async () => {
    try {
      const url = `https://67078ccf4eb31299c3df.appwrite.global/asbeksPrice`; // URL to fetch Asbeks price
      const response = await axios.get(url);
      
        setAsbeksPrice(response.data.asbeksPrice); // Update context with fetched price
      
    } catch (err) {
      console.error('Error fetching ASBeks price:', err);
      setError('Failed to fetch ASBeks price.');
    }
  };

  // Function to get TON price in USD
  const getTonPriceInUsd = async () => {
    const price_url = `https://jetton-index.tonscan.org/market_data/chart?days=1&interval=daily&vs_currency=USD&precision=4`;
    try {
      const price_response = await axios.get(price_url);
      if (price_response.status === 200) {
        const latestPriceData = price_response.data.prices;
        return latestPriceData[latestPriceData.length - 1][1]; // The price of 1 TON in USD
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching TON price in USD:', error);
      return null;
    }
  };

  // Fetch the Asbeks price if it's not already set in context
  useEffect(() => {
    if (appConfig) {
      const totalSupply = appConfig.totalSupply;
      const totalBalance = appConfig.balance;
      const tonPriceInUsd = appConfig.tonPriceInUsd;
  
      const balanceInUsd = totalBalance * tonPriceInUsd;
      const asbeksPrice = balanceInUsd / totalSupply;
     

      setAsbeksPrice(asbeksPrice);
    }
  }, [theasbeksPrice, appConfig]);
  

  // Function to calculate Asbeks and USD value from TON
  const calculateValues = async (event) => {
    const value = event.target.value;
    setTonAmount(value); // Update state with the input value

    if (value === "") {
      setAsbeksAmount(0);
      setUsdValue(0);
      setIsButtonDisabled(true);
      return;
    }

    const numericValue = parseFloat(value);
    if (isNaN(numericValue) || numericValue <= 0) {
      setAsbeksAmount(0);
      setUsdValue(0);
      setIsButtonDisabled(true);
      return;
    }

    // Calculate the USD value of TON
    const tonPriceInUsd = parseFloat(appConfig.tonPriceInUsd) || 5;

    if (tonPriceInUsd) {
      setUsdValue((numericValue * tonPriceInUsd).toFixed(2)); // Set USD value for TON
    }

    // Calculate Asbeks based on the price of 1 TON in USD and the Asbeks price in USD
    if (theasbeksPrice) {
      const asbeksAmount = (numericValue * tonPriceInUsd / theasbeksPrice).toFixed(2);
      setAsbeksAmount(asbeksAmount  * appConfig.fee);
    }

    setIsButtonDisabled(false);
  };

  // Function to swap the currencies
  const swapCurrencies = async () => {
    if (tonAmount > 0) {
      const data = window.Telegram.WebApp.initData;

      const validUntil = Math.floor(Date.now() / 1000) + 60;
      const securingUrl = `https://67078ccf4eb31299c3df.appwrite.global/generate-payment-token`;
      const response = await axios.get(securingUrl, {
        timeout: 90000, // Timeout set to 10 seconds (you can adjust as needed)
      });
      
      if (response.data.status === 'true') {
        const token = response.data.token
        const transaction = {
          validUntil: validUntil, // 60 seconds validity
          messages: [
            {
              address: 'UQC6cS3uwguDjyOl9SrP9gty2BBynjZ70M7dHPtr5N2gA919', // recipient address
              amount: (parseFloat(tonAmount).toFixed(9) * 1e9).toString(), // convert amount to nanotons
            },
          ],
        };

        const options = {
          modals: ['before', 'success', 'error'],
          notifications: ['before', 'success', 'error'],
          skipRedirectToWallet: 'never',  // Option for iOS redirect behavior
          returnStrategy: 'none',  // No redirection after transaction
      };
      
      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        const buyAsbeks = await appwriteService.buyAsbeks({
          docId: userId,
          asbeksAmount: asbeksAmount,
          tonAmount: tonAmount,
          boc: result.boc,
          thewallet: userFriendlyAddress,
        })
        incrementCoinValue(parseFloat(asbeksAmount))
        showEffects();
      } catch (error) {
        console.log(error)
      }
      }
    }
  };
  const generateStars = (numStars) => {
    let stars = [];
    for (let i = 0; i < numStars; i++) {
      stars.push(<Star key={i}>💸</Star>);
    }
    return stars;
  };

  return (
    <TonConnectUIProvider manifestUrl="https://asbeks.github.io/tonconnect-manifest.json">
      <Card>
      {confettiVisible && <Confetti numberOfPieces={500} />}
        {starsVisible && <StarsWrapper>{generateStars(100)}</StarsWrapper>}
        {showSuccess && (
        <SuccessBanner>
          <SuccessMessage>🎉 Transaction Successful! 🎉</SuccessMessage>
          <CloseButton onClick={() => setShowSuccess(false)}>Thank You</CloseButton>
        </SuccessBanner>
      )}
      <Title>BUY ASBEKS</Title>
      
      {/* Currency Selector */}
      <CurrencySelector>
        <CurrencyImage
          src="https://cryptologos.cc/logos/toncoin-ton-logo.png?v=035"
          alt="TON"
        />
        <Arrow>&#8594;</Arrow>
        <CurrencyImage
          src="\asbek.jpg"
          alt="Asbeks"
        />
      </CurrencySelector>
      {!wallet && (
          <ErrorMessage>
            <span>No wallet connected. Please connect your wallet to continue.</span>
            
          </ErrorMessage>
        )}
      {/* TON Input Field */}
      <InputContainer>
        <InputField
          type="number"
          value={tonAmount}
          onChange={calculateValues}
          placeholder="Amount in TON"
        />
      </InputContainer>

      {/* Output */}
      <OutputContainer>
        <span>Amount to Receive:</span>
        <AsbeksOutput>{asbeksAmount}</AsbeksOutput> Asbeks
      </OutputContainer>

      {/* USD Value */}
      {/* {usdValue > 0 && (
        <OutputContainer>
          <span>USD Value:</span>
          <AsbeksOutput>${usdValue}</AsbeksOutput>
        </OutputContainer>
      )} */}

      {/* Asbeks Price */}
      {/* {theasbeksPrice && (
        <OutputContainer>
          <span>Current Asbeks Price:</span>
          <AsbeksOutput>{theasbeksPrice}</AsbeksOutput> USD
        </OutputContainer>
      )} */}

      {/* Error Message */}
      {error && (
        <ErrorMessage>
          <span>{error}</span>
        </ErrorMessage>
      )}

      {/* Swap Button */}
      <Button onClick={swapCurrencies} disabled={isButtonDisabled || !wallet || !theasbeksPrice}>
            BUY
      </Button>
    </Card>
    </TonConnectUIProvider>
    
  );
};

export default TonToAsbeksSwap;

  import React, {useContext, useState, useEffect } from 'react';
  import { CoinContext } from '../contextdtatashare/CoinContext';
  import styled from 'styled-components';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import {faShoppingCart , faPaperPlane, faArrowDown, faSyncAlt, faQrcode, faCopy } from '@fortawesome/free-solid-svg-icons';
  import Modal from 'react-modal'; // For the bottom sheet effect
  import { QRCodeCanvas } from 'qrcode.react';
  import Skeleton from '@mui/material/Skeleton';

  import CryptoList from "./CryptoList";
  import SendCrypto from './sendTon.js';
  import axios from 'axios';
  import BuyAsbeks from './buyasbeks';
  
  // Function to format the wallet address
  const getTonPriceInUsd = async () => {
    const price_url = `https://jetton-index.tonscan.org/market_data/chart?days=1&interval=daily&vs_currency=USD&precision=4`;
    try {
      const price_response = await axios.get(price_url);
      if (price_response.status === 200) {
        const latestPriceData = price_response.data.prices;
        // Assuming the latest price is the last entry in the prices array
        return latestPriceData[latestPriceData.length - 1][1]; // The price of 1 TON in USD
      } else {
        console.error('Error fetching price data:', price_response.statusText);
        return null; // Or handle the error appropriately
      }
    } catch (error) {
      console.error('Error fetching price data:', error);
      return null;
    }
  };

  const formatAddress = (address) => {
    if (address.length <= 15) return address; // Return the address if it's short enough
    const start = address.slice(0, 6); // First 6 characters
    const end = address.slice(-4); // Last 4 characters
    return `${start}...${end}`; // Formatted address
  };
  const WalletContainer = styled.div`
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #f7f7f7;
    border-radius: 15px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  `;

  const Header = styled.div`
    text-align: center;
    margin-bottom: 30px;
  `;

  const Title = styled.h2`
    font-size: 30px;
    color: #333;
    margin-bottom: 5px;
  `;

  const Subtitle = styled.p`
    font-size: 16px;
    color: #666;
  `;

  const WalletCard = styled.div`
    background: linear-gradient(120deg, #4f94d4, #81d4fa);
    padding: 30px;
    border-radius: 15px;
    color: #fff;
    margin-bottom: 40px;
    text-align: center;
    position: relative;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  `;

  const Balance = styled.h1`
    font-size: 52px;
    margin: 10px 0;
  `;

  const BalanceUSD = styled.p`
    font-size: 18px;
    color: #f1f1f1;
  `;

  const ActionButtons = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  `;

  const Button = styled.button`
    background: linear-gradient(120deg, #4f94d4, #81d4fa);
    color: #ffffff;
    font-size: 14px;
    padding: 12px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      background: #3f51b5;
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(1px);
    }

    // Style for disabled state
    &:disabled {
      background: #cfd8dc; // Light gray background for disabled state
      color: #b0bec5; // Light gray text for disabled state
      cursor: not-allowed; // Change cursor to not-allowed
      pointer-events: none; // Prevent any interaction
    }
  `;


  const Icon = styled(FontAwesomeIcon)`
    font-size: 20px;
  `;

  const HistorySection = styled.div`
    background-color: #ffffff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  `;

  const HistoryTitle = styled.h3`
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
  `;

  const TransactionItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
      border-bottom: none;
    }
  `;

  const TransactionText = styled.p`
    font-size: 16px;
    color: #555;
  `;

  const TransactionAmount = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => (props.type === 'credit' ? '#28a745' : '#dc3545')};
  `;


  // QR Code section
  const QRSection = styled.div`
    text-align: center;
    margin: 20px 0;
  `;

  const QRLabel = styled.h3`
    font-size: 22px;
    color: #333;
    margin-bottom: 10px;
  `;

  const QRCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  `;

  // Bottom Sheet (Modal)
  const BottomSheet = styled(Modal)`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 20px;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.1);
  `;

  // Sheet Content
  const SheetContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%; // Fill full height
  `;

  // Wallet Address
  const WalletAddress = styled.p`
    font-size: 16px;
    color: #555;
    word-wrap: break-word;
    margin: 20px 0; // Adjust margin for better spacing
    text-align: center; // Center align for better aesthetics
  `;

  // Copy Button
  const CopyButton = styled.button`
    background-color: #4f94d4;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #3f51b5;
    }
  `;

  // Transaction Modal
  const TransactionModal = styled(Modal)`
    position: fixed;
    top: 0; // Full height
    left: 0;
    right: 0;
    bottom: 0; // Full height
    background: rgba(255, 255, 255, 0.95);
    padding: 30px; // Increase padding for comfort
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    overflow-y: auto; // Enable vertical scrolling
    z-index: 1000; // Ensure it appears above other elements
    transition: all 0.3s ease-in-out; // Add a transition effect
  `;
  // Input field styles
  const InputField = styled.input`
    width: 100%;
    padding: 12px; // Slightly larger padding
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  `;

  // Development message
  const DevMessage = styled.p`
    color: #f44336; // Red color for warning
    font-weight: bold;
    text-align: center;
  `;

  // Close Button in Modal Header
  const CloseButton = styled.button`
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    right: 30px; // Position it to the right
    top: 20px; // Position it at the top
    
    &:hover {
      color: #333; // Change color on hover
    }
  `;

  const WalletPage = () => {
    const {
      createWallet, fetchWalletData, cryptoToUsd, estimatedUSD
    } = useContext(CoinContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [transactionModalOpen, setTransactionModalOpen] = useState(false);
    const [amount, setAmount] = useState('');
    const [recipientAddress, setRecipientAddress] = useState('');
    const [refreshing, setRefreshing] = useState(false);
    // const [cryptoToUsd, setCryptoToUsd] = useState(0); // Store the current price of TON

    // useEffect(() => {
    //   const fetchTonPrice = async () => {
    //     const price = await getTonPriceInUsd();
    //     if (price) {
    //       setCryptoToUsd(createWallet.balance);
    //     }
    //   };
  
    //   fetchTonPrice();
    // }, []);

    const transactions = [
      { text: "Received TON", amount: "+300 TON", type: "credit" },
      { text: "Sent TON", amount: "-150 TON", type: "debit" },
      { text: "Received TON", amount: "+450 TON", type: "credit" },
    ];

    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);

    // Function to copy the wallet address to clipboard
    const copyToClipboard = () => {
      navigator.clipboard.writeText(createWallet.address);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    };
    const openModal = () => {
      setModalIsOpen(true);
    };

    const closeModal = () => {
      setModalIsOpen(false);
    };

    const openTransactionModal = () => {
      setTransactionModalOpen(true);
    };

    const closeTransactionModal = () => {
      setTransactionModalOpen(false);
      setAmount('');
      setRecipientAddress('');
    };

    

    const handleSendTransaction = () => {
      // Add logic to send transaction
      alert(`Sending ${amount} TON to ${recipientAddress}`);
      closeTransactionModal();
    };


    const handleRefresh = () => {
    setRefreshing(true); // Show notification
    fetchWalletData()
    // Simulate data fetching or refreshing
    setTimeout(() => {
      // Replace this with your actual data fetching logic
      setRefreshing(false); // Hide notification after data is refreshed
    }, 2000); // Simulate a 2-second delay for refreshing data
  };
    return (
      <WalletContainer>
        <Header>
          <Title>Asbeks Wallet</Title>
          <Subtitle>Your secure cryptocurrency wallet</Subtitle>
        </Header>

        <WalletCard>
        <Balance>
            {createWallet ? (
              isNaN(Number(createWallet.balance)) ? 'N/A' : Number(createWallet.balance).toFixed(6) + ' TON'
            ) : (
              <Skeleton variant="text" width={100} />
            )}
          </Balance>
          <BalanceUSD>
            {estimatedUSD ? (
            '≈ $' + isNaN(Number(estimatedUSD)) ? ('≈ $' + Number(estimatedUSD).toFixed(2) + ' USD') : (
                '≈ $' + Number(estimatedUSD).toFixed(2) + ' USD'
              )
            ) : (
              <Skeleton variant="text" width={100} />
            )}
          </BalanceUSD>
          </WalletCard>

            <center>

            {refreshing && (
          
          <p>Refreshing...</p> 
         
       )}
            </center>
        <ActionButtons>
          <SendCrypto/>
        {/* <Button disabled={true}>
            <Icon icon={faPaperPlane} /> Send
          </Button> */}
          <Button disabled={!createWallet} onClick={openModal}>
            <Icon icon={faArrowDown} /> Deposit
          </Button>
          <Button onClick={handleRefresh}>
            <Icon icon={faSyncAlt} /> Refresh
          </Button>
        </ActionButtons>
        <center>
        
        <BuyAsbeks/>
          </center>
        
        {/* <CryptoList></CryptoList> */}
      {/* QR Modal */}
      <BottomSheet
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Deposit TON"
          style={{ overlay: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } }}
        >
          <SheetContent>
            <CloseButton onClick={closeModal}>✖</CloseButton>
            <QRSection>
              <QRLabel>Scan to Deposit</QRLabel>
              <QRCodeContainer>
                <QRCodeCanvas value=
                 {createWallet ? (
                  createWallet.address
                ) : (
                  <Skeleton variant="text" width={100} />
                )}
                
                size={150} />
                {copySuccess && <p style={{ color: '#81d4fa' }}>Copied to clipboard!</p>} {/* Feedback message */}
                {createWallet ? (
              <WalletAddress>{formatAddress(createWallet.address)}</WalletAddress>
            ) : (
              <Skeleton variant="text" width={100} />
            )}
                            </QRCodeContainer>
            </QRSection>
            <CopyButton onClick={copyToClipboard}>Copy Address</CopyButton>
          </SheetContent>
        </BottomSheet>

        {/* Transaction Modal */}
        <TransactionModal
          isOpen={transactionModalOpen}
          onRequestClose={closeTransactionModal}
          contentLabel="Send Transaction"
        >
          <CloseButton onClick={closeTransactionModal}>✖</CloseButton>
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Send TON</h2>
          <InputField
            type="text"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <InputField
            type="text"
            placeholder="Recipient Address"
            value={recipientAddress}
            onChange={(e) => setRecipientAddress(e.target.value)}
          />
          <Button onClick={handleSendTransaction}>Send</Button>
          <DevMessage>Development in progress, functionality may be limited!</DevMessage>
        </TransactionModal>

        {/* <HistorySection>
          <HistoryTitle>Transaction History</HistoryTitle>
          {transactions.map((transaction, index) => (
            <TransactionItem key={index}>
              <TransactionText>{transaction.text}</TransactionText>
              <TransactionAmount type={transaction.type}>{transaction.amount}</TransactionAmount>
            </TransactionItem>
          ))}
        </HistorySection> */}

        
      </WalletContainer>
    );
  };

  export default WalletPage;

import React, { useState, useEffect } from 'react';
import Barcode from 'react-barcode'; 
import QRCode from 'react-qr-code';
import {
  CardContainer,
  IdCard,
  IdCardSide,
  ProfileSection,
  ProfilePicWrapper,
  ProfileDetails,
  VerificationBadge,
  InfoField,
  FlipButton,
  TextContainer,
  TextContent,AppBar,
  AppBarTitle, DescriptionContainer, DescriptionText, ButtonContainer

} from './idcardstyle';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import { ShareID } from './invite';

const appwriteService = new AsbekAppwriteService();

const InfoFieldComponent = ({ label, value }) => (
  <InfoField>
    <label>{label}</label>
    <span>{value}</span>
  </InfoField>
);

const OtherUserIDPage = ({ userId }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const [profileImage, setProfileImage] = useState('');

  useEffect(() => {
    if (userId) {
      const fetchUserDetails = async () => {
        try {
          const document = await appwriteService.getDocument(userId); 
          setUserDetails(document);
          setProfileImage(document.profilePic || 'https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'); // Set default or fetched profile image
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };
      fetchUserDetails();
    }
  }, [userId]);

  const flipCard = () => setIsFlipped((prev) => !prev);

  const telegramLink = userId ? `https://t.me/asbeksbot/start/?startapp=myid${userId}` : '';
  const title = "My Id Card";

  if (!userDetails) return <div>Loading...</div>;

  return (
    <CardContainer>
      <AppBar>
              <AppBarTitle>Profession ID Card</AppBarTitle>
            </AppBar>
            <DescriptionContainer>
            <DescriptionText>
        The Profession ID Card is a verified representation of your professional credentials, designed to showcase your skills and expertise with credibility. Once verified, you can confidently share it with employers, clients, or collaborators, ensuring trust and authenticity in your professional interactions.
        
              </DescriptionText>
      
            </DescriptionContainer>
      <IdCard className={isFlipped ? 'flipped' : ''}>
        {/* Front Side */}
        <IdCardSide>
          <ProfileSection>
            <ProfilePicWrapper style={{ position: 'relative' }}>
              <img
                src={profileImage}
                alt="Profile"
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
            </ProfilePicWrapper>
            <VerificationBadge verified={userDetails.verified}>
              {userDetails.verified ? '✔ Verified' : '❌ Unverified'}
            </VerificationBadge>
          </ProfileSection>
          <ProfileDetails>
            <InfoFieldComponent
              label="Full Name:"
              value={userDetails.fullName || 'John Doe'}
            />
            <InfoFieldComponent
              label="Profession:"
              value={userDetails.profession || 'Your Profession'}
            />
            <InfoFieldComponent
              label="Country:"
              value={userDetails.country || 'Your Country'}
            />
          </ProfileDetails>
        </IdCardSide>

        {/* Back Side */}
        <IdCardSide style={{ transform: 'rotateY(180deg)', padding: '20px' }}>
          <ProfileDetails style={{ wordWrap: 'break-word' }}>
            <InfoFieldComponent
              label="Contact:"
              value={userDetails.phoneNumber || '(+123) 456-7890'}
            />
            <InfoFieldComponent
              label="Email:"
              value={userDetails.email || 'john.doe@example.com'}
            />
            <InfoFieldComponent
              label="Telegram Username:"
              value={userDetails.username || '@yourusername'}
            />
            
          </ProfileDetails>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '10px', width: '100%' }}>
  <div style={{ width: '100px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <QRCode value={telegramLink} size={100} />
  </div>
  <TextContainer height="120px">
    <TextContent bold>
      This ID is issued by Asbeks.
    </TextContent>
    <TextContent>
      The owner of this ID is{' '}
      <span style={{ color: userDetails.verified ? 'green' : 'red', fontWeight: 'bold' }}>
        {userDetails.verified ? 'verified' : 'not yet verified'}
      </span>{' '}
      as a{' '}
      <span style={{ color: 'dodgerblue', fontWeight: 'bold' }}>
        {userDetails.profession || 'Your Profession'}
      </span>{' '}
      and is{' '}
      <span style={{ color: userDetails.verified ? 'green' : 'red', fontWeight: 'bold' }}>
        {userDetails.verified ? 'trusted' : 'not trusted at this time'}
      </span>.
    </TextContent>
  </TextContainer>
</div>
        </IdCardSide>
      </IdCard>
      <FlipButton onClick={flipCard}>
          {isFlipped ? 'Flip to Front' : 'Flip to Back'}
        </FlipButton>
      {/* Flip Button */}
      
       
        <div style={{ marginTop: '20px' }}>
          <ShareID inviteLink={telegramLink} title={title} />
        
      </div>
    </CardContainer>
  );
};

export default OtherUserIDPage;

import styled from "styled-components";

// Container for the explanation page
export const ExplanationContainer = styled.div`
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  max-width: 900px;
  margin: 3rem auto;
  font-family: "Arial", sans-serif;
  line-height: 1.8;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 1.5rem;
    margin: 2rem auto;
  }
`;

export const Title = styled.h1`
  font-size: 2.6rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`;

export const Section = styled.div`
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1.8rem;
  color: #555;
  margin-bottom: 0.8rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

export const Text = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const Highlight = styled.span`
  color: #007bff;
  font-weight: bold;
`;

export const CTAButton = styled.button`
  display: block;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 2rem auto 0;
  text-align: center;
  transition: background-color 0.3s;
  font-weight: bold;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
`;

import React, {useContext, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import useQuoteGenerator from './quoteLogic';
import html2canvas from 'html2canvas';
import axios from 'axios';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import { CoinContext } from '../contextdtatashare/CoinContext';
import UserFileDropdown from './usersimages';
const appwriteService = new AsbekAppwriteService();
const QuoteGenerator = () => {
  const [bannerMessage, setBannerMessage] = useState(null);
  const [bannerType, setBannerType] = useState('success');
  const {quotesfiles,setWeeklyClickCount,theRank, setTheRank,friendsCount, taskEarn, appConfig, withdrawDate, allowWithdraw, withdrawRequestType, setWithdrawalRequests, setWithdrawRequestType, withdrawalRequests, withdrawLimit, theCoinValue, theasbeksPrice, decrementCoinValue } = useContext(CoinContext);
  const { additionalPrompt, setAdditionalPrompt, quote, loading, selectedType, setSelectedType, generateQuote } = useQuoteGenerator();
  const [theme, setTheme] = useState('gradient');
  const [fontSize, setFontSize] = useState(30);  
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [gradientColors, setGradientColors] = useState(['#00c6ff', '#0072ff']);  // Cool blue gradient
 // Default gradient
  const [solidColor, setSolidColor] = useState('#4caf50'); // Default solid color
  const quoteCardRef = useRef();
  const [saving, setSaving] = useState(false);
  const PEXELS_API_KEY = 'EtuCEj3nFCNiX8UeM2JedgdfYrhV7aGXutBeg4OOXY3yupKlGyxmS9sg'; // Replace with your Pexels API key
  const [showConfirmation, setShowConfirmation] = useState(false); // State for confirmation
  const [isGenerating, setIsGenerating] = useState(false); // State for tracking generation
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const tg = window.Telegram.WebApp;
    tg.ready();
  
    const initDataUnsafe = tg.initDataUnsafe;
    const usertg = initDataUnsafe?.user;
  
    const userId = String(usertg?.id).trim();
    const showBanner = (message, type = 'success') => {
      setBannerMessage(message);
      setBannerType(type);
  
      // Hide the banner automatically after 3 seconds
      setTimeout(() => {
        setBannerMessage(null);
      }, 3000);
    };
    const saveToAppwrite = async () => {
      if (quote === "Your generated quote will appear here!") {
        showBanner('Please generate a quote before saving.', 'error');
        return;
      }
      setSaving(true);
      if (!quoteCardRef.current) {
        console.error('Quote card reference is not available');
        showBanner('Failed to save quote. Please try again.', 'error');
        setSaving(false);
        return;
      }
  
      try {
        const canvas = await html2canvas(quoteCardRef.current, {
          backgroundColor: null, // Keeps transparency
          useCORS: true,         // To avoid CORS issues
        });
  
        canvas.toBlob(async (blob) => {
          if (!blob) {
            console.error('Failed to create blob from canvas');
            showBanner('Failed to save quote. Please try again.', 'error');
            setSaving(false);
            return;
          }
  
          const file = new File([blob], `quote-${Date.now()}.png`, { type: 'image/png' });
  
          try {
            const response = await appwriteService.uploadQuote(file, userId);
            setSaving(false);
            console.log('Quote uploaded successfully:', response);
            showBanner('Quote saved successfully!', 'success');
          } catch (error) {
            setSaving(false);
            console.error('Error uploading quote to Appwrite:', error);
            showBanner('Failed to save quote. Please try again.', 'error');
          }
        }, 'image/png');
      } catch (error) {
        console.error('Error generating image:', error);
        showBanner('Failed to save quote. Please try again.', 'error');
      }
    };
  

  // Function to handle confirmation before generating
  const handleGenerateClick = () => {
    console.log("Generate button clicked");
  
    // Check if the coinValue is less than 100
    if (theCoinValue < 100) {
      // Set the insufficient balance state to true to display the error
      setInsufficientBalance(true);
      return; // Prevent further execution if balance is insufficient
    }
  
    setShowConfirmation(true); // Proceed with showing the confirmation
  };
  
  

  const confirmGenerateQuote = () => {
    setShowConfirmation(false); // Close confirmation
    setIsGenerating(true); // Start generating
    generateQuote(); // Proceed with quote generation
  };

  const cancelGenerateQuote = () => {
    setShowConfirmation(false); // Close confirmation without generating
  };
  // Function to fetch stock images from Pexels based on the quote type
  // Function to fetch stock images from Pexels based on the quote type
  const fetchBackgroundImage = async (type) => {
    try {
      const bg = quotesfiles; // Fetch files using the listFiles method
      console.log('Fetched files:', bg);
  
      // Check if there are files and pick one randomly
      if (bg && bg.length > 0) {
        const randomIndex = Math.floor(Math.random() * bg.length); // Random index for selection
        const selectedImage = bg[randomIndex].fileViewURL; // Get the view URL of the selected image
        console.log('selectredß files:', selectedImage);

        setBackgroundImage(selectedImage.href); // Set background image to the random file's URL
      } else {
        console.warn('No background images available.');
        setBackgroundImage(null); // If no images available, reset background
      }
    } catch (error) {
      console.error("Error fetching background image:", error);
      setBackgroundImage(null); // Reset on error
    }
  };
  
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setBackgroundImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    if (newTheme === 'image') {
      document.getElementById('imageInput').click();
    } else if (newTheme === 'stock') {
        fetchBackgroundImage(`${selectedType}background without text`); 
      }
    else {
      setBackgroundImage(null);
    }
  };

  const handleGradientColorChange = (index, color) => {
    const updatedColors = [...gradientColors];
    updatedColors[index] = color;
    setGradientColors(updatedColors);
  };

  const downloadQuoteCard = () => {
    if (backgroundImage) {
      // Fetch the stock image as a blob and embed it in the canvas
      fetch(backgroundImage)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
              // Render the card with the image embedded
              html2canvas(quoteCardRef.current, {
                backgroundColor: null, // Keeps transparency
                useCORS: true,         // To avoid CORS issues
              }).then((canvas) => {
                const link = document.createElement('a');
                link.download = 'quote-card.png';
                link.href = canvas.toDataURL('image/png');
                link.click();
              });
            };
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => console.error('Error downloading the background image:', error));
    } else {
      // Proceed without a background image
      html2canvas(quoteCardRef.current, {
        backgroundColor: null,
        useCORS: true,
      }).then((canvas) => {
        const link = document.createElement('a');
        link.download = 'quote-card.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
      });
    }
  };
  

  const shareStory = () => {
    try {
      const mediaUrl = 'https://cloud.appwrite.io/v1/storage/buckets/66c0b25b000872a841b7/files/66c0b28a002f60c59e82/view?project=66af5ec5001608e38606&project=66af5ec5001608e38606&mode=admin';
  
      // Ensure Telegram WebApp is ready
      const tg = window.Telegram?.WebApp;
  
      // Check if the method is available
      if (tg && typeof tg.shareToStory === 'function') {
        // Share the story with parameters
        tg.shareToStory(mediaUrl, {
          is_private: false, // Set to true if you want the story to be private
          caption: quote, // Add a caption to the story
          background_color: '#FFFFFF' // Optional background color
        });
  
        //console.log('Story shared successfully!');
      } else {
        console.error('shareToStory is not available in this WebApp version.');
      }
    } catch (error) {
      console.error('Error sharing story:', error);
    }
  };

  useEffect(() => {
    if (quote) {
      const quoteLength = quote.length;
      setFontSize(quoteLength > 120 ? 20 : quoteLength > 80 ? 25 : 30);
    }
  }, [quote]);

  return (
    <Container>
      <Title>AI Quote Generator</Title>
      
      <input
        type="file"
        id="imageInput"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleImageUpload}
      />

      <ThemeSelector>
        <button onClick={() => handleThemeChange('gradient')}>Gradient</button>
        <button onClick={() => handleThemeChange('solid')}>Solid</button>
        <button onClick={() => handleThemeChange('image')}>Image</button>
        <button onClick={() => handleThemeChange('stock')}>Stock</button>
      </ThemeSelector>

      {theme === 'gradient' && (
        <GradientSettings>
          <ColorInput
            type="color"
            value={gradientColors[0]}
            onChange={(e) => handleGradientColorChange(0, e.target.value)}
          />
          <ColorInput
            type="color"
            value={gradientColors[1]}
            onChange={(e) => handleGradientColorChange(1, e.target.value)}
          />
        </GradientSettings>
      )}

      {theme === 'solid' && (
        <SolidColorPicker>
          <label>Select Solid Color:</label>
          <ColorInput
            type="color"
            value={solidColor}
            onChange={(e) => setSolidColor(e.target.value)}
          />
        </SolidColorPicker>
      )}
      {insufficientBalance && (
  <ErrorMessage>
    Insufficient balance. You need at least 100 Asbeks to generate a quote.
  </ErrorMessage>
)}
      {showConfirmation && (
        <ConfirmationModal>
          <h2>Pay 100 Asbeks for 1 Quote</h2>
          <p>You will pay 100 Asbeks to generate your quote. Do you want to continue?</p>
          <button onClick={confirmGenerateQuote}>Yes</button>
          <button onClick={cancelGenerateQuote}>No</button>
        </ConfirmationModal>
      )}
  <PromptInput
        type="text"
        placeholder="What are you feeling (optional)"
        value={additionalPrompt} // Use additionalPrompt state
        onChange={(e) => setAdditionalPrompt(e.target.value)} // Update additionalPrompt state on change
      />
  {loading ?(
    <QuoteCard ref={quoteCardRef} theme={theme} backgroundImage={backgroundImage} gradientColors={gradientColors} solidColor={solidColor}>
    <GeneratingContainer>
    <div>Generating your {selectedType} quote...</div>
    <AIAnimation>
      <div></div>
      <div></div>
      <div></div>
    </AIAnimation>
  </GeneratingContainer>
    
  </QuoteCard>

  ):(
    <QuoteCard ref={quoteCardRef} theme={theme} backgroundImage={backgroundImage} gradientColors={gradientColors} solidColor={solidColor}>
        {quote && <Quote >{quote}</Quote>}
      
      <Footer>Created with ASBEKS💡</Footer>
    </QuoteCard>
  )

}

      <RadioGroup>
        {['motivational', 'inspirational', 'funny', 'philosophical', 'leadership', 'love', 'wisdom', 'happiness', 'friendship', 'sadness', 'islamic', 'christian'].map((type) => (
          <RadioLabel key={type}>
            <input
              type="radio"
              value={type}
              checked={selectedType === type}
              onChange={() => setSelectedType(type)}
            />
            {capitalizeFirstLetter(type)}
          </RadioLabel>
        ))}
      </RadioGroup>

      <GenerateButton onClick={handleGenerateClick} disabled={loading}>
        {loading ? 'Generating...' : 'Generate Quote'}
      </GenerateButton>
      {bannerMessage && <Banner type={bannerType}>{bannerMessage}</Banner>}
      <DownloadButton onClick={saveToAppwrite} disabled={saving}>
        {saving ? 'saving...' : 'Save Quote Image'}
      </DownloadButton>
      <UserFileDropdown userId={userId} />
      {/* <ShareButton onClick={shareStory}>
        {loading ? 'Generating...' : 'Share Quote On Story'}
      </ShareButton> */}
    </Container>
  );
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 80px;
  padding: 30px 20px;
  text-align: center;
  background-color: #f7f7f7;
  border-radius: 15px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s ease, transform 0.3s ease;
  &:hover {
    transform: scale(1.02);
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
`;

const QuoteCard = styled.div`
  background: ${({ theme, backgroundImage, gradientColors, solidColor }) =>
    theme === 'solid'
      ? solidColor
      : theme === 'gradient'
      ? `linear-gradient(135deg, ${gradientColors[0]}, ${gradientColors[1]})`
      : backgroundImage
      ? `url(${backgroundImage})`
      : 'linear-gradient(135deg, #ff9966, #ff5e62)'};
  background-size: cover;
  background-position: center;
  color: #fff;
  border-radius: 20px;
  padding: 20px;
  width: 100%; /* Make the card responsive */
  max-width: 400px; /* Maximum width */
  height: auto; /* Allow height to adjust based on width */
  aspect-ratio: 1; /* Maintain a 1:1 aspect ratio */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  word-wrap: break-word;
  white-space: pre-wrap;
  letter-spacing: 0.5px;
  line-height: 1.6;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  /* Black overlay */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
    border-radius: 20px; /* To match the rounded corners */
    pointer-events: none; /* Prevent overlay from affecting interactivity */
  }

  /* Inner content */
  & > * {
    position: relative; /* Ensure the content stays above the overlay */
  }

  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 18px;
    pointer-events: none;
  }
`;



const Quote = styled.p`
  font-size: clamp(16px, 5vw, 30px); /* Responsive font size */
  font-weight: 600;
  font-style: italic;
  color: #fff;
  line-height: 1.6;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  max-height: 300px;
  overflow: hidden;
  white-space: normal;

  /* Additional responsiveness */
  @media (max-width: 768px) {
    font-size: clamp(14px, 6vw, 25px); /* Adjusted font size for smaller devices */
  }

  @media (max-width: 480px) {
    font-size: clamp(12px, 8vw, 20px); /* Adjusted font size for mobile devices */
  }
`;


const Footer = styled.p`
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  margin-top: 10px;
  opacity: 0.8;
  font-style: italic;
`;

const GradientSettings = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
`;

const ColorInput = styled.input`
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
`;

const SolidColorPicker = styled.div`
  margin-bottom: 15px;
  label {
    margin-right: 10px;
  }
`;

const ThemeSelector = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;

  button {
    padding: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;

    &:hover {
      background-color: #555;
    }
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
`;

const RadioLabel = styled.label`
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    margin-right: 5px;
  }
`;

const GenerateButton = styled.button`
  padding: 12px 25px;
  background-color: #ff5722;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
  width: 200px;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #e64a19;
  }
`;

const DownloadButton = styled(GenerateButton)`
  background-color: #4caf50;

  &:hover {
    background-color: #388e3c;
  }
`;

const ShareButton = styled(GenerateButton)`
  background-color: #2196f3;

  &:hover {
    background-color: #1976d2;
  }
`;

const Loading = styled.p`
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  opacity: 0.8;
`;

const GeneratingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  font-family: 'Roboto Mono', monospace; /* Futuristic font */
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.7), 0 0 20px rgba(0, 255, 255, 0.5);
`;

const AIAnimation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  div {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background: linear-gradient(45deg, #00ffff, #8a2be2);
    border-radius: 50%;
    animation: pulse 1.5s infinite ease-in-out;
  }

  div:nth-child(2) {
    animation-delay: 0.2s;
  }

  div:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.5);
      opacity: 1;
    }
  }
`;
const ConfirmationModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  text-align: center;
  z-index: 1000;
  button {
    margin: 10px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #45a049;
    }
  }
`;
const ErrorMessage = styled.div`
  color: red;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
`;
const Banner = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  /* Center it */
  background-color: rgba(0, 0, 0, 0.7);  /* Semi-transparent background */
  color: white;
  padding: 20px 30px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  z-index: 9999;  /* Ensure it's above all other elements */
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  word-wrap: break-word;
  text-align: center;
`;
const PromptInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

export default QuoteGenerator;

import React from "react";
import { ExplanationContainer, Title, Section, Subtitle, Text, Highlight, CTAButton } from "./ExplanationStyle";
import { Helmet } from "react-helmet";

const ExplanationPage = ({ onGetStarted }) => {
    
  return (
    <ExplanationContainer>
        <Helmet>
        <title>AI Telegram Channel Automation - Streamline Your Telegram</title>
        <meta
          name="description"
          content="Leverage AI to automate your Telegram channel. Enhance efficiency, boost engagement, and save time with intelligent solutions."
        />
        <meta
          name="keywords"
          content="AI Channel Automation, Telegram Automation, AI Bot, Efficiency, Engagement, Task Automation"
        />
        <meta name="Asbeks" content="" />
      </Helmet>
      <Title>Supercharge Your Telegram Channel with Asbeks AI</Title>

      <Section>
        <Subtitle>Are You Struggling to Keep Your Channel Active?</Subtitle>
        <Text>
          Managing a Telegram channel is no small feat. You might have started it with great enthusiasm, sharing posts and growing your audience.
          But over time, the reality hits—you need to keep posting regularly to retain your subscribers, stay relevant, and grow further. 
          And let’s be honest, life doesn’t always give you the time to focus on your channel every single day.
        </Text>
        <Text>
          Maybe you’ve noticed a drop in engagement. Maybe you’re spending too much time thinking, "What should I post next?" Or maybe you’ve missed days or even weeks of posting, and you can see the impact on your subscriber count.
          It’s frustrating, isn’t it? And it feels like you’re stuck in a never-ending cycle of trying to do more with less time.
        </Text>
        <Text>
          Don’t worry—you’re not alone. Many channel owners feel this way. But here’s the good news: <Highlight>Asbeks AI is here to make your life easier.</Highlight>
        </Text>
      </Section>

      <Section>
        <Subtitle>Why Asbeks AI? What Makes It Different?</Subtitle>
        <Text>
          Let’s get real: automation tools are not new. But not all automation tools are created equal. 
          Asbeks AI is designed specifically for Telegram channel owners who want to save time, grow their audience, and keep their subscribers engaged—without the stress and overwhelm.
        </Text>
        <Text>
          Here’s what sets Asbeks AI apart:
        </Text>
        <ul>
          <li>
            <Highlight>24/7 Automation:</Highlight> No matter how busy you are, your channel stays active. Posts go out on time, every time, even while you sleep.
          </li>
          <li>
            <Highlight>Audience-Centered Content:</Highlight> Asbeks AI understands your audience’s interests and ensures that every post feels relevant and engaging.
          </li>
          <li>
            <Highlight>Effortless Growth:</Highlight> Consistency is the key to growth on Telegram, and Asbeks AI makes it happen effortlessly.
          </li>
          <li>
            <Highlight>Affordable Pricing:</Highlight> At just 2 TON per month, Asbeks AI is a small investment that delivers massive returns.
          </li>
        </ul>
        <Text>
          Think of it as your personal assistant—always on, always working, always delivering results.
        </Text>
      </Section>

      <Section>
        <Subtitle>How Asbeks AI Solves Your Problems</Subtitle>
        <Text>
          Picture this: You’re managing a growing Telegram channel with hundreds or even thousands of subscribers. Your audience expects fresh, valuable content every day. 
          But here’s the problem—you’re busy. Maybe you’re running a business, juggling a full-time job, or just trying to enjoy life. 
          And creating engaging posts? That’s a job in itself.
        </Text>
        <Text>
          With Asbeks AI, you can say goodbye to the stress of daily posting. The AI takes care of everything:
        </Text>
        <ul>
          <li>
            Schedules and publishes posts automatically, ensuring your channel never misses a beat.
          </li>
          <li>
            Analyzes what your audience loves and tailors posts to match their interests.
          </li>
          <li>
            Boosts engagement by keeping your content consistent and on-brand.
          </li>
        </ul>
        <Text>
          It’s like having a professional content manager for a fraction of the cost. Plus, it frees up your time so you can focus on what really matters.
        </Text>
      </Section>

      <Section>
        <Subtitle>Is It Really Worth It?</Subtitle>
        <Text>
          Let’s break it down. Imagine you spend 2-3 hours a week creating and managing posts for your channel. That’s time you could be using to work on other parts of your business or even just relaxing with your family.
        </Text>
        <Text>
          Now, think about what happens when your channel grows. With a larger audience, the stakes are higher. Each post has to be perfect to keep your subscribers engaged.
        </Text>
        <Text>
          For just 2 TON per month, Asbeks AI:
        </Text>
        <ul>
          <li>Saves you hours of work every week.</li>
          <li>Ensures your channel stays consistent and professional.</li>
          <li>Helps you grow your audience faster and retain your subscribers longer.</li>
        </ul>
        <Text>
          That’s a tiny price to pay for the peace of mind and results you’ll get.
        </Text>
      </Section>

      <Section>
        <Subtitle>How to Get Started with Asbeks AI</Subtitle>
        <Text>
          Ready to take the leap? Here’s how simple it is to get started:
        </Text>
        <ol>
          <li>Search for <b>@AsbeksBot</b> on Telegram.</li>
          <li>Open the bot and explore the AI menu.</li>
          <li>Link your Telegram channel to the bot and set up your preferences.</li>
          <li>Start your automation and let the magic happen!</li>
        </ol>
        <Text>
          It’s easy, fast, and you’ll see the difference from day one.
        </Text>
      </Section>

      <Section>
        <Subtitle>Join Thousands of Satisfied Channel Owners</Subtitle>
        <Text>
          Asbeks AI isn’t just a tool—it’s a game-changer for Telegram channel owners worldwide. Whether you’re running a tech-focused channel, a lifestyle blog, or a business community, 
          Asbeks AI helps you achieve your goals without burning out.
        </Text>
        <Text>
          Don’t just take my word for it—join the growing community of smart channel owners who are already using Asbeks AI to stay ahead of the curve.
        </Text>
      </Section>

      <Section>
        <Subtitle>Take Control of Your Channel Today</Subtitle>
        <Text>
          You’ve worked hard to build your Telegram channel. Don’t let it stagnate or fall behind. With Asbeks AI, you’ll have the tools you need to stay consistent, engage your audience, and grow like never before.
        </Text>
        <Text>
          So, what are you waiting for? Click the button below to get started, and let Asbeks AI take your Telegram channel to the next level.
        </Text>
      </Section>

      <CTAButton onClick={onGetStarted}>Get Started with Asbeks AI</CTAButton>
    </ExplanationContainer>
  );
};

export default ExplanationPage;

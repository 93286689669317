import { useEffect, useState, useRef } from 'react';

let showOnClickAd = null; // Global reference to the ad show method

const initializeAd = async () => {
  try {
    console.log('Loading ad script...');

    // Load the ad script dynamically
    const script = document.createElement('script');
    script.src = 'https://js.onclckvd.com/in-stream-ad-admanager/tma.js';
    script.async = true;

    // Wait until the script loads before proceeding
    await new Promise((resolve, reject) => {
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });

    // Initialize the ad engine
    const show = await window.initCdTma({ id: '6048832' }); // Use your spotId here
    console.log('Ad engine initialized successfully');
    showOnClickAd = show; // Assign the show method to the global variable
  } catch (e) {
    console.error('Error initializing ad engine:', e);
    alert('No ads available');
  }
};

const playAd = async (userId, setLocalCoinValue, setLocalClickCount) => {
  console.log('Attempting to play ad...');
  if (showOnClickAd) {
    try {
      await showOnClickAd();
      // Increment the coin value
      
      setLocalCoinValue((prevCount) => {
        const newCoinValue = prevCount + 50;
        localStorage.setItem(`localCoinValue_${userId}`, newCoinValue);
        return newCoinValue;
      });
      if(setLocalClickCount){
        setLocalClickCount((prevCount) => {
          const newClickCount = prevCount + 1;
          localStorage.setItem(`localClickCount_${userId}`, newClickCount); // Save to localStorage on each click
          return newClickCount;
        });
      }
      console.log('Video ad played successfully');
    } catch (err) {
      console.error('Ad failed:', err);
    }
  } else {
    console.error('Ad engine not initialized yet');
  }
};

const VideoAd = () => {
  const videoRef = useRef();

  useEffect(() => {
    // Initialize the ad system on component mount
    initializeAd();

    return () => {
      console.log('Cleaning up ad resources...');
      // Cleanup logic can be implemented here if necessary
    };
  }, []);

  return <div ref={videoRef} id="videoAdContainer"></div>;
};

export { initializeAd, playAd };
export default VideoAd;

import React, { createContext, useState, useEffect } from 'react';
import AsbekAppwriteService from '../api/AsbekAppwriteService'; // Import the service
import AppwriteService from '../api/liveWeekly';
import axios from 'axios';
// Create a Context for all user data fields
export const CoinContext = createContext();
const appwriteService = new AsbekAppwriteService();
const asbekService = new AsbekAppwriteService();

// Create a Provider component
export function CoinProvider({ children }) {
  const [friends, setFriends] = useState([]);
    const [friendsCount, setfriendsCount] = useState(0);
    const [loadingFriends, setLoadingFriends] = useState(true);
    const [errorFriends, setErrorFriends] = useState(null);
  const [theasbeksPrice, setAsbeksPrice] = useState(null);
  const [theRank, setTheRank] = useState(null);
  const [dailyRank, setDailyRank] = useState(null);
  const [theUser, setUser] = useState(null); 
  const [theUserId, setUserId] = useState(null);
  const [theUsername, setUsername] = useState(null);
  const [theFirstName, setFirstName] = useState(null);
  const [theLastName, setLastName] = useState(null);
  const [theInvitedBy, setInvitedBy] = useState(null);
  const [theInvitationLink, setInvitationLink] = useState(null);
  const [theCoinValue, setCoinValue] = useState(null);
  const [profession, setProfession] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [UsersCountry, setUsersCountry] = useState(null);
  const [taskEarn, setTaskEarn] = useState(null);
  const [theLastReset, setLastReset] = useState(null);
  const [theClickCount, setClickCount] = useState(null);
  const [theAccountNumber, setAccountNumber] = useState(null);
  const [theAccountName, setAccountName] = useState(null);
  const [thePhoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [theDailyClickCount, setDailyClickCount] = useState(null);
  const [theWeeklyClickCount, setWeeklyClickCount] = useState(null);
  const [theMonthlyClickCount, setMonthlyClickCount] = useState(null);
  const [theLastDailyReset, setLastDailyReset] = useState(null);
  const [theLastWeeklyReset, setLastWeeklyReset] = useState(null);
  const [theLastMonthlyReset, setLastMonthlyReset] = useState(null);
  const [theBlock, setBlock] = useState(false);
  const [verified, setVerified] = useState(false);
  const [theClickHistory, setClickHistory] = useState([]);
  const [theOnline, setOnline] = useState(null);
  const [theLastWeekClickCount, setLastWeekClickCount] = useState(null);
  const [theSessionId, setSessionId] = useState(null);
  const [theWallet, setWallet] = useState(null);
  const [theSessionTime, setSessionTime] = useState(null);
  const [ergaa, setErgaa] = useState('');
  const [startParam, setStartParam] = useState(null);
  const [theTopUsers, setTopUsers] = useState({ top3: [], rest: [] });
  const [theTop3Users, setTop3Users] = useState({ top3: [], rest: [] });
  const [theAllDocuments, setTheAllDocuments] = useState([]);
  const [competitionStartDate, setCompetitionStartDate] = useState(null);
  const [withdrawLimit, setWithdrawLimit] = useState(null);
  const [withdrawDate, setWithdrawDate] = useState('');
  const [allowWithdraw, setAllowWithdraw] = useState(false);
  const [appConfig, setAppConfig] = useState(null);
  const [balance, setBalance] = useState(null);
  const [goal, setGoal] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [completedUsersCount, setcompletedUsersCount] = useState([]);
  const [vpn, setVpn] = useState(false);
  const [country, setCountry] = useState('');
  const [showBanner, setShowBanner] = useState(false);
  const [loadingTheTasks, setLoadingTasks] = useState(true);
  const [tasksError, setTasksError] = useState(null);
  const [createWallet, setCreateWallet] = useState();
  const [blockId, setBlockId] = useState();
  const [initData, setInitData] = useState(null);
  const [validationStatus, setValidationStatus] = useState('');
  const [cryptoToUsd, setCryptoToUsd] = useState();
  const [estimatedUSD, setEstimatedUSD] = useState();
  const [taskType, setTaskType] = useState("notcompleted");
  const [taskCount, setTaskCount] = useState(0);
  const [localClickCount, setLocalClickCount] = useState(0);
  const [localCoinValue, setLocalCoinValue] = useState(0);
  const [taskCursor, setTaskCursor] = useState(null);
  const [nextTaskCursor, setNextTaskCursor] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [withdrawRequestType, setWithdrawRequestType] = useState("pending");
  const [loadingwithdrawrequests, setLoadingWithdrawRequests] = useState(true);
  const [withdrawRequestCursor, setWithdrawRequestCursor] = useState(null);
  const [withdrawRequestNextCursor, setWithdrawRequestNextCursor] = useState(null);
  const [withdrawHasMore, setWithdrawHasMore] = useState(false);
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);
  const [withdrawalRequestsCount, setWithdrawalRequestsCount] = useState(0);
  const [quotesfiles, setQuotesFiles] = useState([]);
  const [channels, setChannels] = useState([]);
  const [posts, setPosts] = useState([]);
  const [telegramChatId, setTelegramChatId] = useState("");
  const [loadingChannels, setLoadingChannels] = useState(true);
  const tg = window.Telegram.WebApp;
  tg.ready();

  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;

  useEffect(() => {
    if (usertg) {
      setUser(usertg);
      setUserId(String(usertg?.id).trim());
    }
  }, [usertg]);

  const userId = String(usertg?.id).trim();
  useEffect(() => {
    const listQuotesFiles = async () => {
      try {

        const response = await appwriteService.listFiles()
        setQuotesFiles(response)
       
      } catch (error) {
        console.error("Error fetching tasks:", error.message);
        
      }
    };

    listQuotesFiles();
  }, [ ]);
  
  useEffect(() => {
    const fetchFriends = async () => {
        try {
            const response = await appwriteService.getFriends(userId);
            setFriends(response.friends); // Set the list of friends
            setfriendsCount(response.count);    // Set the count of friends
        } catch (err) {
            setErrorFriends(err.message);      // Handle errors
        } finally {
            setLoadingFriends(false);          // End loading state
        }
    };

    fetchFriends(); // Call the function inside useEffect
}, [userId]); // Dependency array ensures this runs when userDocId changes


  useEffect(() => {
    const fetchWithdrawalRequests = async () => {
      try {
        setLoadingWithdrawRequests(true);  // Start loading when the API call starts
        // const response = await axios.get(
        //   "https://67100c95ea75afe346f4.appwrite.global/getTasks", 
        //   {
        //     params: { taskType, userId: userId }
        //   }
        // );

        const response = await appwriteService.fetchWithdrawalRequests({ status: withdrawRequestType, userId: userId, limit: 5, cursor: withdrawRequestNextCursor,  })
        // Optionally inspect response structure
        ////console.log('response', response.tasks)
        // Assuming response.data.tasks is the structure you're working with
        //console.log('sdjhsdjhdsj', response.withdrawalRequests)
        setWithdrawalRequests((prevWithdrawalRequests) => [...prevWithdrawalRequests, ...response.withdrawalRequests]);
        setWithdrawRequestCursor(response.nextCursor)
        setWithdrawHasMore(response.hasMore);
        const requestCount = response.withdrawalRequests?.length || 0;
        setWithdrawalRequestsCount(requestCount)
        
        setLoadingWithdrawRequests(false);
      } catch (error) {
        console.error("Error withdrawal requests :", error.message);
        // setTasksError("Failed to load tasks. Please try again later.");
        setLoadingWithdrawRequests(false);
      }
    };

    fetchWithdrawalRequests();
  }, [withdrawRequestType, withdrawRequestNextCursor ]);
  useEffect(() => {
    const fetchPosts = async () => {
        try {
            // Fetch the posts for the user based on the owner and telegramChatId
            const response = await appwriteService.getAllPostsByOwnerAndChatId({
                owner: userId,
                telegramChatId: telegramChatId
            });

            // Optionally inspect response structure
            // console.log('response', response.posts);

            // Assuming response.posts is the structure you're working with
            const postArray = Array.isArray(response.posts) ? response.posts : [];

            // Set the posts state
            setPosts(postArray);
        } catch (error) {
            console.error("Error fetching posts:", error.message);
        }
    };

    // Fetch posts when `userId` or `telegramChatId` changes
    if (userId && telegramChatId) {
        fetchPosts();
    }
}, [userId, telegramChatId]); // Dependency array: re-run when `userId` or `telegramChatId` changes

  useEffect(() => {
    const fetchChannels = async () => {
      setLoadingChannels(true)
      try {
        
        // Fetch the Telegram channels for the user
        const response = await appwriteService.getUsersTelegramChannels({ userId: userId });
  
        // Optionally inspect response structure
        // console.log('response', response.channels);
  
        // Assuming response.channels is the structure you're working with
        const channelArray = Array.isArray(response.channels) ? response.channels : [];
  
        // Set the channels state
        setChannels(response);
        setLoadingChannels(false)
  
             } catch (error) {
              setLoadingChannels(false)
        console.error("Error fetching channels:", error.message);
             }
    };
  
    fetchChannels();
  }, [userId]); // Dependency array: re-run when `userId` changes
  
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        setLoadingTasks(true);  // Start loading when the API call starts
        // const response = await axios.get(
        //   "https://67100c95ea75afe346f4.appwrite.global/getTasks", 
        //   {
        //     params: { taskType, userId: userId }
        //   }
        // );

        const response = await appwriteService.fetchTasks({ taskType: taskType, userId: userId, limit: 5, cursor: nextTaskCursor  })
        // Optionally inspect response structure
        ////console.log('response', response.tasks)
        // Assuming response.data.tasks is the structure you're working with
        const taskArray = Array.isArray(response.tasks) ? response.tasks : [];
        setTasks((prevTasks) => [...prevTasks, ...response.tasks]);
        setTaskCursor(response.nextCursor)
        setHasMore(response.hasMore);
        const taskCount = response.tasks?.length || 0;

        if(taskType == 'notcompleted'){  
          setTaskCount(taskCount);
        }
        
        setLoadingTasks(false);
      } catch (error) {
        console.error("Error fetching tasks:", error.message);
        setTasksError("Failed to load tasks. Please try again later.");
        setLoadingTasks(false);
      }
    };

    fetchTasks();
  }, [taskType, nextTaskCursor ]);
  
  const completeTask = (taskId) => {
    setTasks((prevTasks) => prevTasks.filter((task) => task.$id !== taskId));
    if (taskCount > 0) {
      setTaskCount((prevCount) => prevCount - 1);
    }
  };
  const fetchAsbeksPrice = async () => {
    const data = window.Telegram.WebApp.initData;
    //'https://67078ccf4eb31299c3df.appwrite.global/asbeksPrice?initData=user%3D%257B%2522id%2522%253A445929610%252C%2522first_name%2522%253A%2522%25F0%259F%2591%2591Abdurazak%25F0%259F%2591%2591%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522hakaaf%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26chat_instance%3D4195751860943568501%26chat_type%3Dprivate%26auth_date%3D1729075283%26hash%3Da3d3539cc52da6af33d92bb289798907181906e588fbb778503d561cade9106d&toAddress=UQC6cS3uwguDjyOl9SrP9gty2BBynjZ70M7dHPtr5N2gA919&value=0.1&comment=Payment%20for%20services&title=Service%20Payment&description=Payment%20for%20requested%20services&earn=0.1&url=http%3A%2F%2Fexample.com%2Fservice&adsBudget=10',

    //const response = await axios.get(`https://67078ccf4eb31299c3df.appwrite.global/createwallet?initData=${encodeURIComponent(data)}`);
    const initdata =
        'user%3D%257B%2522id%2522%253A445929610%252C%2522first_name%2522%253A%2522%25F0%259F%2591%2591Abdurazak%25F0%259F%2591%2591%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522hakaaf%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26chat_instance%3D4195751860943568501%26chat_type%3Dprivate%26auth_date%3D1729075283%26hash%3Da3d3539cc52da6af33d92bb289798907181906e588fbb778503d561cade9106d';

    try {
      const response = await axios.get(
        `https://67078ccf4eb31299c3df.appwrite.global/asbeksPrice`,
       
      );
      //////console.log('projnjnsdk', response)
      // Extract `asbeksPrice` and `coinValueToUsd` from the response
      setAsbeksPrice(response.data.asbeksPrice);

      // setCoinValueToUsd(response.data.coinValueToUsd);
    } catch (err) {
      console.error('Error fetching ASBeks price:', err);
      const totalSupply = appConfig.totalSupply
      const totalBalance = appConfig.balance
      const tonPriceInUsd = appConfig.tonPriceInUsd

      const balanceInUsd = totalBalance * tonPriceInUsd;
      const asbeksPrice = balanceInUsd/totalSupply
      // fetchAsbeksPrice(); // Fetch only if Asbeks price is not available in context
      setAsbeksPrice(asbeksPrice)
    
      
    }
  };

  useEffect(() => {
    fetchAsbeksPrice();
  }, []);

  const getTonPriceInUsd = async () => {
    const price_url = `https://jetton-index.tonscan.org/market_data/chart?days=1&interval=daily&vs_currency=USD&precision=4`;
    try {
      const price_response = await axios.get(price_url);
      if (price_response.status === 200) {
        const latestPriceData = price_response.data.prices;
        // Assuming the latest price is the last entry in the prices array
        return latestPriceData[latestPriceData.length - 1][1]; // The price of 1 TON in USD
      } else {
        console.error('Error fetching price data:', price_response.statusText);
        return null; // Or handle the error appropriately
      }
    } catch (error) {
      console.error('Error fetching price data:', error);
      return null;
    }
  };

  // useEffect(() => {
    
  // }, []);

  useEffect(() => {
    const fetchWalletData = async () => {
      const data = window.Telegram.WebApp.initData;
      try {
       // const response = await axios.get(`https://67078ccf4eb31299c3df.appwrite.global/createwallet?initData=user%3D%257B%2522id%2522%253A445929610%252C%2522first_name%2522%253A%2522%25F0%259F%2591%2591Abdurazak%25F0%259F%2591%2591%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522hakaaf%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26chat_instance%3D4195751860943568501%26chat_type%3Dprivate%26auth_date%3D1729075283%26hash%3Da3d3539cc52da6af33d92bb289798907181906e588fbb778503d561cade9106d&toAddress=UQC6cS3uwguDjyOl9SrP9gty2BBynjZ70M7dHPtr5N2gA919&value=0.1&comment=Payment%20for%20services`); // Replace with your actual API URL
        const response = await axios.get(`https://67078ccf4eb31299c3df.appwrite.global/createwallet?initData=${encodeURIComponent(data)}`);
        const wallet = response.data; // Destructure response data
        setCreateWallet(wallet);
      
          const price = await getTonPriceInUsd();
          if (price) {
            setCryptoToUsd(price);
            setEstimatedUSD(wallet.balance * price);
          }
       
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      } 
    };

   // fetchWalletData();
  }, []);

  const fetchWalletData = async () => {
    //////console.log('loadiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')
    try {
      const data = window.Telegram.WebApp.initData;
      const response = await axios.get(`https://67078ccf4eb31299c3df.appwrite.global/createwallet?initData=${encodeURIComponent(data)}`); // Replace with your actual API URL
      const wallet = response.data; // Destructure response data
      setCreateWallet(wallet);
      const price = await getTonPriceInUsd();
          if (price) {
            setCryptoToUsd(wallet.balance);
          }

      
     
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    } 
  };
  useEffect(() => {
    // Function to get the country
    
  const getCountry = async () => {
    try {
      // Make the API call to get IP information
      const response = await axios.get('https://ipapi.co/json/');
      const ipInfo = response.data;

      // Set the country state
      if (ipInfo.country) {
        setCountry(ipInfo.country);
        // Show banner if the country is Ethiopia
        if (ipInfo.country === 'ET') { // Use 'ET' for Ethiopia's ISO country code
          setShowBanner(true);
        } else {
          setShowBanner(false); // Hide banner if the country is not Ethiopia
        }
      }
    } catch (error) {
      console.error('Error fetching country info:', error.message);
    }
  };

    // Fetch country initially
    getCountry();

    // Set up polling to fetch country every 30 seconds
    const intervalId = setInterval(getCountry, 30000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  // useEffect(() => {
  //   const getTasks = async () => {
  //     try {
  //       const tasks = await appwriteService.getAllTasks();
  //       const tasksWithCount = await Promise.all(
  //         tasks.map(async task => {
  //           const completedUsersCount = task.completedUsers.length;
  //           // Add a label for whether the task is completed by anyone
  //           const budgetCompleted = completedUsersCount >= task.adsBudget;

  //           // Add a label for whether the task's adsBudget is completed
  //           const completionLabel = budgetCompleted ? 'Completed' : 'Not Yet Completed';
  //           const isMyTask = task.owner === userId ? 'My Task' : '';
  //           const isTelegramLink =
  //             task.url.startsWith('https://t.me/') ||
  //             task.url.startsWith('http://t.me/') ||
  //             task.url.startsWith('https://telegram.me/') ||
  //             task.url.startsWith('http://telegram.me/');
            
  //           if (isTelegramLink) {
  //             // const username = task.url.startsWith('https://t.me/')
  //             //   ? task.url.replace('https://t.me/', '')
  //             //   : task.url.replace('http://t.me/', '');
              
  //             const username = task.url.startsWith('https://t.me/')
  //               ? task.url.replace('https://t.me/', '').split(/[/\?]/)[0] // Split on either '/' or '?'
  //               : task.url.replace('http://t.me/', '').split(/[/\?]/)[0];

  //             const isBot = username.endsWith('bot');
  //             if(!isBot){
                
  //               try {
  //                 const response = await fetch(`https://66f46cdd4587b0b3ee6b.appwrite.global/check_membership/@${username}/${userId}`);
  //                 const data = await response.json();
                  
  //                 // Check if there are any errors related to the bot not being an admin
  //                 if (data.error === 'Failed to retrieve bot status in the channel/group.' ||
  //                     data.error === 'The bot is not an admin in this channel or group.') {
  //                   return null; // Exclude this task from the list
  //                 }

  //                 if (['member'].includes(data.status)) {
  //                   return null;
  //                 }

  //                 if (['administrator', 'creator'].includes(data.status)) {
  //                   return {
  //                     ...task,
  //                     completedUsersCount,
  //                     budgetCompleted,
  //                   completionLabel,
  //                     userRole: data.status ,
  //                     isMyTask
  //                   }; 
  //                 }
  //               } catch (err) {
  //                 console.error('Error checking membership:', err.message);
  //                 return null; // Exclude the task in case of error
  //             }
  //           }
  //           }
  
  //           return {
  //             ...task,
  //             completedUsersCount,
  //             budgetCompleted,
  //                 completionLabel,
  //                 isMyTask,
  //           };
  //         })
  //       );
  
  //       const filteredTasks = (userId === '6307965462')
  //       ? tasksWithCount  .filter(task => task !== null)
        
  //      : tasksWithCount
  //         .filter(task => task !== null) // Filter out tasks with errors
  //         .filter(task => 
  //           task.adsBudget > task.completedUsersCount && 
  //           task.adsBudget !== null 
  //         ); // Filter based on adsBudget
          
  //         const sortedTasks = filteredTasks.sort((a, b) => b.earn - a.earn);
  //       setTasks(sortedTasks); // Set the filtered tasks
  //      // setLoadingTasks(false)
  //       //////console.log('Fetched tasks with completed users count:', filteredTasks);
  //     } catch (error) {
  //       console.error('Error getting tasks:', error.message);
  //     }
  //   };
  
  //   getTasks();
  // }, [userId]);
  
  
  // useEffect(() => {
  //   const getTasks = async () => {
  //     try {
  //       const tasks = await appwriteService.getAllTasks();
  //       const tasksWithCount = tasks
  //         .map(task => ({
  //           ...task,
  //           completedUsersCount: task.completedUsers.length, // Calculate count of completed users
  //         }))
  //         .filter(task => task.adsBudget > task.completedUsersCount && task.adsBudget !== null); // Filter based on adsBudget
  
  //       setTasks(tasksWithCount); // Set the filtered tasks
  //       //////console.log('Fetched tasks with completed users count:', tasksWithCount);
  //     } catch (error) {
  //       console.error('Error getting tasks:', error.message);
  //     }
  //   };
  
  //   getTasks();
  // }, []); // Add an empty array to run only once
  
  // useEffect(() => {
  //   // const getTasks = async () => {
  //   //   try {
  //   //     const tasks = await appwriteService.getAllTasks();
  //   //     setTasks(tasks); 
  //   //     //////console.log('taaaskota', tasks);
  //   //   } catch (error) {
  //   //     console.error('Error getting tasks:', error.message);
  //   //   }
  //   // };
  //   const getTasks = async () => {
  //     try {
  //       const tasks = await appwriteService.getAllTasks();
  //       const tasksWithCount = tasks.map(task => ({
  //         ...task,
  //         completedUsersCount: task.completedUsers.length // Calculate count of completed users
  //       }));
    
  //       setTasks(tasksWithCount); // Set the tasks with the completed users count
  //       //////console.log('Fetched tasks with completed users count:', tasksWithCount);
  //     } catch (error) {
  //       console.error('Error getting tasks:', error.message);
  //     }
  //   };
  //   getTasks();
  // }, []); // Add an empty array to run only once
  
  

// Function to count completed users for each task
const getCompletedUsersCount = (taskId) => {
  const task = tasks.find(task => task.$id === taskId);
  return task ? task.completedUsers.length : 0;
};

  useEffect(() => {
     const getAdsgram = async () => {
      const adsgram = await asbekService.fetchAdsgram();
      console.error('bhkjn fet46888888:', adsgram);
      setBalance(adsgram.balance)
      setGoal(adsgram.goal)
      setBlockId(adsgram.blockId)
     }
     getAdsgram()
  }, []);

  useEffect(() => {
     const AppConfig = async () => {
      const appConfig = await asbekService.getConfigs();
      setAppConfig(appConfig)
      setWithdrawLimit(appConfig.withdrawlimit)
      setAllowWithdraw(appConfig.allow_withdraw)
      setWithdrawDate(appConfig.withdrawDate)
     }
     AppConfig()
  }, []);

  const updateAccountDetails = (newAccountDetails) => {
    setAccountNumber(newAccountDetails.accountNumber);
    setAccountName(newAccountDetails.accountName);
    setPhoneNumber(newAccountDetails.phoneNumber);
  };
  
 // Function to update coin value
 const incrementCoinValue = (add) => {
    setCoinValue((prevValue) => prevValue + add); // Increments coin value by 10
  };
  const decrementCoinValue = (minus) => {
    setCoinValue((prevValue) => prevValue - minus); // Increments coin value by 10
  };
//   const incrementTaskEarn = (add) => {
//     setTaskEarn((prevValue) => {
//         // Ensure both prevValue and add are treated as numbers
//         const newValue = Number(prevValue) + Number(add); 
//         //////console.log('Incrementing task earnings. Previous:', prevValue, 'Adding:', add, 'New:', newValue);
//         return newValue; 
//     });
// };
const incrementTaskEarn = (add) => {
  setTaskEarn((prevValue) => {
      // Ensure both prevValue and add are treated as valid numbers
      const validPrevValue = isNaN(prevValue) || prevValue === null ? 0 : Number(prevValue);
      const validAdd = isNaN(add) || add === null ? 0 : Number(add);

      const newValue = validPrevValue + validAdd;
      //////console.log('Incrementing task earnings. Previous:', validPrevValue, 'Adding:', validAdd, 'New:', newValue);

      return newValue;
  });
};
const decrementTaskEarn = (add) => {
  setTaskEarn((prevValue) => {
      // Ensure both prevValue and add are treated as valid numbers
      const validPrevValue = isNaN(prevValue) || prevValue === null ? 0 : Number(prevValue);
      const validAdd = isNaN(add) || add === null ? 0 : Number(add);

      const newValue = validPrevValue - validAdd;
      //////console.log('Incrementing task earnings. Previous:', validPrevValue, 'Adding:', validAdd, 'New:', newValue);

      return newValue;
  });
};
  // Function to increment weekly click count by 1
  const incrementWeeklyClickCount = (add) => {
    setWeeklyClickCount((prevCount) => prevCount + add); // Increments weekly click count by 1
    recalculateRank()
  };
  
  const incrementRank = () => {
    // //////console.log('uiuiuiui',theAllDocuments)
    setErgaa('calculating rank')
    if (theAllDocuments.length > 0) {
      recalculateRank();
    }
  
  };

  // Function to increment click count by 1
  const incrementClickCount = (add) => {
    setClickCount((prevCount) => prevCount + add); // Increments click count by 1
  };
  const resetClickCount = () => {
    setClickCount((prevCount) => 0); // Increments click count by 1
  };
  // Function to increment daily click count by 1
  const incrementDailyClickCount = (add) => {
    setDailyClickCount((prevCount) => prevCount + add); // Increments daily click count by 1
  };

  // Function to increment monthly click count by 1
  const incrementMonthlyClickCount = (add) => {
    setMonthlyClickCount((prevCount) => prevCount + add); // Increments monthly click count by 1
  };

  useEffect(() => {
    fetchClickData('weekly');
    // Create an instance of AsbekAppwriteService
    const appwriteService = new AsbekAppwriteService();

    // Function to fetch and set all the user data fields
    const fetchData = async () => {
      if (userId) {
       
        appwriteService.getDocument(userId)
        .then(userDoc => {

            if (userDoc) {

          const now = Math.floor(Date.now() / 1000); // Current time in UNIX timestamp
          appwriteService.updateDocument(userId, { online: String(now)})
          setFullName(userDoc.fullName)
          setProfession(userDoc.profession)
          setProfilePic(userDoc.profilePic)
          setUsersCountry(userDoc.country)
          setVerified(userDoc.verified)
          setUsername(userDoc.username);
          setFirstName(userDoc.first_name);
          setLastName(userDoc.last_name);
          setInvitedBy(userDoc.invited_by);
          setInvitationLink(userDoc.invitation_link);
          setCoinValue(parseFloat(userDoc.coinValue));

          setTaskEarn(parseFloat(userDoc.taskEarn));
          setLastReset(userDoc.lastReset || String(Math.floor(new Date().getTime() / 1000)));
          setClickCount(parseInt(userDoc.clickCount));
          setAccountNumber(userDoc.accountNumber);
          setAccountName(userDoc.accountName);
          setPhoneNumber(userDoc.phoneNumber);
          setEmail(userDoc.email)
          setDailyClickCount(parseInt(userDoc.dailyClickCount));
          setWeeklyClickCount(parseInt(userDoc.weeklyClickCount));
          setMonthlyClickCount(parseInt(userDoc.monthlyClickCount));
          setLastDailyReset(userDoc.lastDailyReset);
          setLastWeeklyReset(userDoc.lastWeeklyReset);
          setLastMonthlyReset(userDoc.lastMonthlyReset);
          setBlock(userDoc.block);
          setClickHistory(userDoc.clickHistory || []);
          setOnline(userDoc.online);
          setLastWeekClickCount(parseInt(userDoc.lastWeekClickCount));
          setSessionId(userDoc.sessionId);
          setWallet(userDoc.wallet);
          setSessionTime(userDoc.sessionTime);
          ranking(userDoc.weeklyClickCount, userId)
          dailyRanking(userDoc.dailyClickCount, userId)    
          const getCompetitionStartDate = async () => {
            const competitionStart = await asbekService.fetchCompetitionStartDate();
            //console.error('bhkjn fet46888888:', competitionStart);
            setCompetitionStartDate(competitionStart.competitionStartDate)
            
            setVpn(competitionStart.vpn)
          //   if (userId) {
          //     // //////console.log('Welcomiiinnh');
          //     const savedLocalClickCountData = JSON.parse(localStorage.getItem(`localClickCount_${userId}`));
          //     const savedLocalCoinValueData = JSON.parse(localStorage.getItem(`localCoinValue_${userId}`));
          //     const lastServerUpdate = localStorage.getItem('lastServerUpdate');
              
          //     if (savedLocalClickCountData && savedLocalCoinValueData && lastServerUpdate) {
          //         const savedClickTimestamp = parseInt(lastServerUpdate, 10); // timestamp in milliseconds
          //         //////console.log('kjh', competitionStart.competitionStartDate)
          //         // Fetch the competition start date from Appwrite
          //         const competitionStartTime = Date.parse(competitionStart.competitionStartDate);

          //         // //////console.log('competitionStartDate', competitionStartTime);
          //         // //////console.log('lastServerUpdate', lastServerUpdate);
                  
          //         if (competitionStartTime && savedClickTimestamp >= competitionStartTime) {
          //           // //////console.log('compe let go');
          //           // //////console.log('savedLocalCoinValueData.value', savedLocalCoinValueData);
          //           // //////console.log('savedLocalClickCountData.count', savedLocalClickCountData);
          //             // Only increment if the last server update is after the competition start time
          //             incrementCoinValue(parseInt(savedLocalCoinValueData, 10));
          //             incrementWeeklyClickCount(parseInt(savedLocalClickCountData, 10));
          //         } else if (competitionStart.competitionStartDate == null){
          //           incrementCoinValue(parseInt(savedLocalCoinValueData, 10));
          //             incrementWeeklyClickCount(parseInt(savedLocalClickCountData, 10));
          //         } 
          //         else {
          //           incrementCoinValue(parseInt(savedLocalCoinValueData, 10));
          //             //////console.log('Local click data is from before the competition start time. Skipping increment.');
          //         }
          //     } else{
          //       //////console.log('no no no');
          //     }
          // }
           }
           getCompetitionStartDate()

         
        
          //setErgaa('wonderful')
        } else{
          //setErgaa('asiimmoo maal gootaa')  
        }



        }) .catch(async error => {
            //setErgaa('catch');
            if (error.code !== 404) {
                //setErgaa('Error fetching document:', error)
                //console.error('Error fetching document:', error);
            } else  {
                //setErgaa('some one:', error)
                //console.error('Errorjj document:', error);
                try {
                    //setErgaa('let go');
                     createOrUpdateDocument(0);
                     //setErgaa('gobex neshko:', error)
                    //console.error('55555555555Errorjj document:', error);
                    //await appwriteService.createDocument(userId, { coinValue: 1, username: user.username, first_name: user.first_name, last_name: user.last_name });
                } catch (error) {
                    //setErgaa('dfjkm',userId,error)
                 //console.error('dfjkm',userId,error)   
                }
                
            }
        });
      
        }
    };

    fetchData();
  }, [userId]);

  const dailyRanking = async (dailyClickCount, userId) => {
    
    try {
        const { rank, documents } = await appwriteService.getDailyRank(dailyClickCount, userId);
        setDailyRank(rank); 
         
        //////console.log('55555555555555555')
    } catch (error) {
        console.error('Error getting ranking:', error.message);
        // Handle the error as needed
    }
}

  const ranking = async (weeklyClickCount, userId) => {
    const messages = [
      'Message 1: Hello there!',
      'Message 2: How are you?',
      'Message 3: Why always here?',
      'Message 4: Keep going!',
      'Message 5: Great job!'
  ];

  // Randomly select a message
  const randomMessage = messages[Math.floor(Math.random() * messages.length)];

  // Set the randomly selected message
  setErgaa(randomMessage);
    try {
        const { rank, documents } = await appwriteService.getFastRank(weeklyClickCount, userId);
        setTheRank(rank); 
        setTheAllDocuments(documents);  
        //////console.log('55555555555555555')
    } catch (error) {
        console.error('Error getting ranking:', error.message);
        // Handle the error as needed
    }
}


const recalculateRank = () => {
  // Check if documents are available
  if (!theAllDocuments || theAllDocuments.length === 0) {
    // setErgaa('No documents to calculate rank.')
    //////console.log('No documents to calculate rank.');
    return;
  }

  // Log documents for debugging purposes
  // //////console.log('Documents:', theAllDocuments);

  // Assume theWeeklyClickCount is available in the client side
  const userWeeklyClickCount = theWeeklyClickCount; // Use your updated click count for the user

  // Calculate the user's rank
  let userRank = 1;

  // Loop through all documents to compare weeklyClickCount
  for (const doc of theAllDocuments) {
    if (doc.weeklyClickCount > userWeeklyClickCount) {
      userRank++; // Increment rank if the document's weeklyClickCount is higher
    }
  }

  // Set the rank for the user
  setTheRank(userRank);

  // Log the rank for debugging purposes
  // setErgaa('User Weekly Click Count:', userWeeklyClickCount, )
  // //////console.log('User ID:', userId);
  // //////console.log('User Weekly Click Count:', userWeeklyClickCount, 'User Rank:', userRank);
  // //////console.log('User Rank:', userRank);
};

  
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const startParam = query.get('tgWebAppStartParam');
    setStartParam(startParam);
  }, []);

  const createOrUpdateDocument = async (newHighScore) => {
         
    const tg = window.Telegram.WebApp;
    tg.ready();

    const initDataUnsafe = tg.initDataUnsafe;
    const user = initDataUnsafe?.user;

    const query = new URLSearchParams(window.location.search);
    const startParam = query.get('tgWebAppStartParam');
    const userId = String(user?.id).trim();
    //setErgaa('seenera');

    try {
        const existingDoc = await appwriteService.getDocument(userId);
        if (existingDoc) {
            if (!existingDoc.user_id) {
                //setErgaa('wayyoooooooooooooo');
                await appwriteService.updateDocument(userId, { user_id: userId });
                //setErgaa('dansa');
                //setErgaa({ type: 'success', text: 'Document updated successfully.' });
                //setErgaa({ type: 'success', text: '55 createOrUpdateDocument 55Document created successfully.' });
            }
            //setErgaa('dox jira');
            if (newHighScore > parseInt(existingDoc.coinValue)) {
                await appwriteService.updateDocument(userId, { coinValue: newHighScore });
                //setErgaa({ type: 'success', text: 'Document updated successfully.' });
                //setErgaa({ type: 'success', text: '55 createOrUpdateDocument 55Document created successfully.' });
            }
        } else {
            await appwriteService.createDocument(userId, {invited_by:startParam, coinValue: newHighScore, username: user.username, first_name: user.first_name, last_name: user.last_name,  });
            //console.error('5ashsghvbasjdknsk Error updating document:');
            //setErgaa({ type: 'success', text: '66 createOrUpdateDocument 55Document created successfully.' });
            //setErgaa({ type: 'success', text: ' 55 Document created successfully.' });
        }
        
    } catch (error) {
        if (error.code === 404) {
            try {
                //setErgaa('Asan Jiraam');
                if (startParam) {
                    //setErgaa('Argameera', startParam);
                    try {
                        //setErgaa('Yaalii eegale', startParam);
                    const userId = String(user?.id).trim();
                    //setErgaa('User ID:', userId);
                    await appwriteService.createDocument(userId, {user_id:userId ,invited_by:startParam, coinValue: 1000, username: user.username, first_name: user.first_name, last_name: user.last_name,  });
                    setCoinValue(1000);
                    //setErgaa('Baga nagaan dhufte Error updating document:', );
               
                    const userDoc = await appwriteService.getDocument(startParam);
                    if (userDoc) {
                        //setErgaa('Invitter kennaafii jira', startParam);
                     const InviterCurrentCoinValue = parseInt(userDoc.coinValue) ;
                     //setErgaa('InviterCurrentCoinValue', InviterCurrentCoinValue);
                      const newCoinValue = InviterCurrentCoinValue + 1000;
                       
                      //setErgaa('Inviter new CurrentCoinValue', newCoinValue);
                      
                      const updatedDoc = await appwriteService.updateDocument(startParam, { coinValue: newCoinValue});
                      //setErgaa('inviter is rewarded :', updatedDoc); // Log updated document
            
                     
            
                    } else {
                      //setErgaa('Inviter hin jiruu');
                    }
            
                    } catch (error) {
                        await appwriteService.createDocument(userId, {user_id:userId ,invited_by:startParam, coinValue: newHighScore, username: user.username, first_name: user.first_name, last_name: user.last_name,  });
                        //console.error('ayaleeeeeeeeeeeeeee5 Error updating document:', error);
                        //setErgaa({ type: 'success', text: ' createOrUpdateDocument 55Document created successfully.' });
             
                      //console.error('Error marking task as completed:', error);
                    }
                  } else{
                    //setErgaa('User ID:', userId);
                    await appwriteService.createDocument(userId, {user_id:userId ,invited_by:startParam, coinValue: 0, username: user.username, first_name: user.first_name, last_name: user.last_name,  });
                    //setErgaa('you have getted  Error updating document:', );
               

                  }
                       } catch (createError) {
                //console.error('55 Error 55 creating document:', createError);
                //setErgaa({ type: 'error', text: `Error creating document: ${createError.message}` });
            }
        } else {
            //console.error('55 55 Error updating document:', error);
            //setErgaa({ type: 'error', text: `Error updating document: ${error.message}` });
        }
    }
};

const fetchClickData = async (criteria) => {
  try {
    const appwriteService = new AppwriteService();
    const users = await appwriteService.getTopUsers(criteria);
    
    setTopUsers(users);
    setTop3Users(users.top3);
  } catch (error) {
    console.error('Error fetching weekly click data:', error);
  } finally {
    
  }
};



  return (
    <CoinContext.Provider
      value={{
        loadingTheTasks,
        country,
        vpn,
        tasks,
        balance,
        goal,
        competitionStartDate,
        incrementRank,
        theRank,
        setTheRank,
        updateAccountDetails,
        theTopUsers,
        theTop3Users,
        ergaa,
        theUser,
        theUserId,
        theUsername,
        theFirstName,
        theLastName,
        theInvitedBy,
        theInvitationLink,
        theCoinValue,
        theLastReset,
        theClickCount,
        theAccountNumber,
        theAccountName,
        thePhoneNumber,
        theDailyClickCount,
        theWeeklyClickCount,
        theMonthlyClickCount,
        theLastDailyReset,
        theLastWeeklyReset,
        theLastMonthlyReset,
        theBlock,
        theClickHistory,
        theOnline,
        theLastWeekClickCount,
        theSessionId,
        theWallet,
        theSessionTime,
        incrementCoinValue,
        incrementWeeklyClickCount,
        incrementClickCount,
        incrementDailyClickCount,
        incrementMonthlyClickCount,
        resetClickCount,
        completeTask,
        incrementTaskEarn,
        decrementTaskEarn,
        taskEarn,
        createWallet,
        fetchWalletData,
        blockId,
        cryptoToUsd,
        estimatedUSD,
        decrementCoinValue,
        theasbeksPrice,
        setTaskType,
        taskType,
        taskCount,
        localClickCount,
        localCoinValue,
        setLocalClickCount,
        setLocalCoinValue,
        setTaskCursor,
        taskCursor,
        setNextTaskCursor,
        setTasks,
        hasMore,
        setAsbeksPrice,
        withdrawLimit,
        withdrawRequestType,
        loadingwithdrawrequests,
        withdrawRequestCursor,
        withdrawRequestNextCursor,
        withdrawHasMore,
        withdrawalRequests,
        withdrawalRequestsCount,
        setWithdrawRequestType,
        setLoadingWithdrawRequests,
        setWithdrawRequestCursor,
        setWithdrawRequestNextCursor,
        setWithdrawHasMore,
        setWithdrawalRequests,
        setWithdrawalRequestsCount,
        friends,
        friendsCount,
        loadingFriends,
        allowWithdraw,
        withdrawDate,
        appConfig,
        setWeeklyClickCount,
        quotesfiles,
        fullName,
        profession,
        UsersCountry,
        verified,
        profilePic,
        email,
        setPhoneNumber,
        setEmail,
        setUsersCountry,
        setFullName,
        setProfession,
        setProfilePic,
        channels,loadingChannels,
        setChannels,posts, setPosts,telegramChatId, setTelegramChatId,
        dailyRank
      }}
    >
      {children}
    </CoinContext.Provider>
  );
}

import React, { useContext, useState, useEffect } from 'react';
import { FiSearch, FiEdit2, FiTrash2, FiClock, FiToggleLeft, FiToggleRight, FiCalendar } from "react-icons/fi";
import { format } from "date-fns";
import styled from 'styled-components';
import {ChannelManagementButton, Container, Heading, Button, SearchInput, ChannelCard, ChannelTitle, ChannelDescription, StatusBadge, } from './StyledComponents';
import InitialSetup from './InitialSetup';
import { CoinContext } from '../../contextdtatashare/CoinContext';
import AsbekAppwriteService from '../../api/AsbekAppwriteService';
import TelegramManager from './TGManager';
import Setting from './setting';

const appwriteService = new AsbekAppwriteService();

const ChannelManagement = ({ selectedChannel }) => {
    const [statusMessage, setStatusMessage] = useState('');
    const {
              setPhoneNumber,
              setEmail,
              setUsersCountry,
              setFullName,
              setProfession,
              setProfilePic,
              email,
              thePhoneNumber,
              theUsername,
              fullName,
              profession,
              UsersCountry,
              verified,
              profilePic,
              quotesfiles,
              setWeeklyClickCount,
              theRank,
              setTheRank,
              friendsCount,
              taskEarn,
              appConfig,
              withdrawDate,
              allowWithdraw,
              withdrawRequestType,
              setWithdrawalRequests,
              setWithdrawRequestType,
              withdrawalRequests,
              withdrawLimit,
              theCoinValue,
              theasbeksPrice,
              decrementCoinValue,
              channels,
              setChannels,posts, setPosts,telegramChatId, setTelegramChatId
            } = useContext(CoinContext);
    const [view, setView] = useState("");
    const [settings, setSettings] = useState({
      name: selectedChannel?.name || '',
      owner: selectedChannel?.owner || '',
      description: selectedChannel?.description || '',
      link: selectedChannel?.link || '',
      goal: selectedChannel?.goal || '',
      mainTopic: selectedChannel?.mainTopic || '',
      targetAudience: selectedChannel?.targetAudience || '',
      preferredContentFormat: selectedChannel?.preferredContentFormat || '',
      channelHandle: selectedChannel?.channelHandle || '',
      telegramChatId: selectedChannel?.telegramChatId || '',
      isActive: selectedChannel?.isActive || false, // Assuming isActive is a boolean
  });

   // Handle saving the updated channel settings
   const handleSave = async () => {
    try {
        // Ensure required fields are available
       // Check for missing fields and throw specific errors
if (!selectedChannel?.$id) {
    throw new Error("Channel ID is missing. Please ensure the selected channel is valid.");
}
if (!selectedChannel?.owner) {
    throw new Error("Channel owner is missing. Please ensure the channel owner is specified.");
}
if (!settings.channelHandle) {
    throw new Error("Channel handle is missing. Please provide a valid channel handle.");
}
if (!settings.telegramChatId) {
    throw new Error("Telegram Chat ID is missing. Please provide a valid Telegram Chat ID.");
}
if (!settings.name) {
    throw new Error("Channel name is missing. Please provide a channel name.");
}

// Log the state to ensure isActive is correct
        console.log("Changing isActive state in handlesave to:", settings.isActive);

        // Declare and initialize updatedChannelData before using it
        const updatedChannelData = {
            id: selectedChannel.$id, // Ensure selectedChannel has an ID field
            owner: selectedChannel.owner,  // Ensure you have the owner available
            goal: settings.goal,
            mainTopic: settings.mainTopic,
            targetAudience: settings.targetAudience,
            preferredContentFormat: settings.preferredContentFormat,
            channelHandle: settings.channelHandle,
            telegramChatId: settings.telegramChatId,
            name: settings.name,
            description: settings.description,
            link: settings.link,
            isActive: settings.isActive,  // Can be toggled
        };

        // Call the updateChannel method from appwriteservice
        const response = await appwriteService.updateChannel(updatedChannelData);
        setChannels((prevChannels) =>
            prevChannels.map((channel) =>
                channel.$id === selectedChannel.$id ? { ...channel, ...updatedChannelData } : channel
            )
        );
        
        setStatusMessage("Channel updated successfully!");
        console.log(response);
    } catch (error) {
        console.error("Error updating channel:", error.message);
        setStatusMessage(`Failed to update channel: ${error.message}`);
    }
};


if (view === "dashboard"){
    return <TelegramManager />
}
    return (
      <div>
            <Button onClick={() => setView("dashboard")}>← Back to Dashboard</Button>
            <Heading>{selectedChannel?.name} Settings </Heading>
           
            {/* Display editable fields for the channel */}
            <Setting
            selectedChannel={selectedChannel}
        settings={settings}
        setSettings={setSettings}
        handleSave={handleSave}
       statusMessage={statusMessage}
    />
        </div>


    );
  };

  export default ChannelManagement;
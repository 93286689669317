import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import styled from 'styled-components';

const AdminPanelBottomSheet = ({ isOpen, onDismiss }) => {
  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onDismiss}
      defaultSnap={({ maxHeight }) => maxHeight * 0.7}
    >
      <Content>
        {/* The bottom sheet is now empty */}
      </Content>
    </BottomSheet>
  );
};

const Content = styled.div`
  padding: 20px;
`;

export default AdminPanelBottomSheet;

// quoteLogic.js
import React, {useContext, useRef, useState, useEffect } from 'react';
import { Mistral } from '@mistralai/mistralai';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import { CoinContext } from '../contextdtatashare/CoinContext';

const appwriteService = new AsbekAppwriteService();

  const apiKey = 'vt2Kk2MCvBjQiNwXTsNqncgzsOK08q06'; // Your Mistral API key
const client = new Mistral({ apiKey });

const useQuoteGenerator = () => {
  const {quotesfiles,setWeeklyClickCount,theRank, setTheRank,friendsCount, taskEarn, appConfig, withdrawDate, allowWithdraw, withdrawRequestType, setWithdrawalRequests, setWithdrawRequestType, withdrawalRequests, withdrawLimit, theCoinValue, theasbeksPrice, decrementCoinValue } = useContext(CoinContext);

  const [quote, setQuote] = useState('Your generated quote will appear here!');
  const [loading, setLoading] = useState(false);
  const [previousQuotes, setPreviousQuotes] = useState([]);
  const [selectedType, setSelectedType] = useState('motivational');
  const [additionalPrompt, setAdditionalPrompt] = useState('');  // Ensure this is properly initialized

  const tg = window.Telegram.WebApp;
    tg.ready();
  
    const initDataUnsafe = tg.initDataUnsafe;
    const usertg = initDataUnsafe?.user;
  
    
  
    const userId = String(usertg?.id).trim();

  const generatePrompt = () => {
    

    switch (selectedType) {
      case 'motivational': 
        return additionalPrompt 
          ? `Create 1 very short only 50 character, powerful motivational quote that focuses on ${additionalPrompt}. It should inspire confidence, perseverance, personal growth, and the pursuit of goals. It should encourage action, resilience, and the ability to overcome obstacles in any aspect of life, including personal, professional, and social. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.` 
          : `Create 1 very short only 50 character, powerful motivational quote that inspires confidence, perseverance, personal growth, and the pursuit of goals. It should encourage action, resilience, and the ability to overcome obstacles in any aspect of life, including personal, professional, and social. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    
      case 'inspirational': 
        return additionalPrompt 
          ? `Create 1 very short only 50 character, uplifting inspirational quote that focuses on ${additionalPrompt}. It should fuel hope, self-belief, and a positive outlook. It should inspire action, personal development, overcoming adversity, and finding purpose in life. Include a wide range of themes such as resilience, ambition, and vision. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`
          : `Create 1 very short only 50 character, uplifting inspirational quote that fuels hope, self-belief, and a positive outlook. It should inspire action, personal development, overcoming adversity, and finding purpose in life. Include a wide range of themes such as resilience, ambition, and vision. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    
      case 'funny': 
        return additionalPrompt 
          ? `Create 1 very short only 50 character, witty, and humorous quote focusing on ${additionalPrompt}. It should bring genuine laughter and be clever, light-hearted, and relatable. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`
          : `Create 1 very short only 50 character, witty, and humorous quote that brings genuine laughter. It should be light-hearted, clever, and relatable, capable of brightening anyone’s day. It can cover a wide variety of topics, from everyday life to humorous reflections on human nature. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    
      case 'philosophical': 
        return additionalPrompt 
          ? `Create 1 very short only 50 character, thought-provoking philosophical quote focusing on ${additionalPrompt}. It should encourage deep reflection on life, existence, morality, and human nature. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`
          : `Create 1 very short only 50 character, thought-provoking philosophical quote that encourages deep reflection on life, existence, morality, and human nature. It should address broad topics such as the meaning of life, ethics, free will, or the pursuit of knowledge. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    
      case 'leadership': 
        return additionalPrompt 
          ? `Create 1 very short only 50 character, insightful leadership quote focusing on ${additionalPrompt}. It should inspire integrity, vision, empathy, and the ability to motivate others. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`
          : `Create 1 very short only 50 character, insightful leadership quote that inspires integrity, vision, empathy, and the ability to motivate others. It can discuss leadership qualities, ethics, team dynamics, influence, and personal responsibility in any setting, from business to community life. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    
      case 'love': 
        return additionalPrompt 
          ? `Create 1 very short only 50 character, heartfelt romantic quote focusing on ${additionalPrompt}. It should speak to love, affection, emotional connection, and relationships. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`
          : `Create 1 very short only 50 character, heartfelt romantic quote that speaks to love, affection, emotional connection, and relationships. It should address various aspects of love, from romantic love to love in friendships and family relationships, or even self-love. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    
      case 'wisdom': 
        return additionalPrompt 
          ? `Create 1 very short only 50 character, impactful quote about wisdom focusing on ${additionalPrompt}. It should promote reflection and intellectual growth. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`
          : `Create 1 very short only 50 character, impactful quote about wisdom, knowledge, or learning that promotes reflection and intellectual growth. It should cover a broad range of themes such as gaining wisdom through experience, the pursuit of knowledge, and the importance of reflection in decision-making. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    
      case 'happiness': 
        return additionalPrompt 
          ? `Create 1 very short only 50 character, uplifting quote about happiness focusing on ${additionalPrompt}. It should emphasize joy, positivity, and embracing life's moments. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`
          : `Create 1 very short only 50 character, uplifting quote about happiness, joy, and positivity. It should focus on the pursuit of contentment, inner peace, and the importance of embracing life’s moments, from small joys to larger life accomplishments. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    
      case 'friendship': 
        return additionalPrompt 
          ? `Create 1 very short only 50 character, warm quote about friendship focusing on ${additionalPrompt}. It should emphasize loyalty, trust, and mutual support. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`
          : `Create 1 very short only 50 character, warm quote about friendship that emphasizes loyalty, trust, and mutual support. It should reflect on the emotional connection, shared experiences, and the value of true friendships, from childhood to adulthood, in all its forms. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    
      case 'sadness': 
        return additionalPrompt 
          ? `Create 1 very short only 50 character, compassionate quote about sadness focusing on ${additionalPrompt}. It should offer empathy and understanding, addressing themes of loss, sorrow, and resilience in the face of challenging times. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`
          : `Create 1 very short only 50 character, compassionate quote about sadness, grief, or emotional vulnerability. It should offer empathy and understanding, addressing themes of loss, sorrow, and resilience in the face of challenging times. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    
      case 'islamic': 
        return additionalPrompt 
          ? `Create 1 very short only 50 character Islamic quote focusing on ${additionalPrompt}. It can reflect a wide range of Islamic principles such as faith, mercy, and personal development. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`
          : `Create 1 very short only 50 character Islamic quote that covers a broad range of topics, including faith, knowledge, justice, mercy, compassion, patience, personal development, and ethical conduct. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    
      case 'christian': 
        return additionalPrompt 
          ? `Create 1 very short only 50 character Christian quote focusing on ${additionalPrompt}. It should reflect themes such as faith, love, forgiveness, and humility. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`
          : `Create 1 very short only 50 character Christian quote that can reflect themes such as faith, love, forgiveness, service to others, humility, grace, and the pursuit of righteousness. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    
      default: 
        return additionalPrompt 
          ? `Create 1 very short only 50 character motivational quote focusing on ${additionalPrompt}. It should encourage perseverance, determination, and the pursuit of one’s goals. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`
          : `Create 1 very short only 50 character motivational quote for success that encourages perseverance, determination, and the pursuit of one’s goals. It should cover various aspects of success, from personal ambition to professional achievement, and inspire action in the face of challenges. Provide only the quote, no additional information or introductory phrases. Completely another quote. Don't repeat previous one.`;
    }
    

    
    
  };

  const generateQuote = async () => {
    setLoading(true);
    try {
      const randomPrompt = generatePrompt();
      const response = await client.chat.complete({
        model: 'mistral-large-latest',
        messages: [{ role: 'system', content: randomPrompt }],
        temperature: 1.0,
        
      });

      const newQuote = response.choices[0].message.content.trim();

      // Avoid duplicate quotes
      if (!previousQuotes.includes(newQuote)) {
        setQuote(newQuote);
        setPreviousQuotes([...previousQuotes, newQuote]);
        const paid = await appwriteService.generateQuote({ userId: userId })

        decrementCoinValue(100)
      } else {
        setQuote('Generated a duplicate quote, try again.');
      }
    } catch (error) {
      console.error('Error generating quote:', error);
      setQuote('An error occurred while generating the quote. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return { quote, loading, selectedType, setSelectedType, generateQuote, additionalPrompt, setAdditionalPrompt };
};

export default useQuoteGenerator;
